import React, { useState,useRef } from 'react'
import styled from 'styled-components'
import profile from './assets/profile_img.png';
import "./FindPods.css";
import { useEffect } from 'react';
import axios from 'axios';
import {  useSelector } from "react-redux";
import { NavHashLink } from 'react-router-hash-link';
import star from './assets/star.png';
import Createpod from './Createpod';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
// import { DataGrid } from 'react-data-grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useHistory } from 'react-router-dom';

import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import { useAuthToken } from "../TokenContext";
import { useIntl } from 'react-intl';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }


  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    // setFilterData(data)
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
    
  }

const Teammembers = () => {
  const token = useAuthToken();
    const {  user } = useSelector(state => state.auth);
    const [currentPage, setcurrentPage] = useState(1);
    const [itemsPerPage, setitemsPerPage] = useState(3);
    const intl = useIntl();
    const history = useHistory();

    const handleTabClick = () => {
        history.push('/Leadpodrequests');
    };
    const ITEM_HEIGHT = 48;
    const options = [
        'None',
        'date'
      ];
    const start_date=useRef(new Date())
    const end_date=useRef(new Date());
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [optionValue, setOptionValue] = useState(0);
    
    const opens = Boolean(anchorEl);
    const [pageNumberLimit, setpageNumberLimit] = useState(5);
    const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
    const [minPageNumberLimit, setminPageNumberLimit] = useState(0);

    const [getData, setGetData] = useState([])
    const [filterData, setFilterData] = useState([]);
    const [completedPods, setCompletedPods] = useState([])
    const [privatePods, setPrivatePods] = useState([])
          const[pendingPods,setPendingPods]=useState([]);

    const [value, setValue] = React.useState(0);

  const [tableCurrentPage,setTableCurrentPage] = useState(1);
  const recordsPerPage = 5;
  const lastIndex = tableCurrentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  
  const records = (filterData)?filterData.slice(firstIndex,lastIndex):[];
 // console.log("records",records)
  const npages = (filterData)? Math.ceil(filterData.length / recordsPerPage):0;
  const tablepagenumbers = [...Array(npages+1).keys()].slice(1);

    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'podName', headerName: 'Pod Name', width: 130 },
        { field: 'podtype', headerName: 'Pod Type', width: 130 },
        {
          field: 'podsize',
          headerName: 'Pod Size',
          type: 'number',
          width: 90,
        },
        {
          field: 'podDescription',
          headerName: 'Pod Description',
          description: 'This column has a value getter and is not sortable.',
          sortable: false,
          width: 160,
          
        },
        { 
            field: 'created_at', 
            headerName: 'Date Time', 
            width: 130,
            
            valueGetter: (params) =>{
                var dates= params.value.split("T");
               // console.log("->", dates[0])
                return dates[0];

            }
        },
    
      ];

      const rows = [
        { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
        { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
        { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
        { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
        { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
        { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
        { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
        { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
        { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
      ];

    const handleChange = (event, newValue) => {
        if(newValue==0){
            setFilterData(privatePods)

        }
        else if(newValue == 1){
            setFilterData(getData)
        }
        else if(newValue==2){
            setFilterData(completedPods)
        }
        else if(newValue==3){
          setFilterData(pendingPods)
      }
        
      setValue(newValue);
    };


 



    useEffect( async () => {


        const body = {
            "clientID": user.data[0].emp_id
        }
        const headers = {
          Authorization : token, // Replace with your actual token
          'Content-Type': 'application/json', // You can add more headers as needed
        };

        try{
            
            // https://31c1qn7j99.execute-api.us-east-1.amazonaws.com/dev/gethiredpods
            let findpod = await axios.post(process.env.REACT_APP_CLIENT_GETHIREDPODS, body, {headers:headers});

            //console.log("tulasi hii", findpod.data)

            setGetData(findpod.data.hired_pods);
            setFilterData(findpod.data.private_pods);
            setCompletedPods(findpod.data.completed_pods);
            setPendingPods(findpod.data.pending_pods)
            setPrivatePods(findpod.data.private_pods);
            
            
        }catch(err){

            console.log(err)
        }

   


    }, [token]);


    const handleClick = (event) => {
        setcurrentPage(Number(event.target.id));
    }
    const handleClicks = (event) => {
        setAnchorEl(event.currentTarget);
      };


    const pages = [];
    const completedPages = [];
    const privatePages = [];
    const pendingPages = [];
    const podlength =(getData)?getData.length:0;
    const completepodlength = (completedPods)?completedPods.length:0;
    const pendingpodlength = (pendingPods)?pendingPods.length:0;
    const privatePodslength = (privatePods)?privatePods.length:0;
    for (let i = 1; i <= Math.ceil(podlength / itemsPerPage); i++) {
        pages.push(i);
    }
    for (let i = 1; i <= Math.ceil(completepodlength / itemsPerPage); i++) {
        completedPages.push(i);
    }
    for (let i = 1; i <= Math.ceil(pendingpodlength / itemsPerPage); i++) {
      pendingPages.push(i);
  }
    for (let i = 1; i <= Math.ceil(privatePodslength / itemsPerPage); i++) {
        privatePages.push(i);
    }
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = (getData)?getData.slice(indexOfFirstItem, indexOfLastItem):[];
    const renderPageNumbers = pages.map(number => {
        if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
            return (
                <li key={number} id={number} onClick={handleClick} className={currentPage == number ? 'active' : null}>{number}</li>
            );
        } else {
            return null;
        }
    });

    const handleNextbtn = () => {
        setcurrentPage(currentPage + 1);
        if (currentPage + 1 > maxPageNumberLimit) {
            setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
            setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
        }
    };
    const handlePrevbtn = () => {
        setcurrentPage(currentPage - 1);
        if ((currentPage - 1) % pageNumberLimit == 0) {
            setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
            setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
        }
    };

    

    const prePage =()=>{
        if(tableCurrentPage !== 1){
          setTableCurrentPage(tableCurrentPage-1)
        }
      }
      const nextPage=()=>{
        if(tableCurrentPage !== npages ){
            setTableCurrentPage(tableCurrentPage+1)
        }
      }
      const changeCPage=(id)=>{
        setTableCurrentPage(id)
      }
      const handleClose = (event) => {
        setOptionValue(event.target.value)
        setAnchorEl(null);
      };

      const filterFunction= (e)=>{
        setTableCurrentPage(1)
        
         
         var local_filter_date=[];                  
         if(value==0){
            local_filter_date=(privatePods)?privatePods:[]
         }
         else if(value==1){
            local_filter_date=(getData)?getData:[];
         }
         else if(value==2){
            local_filter_date=(completedPods)?completedPods:[];
         }
         else if(value==3){
          local_filter_date=(pendingPods)?pendingPods:[];
       }
         
        
         let filter_output = local_filter_date.filter((item)=> (start_date.current <= new Date(item.created_at.split("T")[0]) && end_date.current >= new Date(item.created_at.split("T")[0])) )
         
         
         setFilterData(filter_output)
    
      }
      

      const [userLocale, setUserLocale] = useState("en-US"); // Default to English (United States)

      useEffect(() => {
        // Detect the user's locale here and set it in the state
        const detectedLocale = detectUserLocale();
        if (detectedLocale) {
          setUserLocale(detectedLocale);
        }
      }, []);
    
      const formatDate = (date) => {
        return new Intl.DateTimeFormat(userLocale, {
          year: "numeric",
          month: "numeric",
          day: "numeric",
        }).format(date);
      };
    
      const detectUserLocale = () => {
        // Implement your logic to detect the user's locale here
        // For example, you can use navigator.language
        const userLanguage = navigator.language || navigator.userLanguage;
    
        // Check if the detected language is supported, and return a valid locale
        // You can add additional logic to map language codes to supported locales
        if (userLanguage === "en-US") {
          return "en-US"; // English (United States)
        } else if (userLanguage === "fr-FR") {
          return "fr-FR"; // French (France)
        } else {
          return "en-US"; // Default to English (United States) if the user's locale is not supported
        }
      };



      const navigateToDetails = (id) => {
        localStorage.setItem('selectedItemId', id);
      };

return (

    <Return >

<Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' ,  overflowX: 'auto', 
        whiteSpace: 'nowrap', }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label=  {intl.formatMessage({ id: 'privatePods' })} {...a11yProps(0)} />
          <Tab label={intl.formatMessage({ id: 'activePods' })} {...a11yProps(1)} />
          <Tab label={intl.formatMessage({ id: 'previousPods' })} {...a11yProps(2)} />
          <Tab label={intl.formatMessage({ id: 'pendingPods' })}{...a11yProps(3)} />
          <Tab label={intl.formatMessage({ id: 'podRequests' })} {...a11yProps(4)} onClick={handleTabClick} />
    
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Headingtext1> {intl.formatMessage({ id: 'activePods' })}</Headingtext1>

            

       
      </TabPanel>
      <TabPanel value={value} index={1}>
          
      </TabPanel>
      <TabPanel value={value} index={2}>
            <Headingtext1> {intl.formatMessage({ id: 'previousPods' })}</Headingtext1>

          
      </TabPanel>
      <div style={{ height: 400, width: '100%' }}>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClicks}
            style={{float:'right'}}
            
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            MenuListProps={{
              'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={opens}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: '20ch',
              },
            }}
          >
            {options.map((option,i) => (
              <MenuItem key={option} value={i} selected={option === 'None'} onClick={handleClose}>
                {option}
              </MenuItem>
            ))}
          </Menu>
          {optionValue==1 && <>
            <input type="date" name="start_date" onChange={(e)=>{
                let value=new Date(e.target.value)
                start_date.current=value;
                filterFunction();
              
              }} />
            <input type="date" name="end_date" onChange={(e)=>{
              
                let value=new Date(e.target.value)
                end_date.current=value;
                filterFunction()

              }} />
            <IconButton onClick={()=>{
              setOptionValue(0);
               var datas=[];                  
                if(value==0){
                    datas=(privatePods)?privatePods:[]
                }
                else if(value==1){
                    datas=(getData)?getData:[];
                }
                else if(value==2){
                    datas=(completedPods)?completedPods:[];
                }
              setFilterData(datas);
              setTableCurrentPage(1)
              }}>
                          <CloseIcon />
            </IconButton>
            </>}
            <TableContainer component={Paper}>
            <Table component={Paper} sx={{ maxWidth: 900 ,fontFamily:'Poppins,Medium', borderRadius: '10px'}} aria-label="simple table">
              <TableHead   sx={{ backgroundColor:'#00B4D8','& td, & th': {
                fontFamily: 'Poppins,Medium',
                fontSize: '15px',
                color: 'white',
                fontWeight:'600'
              },}}>
                <TableRow>
        
                  
                  
                  {/* <TableCell align="right" sx={{fontWeight:'bold'}}>ID</TableCell> */}
                  <TableCell align="right" sx={{fontWeight:'bold'}}>{intl.formatMessage({ id: 'podName' })}</TableCell>
                  <TableCell align="right" sx={{fontWeight:'bold'}}>{intl.formatMessage({ id: 'podType' })}</TableCell>
                  <TableCell align="right" sx={{fontWeight:'bold'}}>{intl.formatMessage({ id: 'podSize' })}</TableCell>
                  <TableCell align="right" sx={{fontWeight:'bold'}}>{intl.formatMessage({ id: 'podDescription' })}</TableCell>
                  <TableCell align="right" sx={{fontWeight:'bold'}}>{intl.formatMessage({ id: 'date' })}</TableCell>
                  <TableCell align="right" sx={{fontWeight:'bold'}}></TableCell>
                  
                </TableRow>
              </TableHead>
              <TableBody>
              
                  { records.length > 0 ?  
                    records.map((item,i)=> {
              
              const formattedDate = moment(item.created_at).format("MM-DD-YYYY");

            
              
                return( <TableRow
                    key={i}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 , fontFamily:'Poppins,Medium',fontSize: '15px'},
                    '&:nth-child(even)': {
                     backgroundColor: '#90E0EF'
                   },
                   '&:nth-child(odd)': {
                     backgroundColor: '#caf0f8'
                   },
                   '& td, & th': {
                     fontFamily: 'Poppins,Medium',
                     fontSize: '15px',
                     color: 'black',
                   },
                  }}
                  >

                    
                    
                    {/* <TableCell align="right">{item.id} </TableCell> */}
                    <TableCell align="right"> {item.podName}</TableCell>
                    <TableCell align="right"> {item.podtype}</TableCell>
                    <TableCell align="right"> {item.podsize}</TableCell>
                    <TableCell align="right"> {item.podDescription}</TableCell>
                    <TableCell align="right">  { formattedDate}</TableCell>
                    <TableCell align="right">
                      
                        

                        <NavHashLink to="/FindPodsDetails" smooth onClick={() => navigateToDetails(item.id)}>
      <DetailsButton>{intl.formatMessage({ id: 'viewDetails' })}</DetailsButton>
    </NavHashLink>
                    </TableCell>
                    
                  </TableRow>)
                
              
                  
                    
                }) : (<>{intl.formatMessage({ id: 'noPods' })} </>)} 
                </TableBody>
            </Table>
            
            </TableContainer>
             <div style={{textAlign:'center'}}>
                <ul>
                    <li style={{display:'inline-block',margin:4}}>
                      
                    <button style={{cursor:'pointer', border: 'none', background: 'none'}} onClick={prePage}>
  {intl.formatMessage({ id: 'prev' })}
</button>

                    </li>
                    {
                    tablepagenumbers.map((n, i)=>(
                        <li key={i} style={{display:'inline-block',margin:4}}>
                        <p style={{cursor:'pointer', color:`${currentPage === n ? "blue":"black" }`}}  onClick={()=>changeCPage(n)}><b>{n}</b></p>
                        </li>
                    ))
                    }
                    <li style={{display:'inline-block',margin:4}}>
                    <button style={{cursor:'pointer', border: 'none', background: 'none'}} onClick={nextPage}>
  {intl.formatMessage({ id: 'next' })}
</button>

                    </li>
                </ul>
             </div>
            </div>
    </Box>

<NavHashLink to = {`/create-pod`} smooth>
                                

                                </NavHashLink> 








    </Return>



)
}

export default Teammembers;

const OL1 = styled.ol`
list-style: none;
margin:auto;


@media screen and (min-width:320px) and (max-width:1080px){
    display:flex;
flex-direction:column;

align-items:center;
justify-content:center;


}
`;

const Return = styled.ol`

margin:auto;

margin-top:5rem;
@media screen and (min-width:320px) and (max-width:1080px){
    display:flex;
flex-direction:column;

align-items:center;
justify-content:center;
 overflow: auto !important; // Hide overflow to prevent unwanted scrollbars
   -ms-overflow-style: none !important;  /* IE and Edge */
  scrollbar-width: none !important;  /* Firefox */

  &::-webkit-scrollbar {
    display: none !important; // Hide scrollbar for Webkit browsers
  }
width:80vw;
margin-left:4rem;
}
`;

const Div = styled.div`


margin-top:5rem;
align-items:center;
justify-content:center;
padding-left:20px;
margin-left:4rem;
height:28rem;
margin-bottom : "30px";
width:29rem;
border-radius:20px;
background-color:rgb(142, 174, 188,32%);
@media screen and (min-width:320px) and (max-width:1080px){
 
  margin:auto;
  width:20rem;
 height:max-height;
margin-top:5rem;
}
`;

const Cards =styled.div`
display:flex;
flex-direction:row;
@media screen and (min-width:320px) and (max-width:1080px){
    display:flex;

  flex-direction:column;
  align-items:center;
  margin:auto;
  justify-content:center;
 
}
`;


const TD1 =styled.td`
margin-left: 2rem;
width: 12rem;
overflow-wrap: anywhere;
font-weight: 600;
font-family: Poppins,medium;
@media screen and (min-width:320px) and (max-width:1080px){
 margin:auto;
 margin-left: 2rem;
    width: 7rem;
    overflow-wrap: anywhere;
}
`;

const Num2 = styled.li`
        @media screen and (min-width:320px) and (max-width:1080px){
          display:flex;
        margin:auto;
        flex-direction:column;
        align-items:center;
        justify-content:center;
        width:100%;
}
        `;


const Numbers = styled.li`
        @media screen and (min-width:320px) and (max-width:1080px){
          display:flex;
        align-items:center;
        justify-content:center;
        width:7rem;
}
        `;


        const DetailsButton=styled.button`
        display:flex;
        justify-content:center;
        align-items:center;
        margin:auto;
        padding:5px;
      
        color:white;
      border:none;
        border-radius:5px  ;
        background-color:#1DBF73;
        box-shadow: 1px 3px 6px #00000029;

        margin-top:0rem;
        margin-bottom:0rem;
        
        `;
        const CreateButton=styled.button`
        display:flex;
        justify-content:center;
        align-items:center;
        margin-left:auto;
        padding:5px;
      width:10rem;
      height:3rem;
      font-size:18px;
      font-weight:600;
        color:white;
      border:none;
        border-radius:5px  ;
        background-color:#1DBF73;
        box-shadow: 1px 3px 6px #00000029;

        margin-top:4rem;
        margin-bottom:2rem;
        
        `;

        
        const Headingtext1=styled.h3`
font-family:Poppins,Medium;
display:flex;
justify-content:center;
align-items:center;
margin:auto;
margin-top: 5rem;
font-weight: 600;
font-size: 1.5rem;
@media screen and (min-width:320px) and (max-width:1080px){
    display:flex;
    justify-content:center;
    align-items:center;
    margin:auto;
    margin-top: 7rem;
    }
`;