import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useIntl } from 'react-intl';
import { useAuthToken } from "../TokenContext";
import { useSelector } from "react-redux";

const PodAutomation = () => {
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [question, setQuestion] = useState('');
  const intl = useIntl();
  const [showRecentSearches, setShowRecentSearches] = useState(false);
  const [sidebarWidth, setSidebarWidth] = useState(350); // Initial width of the sidebar

  const { user } = useSelector(state => state.auth);
  const token = useAuthToken();
  const [jiraIssueType, setJiraIssueType] = useState('');

  // Handle dropdown change
  const handleDropdownChange = (event) => {
    setJiraIssueType(event.target.value);
  };
  const handleSendClick = async () => {
    setIsLoading(true);
    setErrorMessage(null);

    const headers = {
      
      'Content-Type': 'application/json',
    };

    const payload = {
      "manager_id": user?.data[0]?.emp_id ,
      "query": question,
      "jira_issue_type":jiraIssueType,
    };

    try {
      const response = await axios.post('https://agents.globl.ai/prod/pod_automation',
        payload,
     
   );

      const responseData = response?.data?.pod_insights;

      if (responseData) {
        const insights = responseData.split('\n • ').slice(1); // Split into individual insights
        const formattedResponse = insights.map((insightData, index) => {
          // Detect if it's a blocker or other type of insight
          if (insightData.includes("blocker:")) {
            const [blocker, reportedDate, talentName] = insightData.split(/reported_date:|talent_name:/).map(part => part?.trim());
            
            return (
              <InsightCard key={index}>
                <InsightText> {blocker}</InsightText>
                <InsightInfo><strong>Reported Date:</strong> {reportedDate}</InsightInfo>
                <InsightInfo><strong>Talent Name:</strong> {talentName}</InsightInfo>
              </InsightCard>
            );
          } else {
            return (
              <InsightCard key={index}>
                <InsightText>{insightData}</InsightText>
              </InsightCard>
            );
          }
        });

        setMessages([...messages, { type: 'user', text: question }, { type: 'response', text: formattedResponse }]);
      } else {
        throw new Error('No pod insights found in the response');
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
      setErrorMessage('Error fetching data: ' + error.message);
    }
  };

  const toggleRecentSearches = () => {
    setShowRecentSearches(!showRecentSearches);
  };

  const handleMouseDown = (e) => {
    e.preventDefault();
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseMove = (e) => {
    const newWidth = e.clientX;
    setSidebarWidth(newWidth);
  };

  const handleMouseUp = () => {
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  return (
    <Container>
      <MainContent showRecentSearches={showRecentSearches} sidebarWidth={sidebarWidth}>
        <h3 style={{ fontWeight: "bold" }}>Pod Automation</h3>
        <MessagesContainer>
          {messages.map((message, index) => (
            <Message key={index} type={message.type}>
              {message.type === 'response' ? message.text : <p>{message.text}</p>}
            </Message>
          ))}
          {isLoading && <Message type="loading"> Loading PodInsights ...</Message>}
          {errorMessage && <Message type="error">{errorMessage}</Message>}
        </MessagesContainer>
        <InputContainer>
          <QuestionInput
            type="text"
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            placeholder="Enter your question here"
          />
          <Select id="jiraIssueType" value={jiraIssueType} onChange={handleDropdownChange}>
          <option value="">Select Issue Type</option>
        <option value="Story">Story</option>
        <option value="Bug">Bug</option>
        <option value="Epic">Epic</option>
        <option value="Story,Bug">Story & Bug</option>
        </Select>
          <Button onClick={handleSendClick}>Send</Button>
        </InputContainer>
      </MainContent>
    </Container>
  );
};

export default PodAutomation;

const Container = styled.div`
  display: flex;
  font-family: 'Poppins', sans-serif;
  width: 100vw;
  height: 100vh; /* Make the container full height */
  overflow: hidden; /* Prevent full page scroll */
  background-color: #e0f7fa;
  @media screen and (min-width:320px) and (max-width:1080px){
 display: flex;
 flex-direction:column;
margin-left:4rem;
  height: 100vh; /* Make the container full height */

}
`;


const Select=styled.select`
font-family:Poppins,Medium;
width: 30vw;
height:50px;
margin-left:1rem;
border-radius: 10px;
border: 2px solid #B7B7B7;
padding-left:25px;
font-weight:600;
    @media screen and (min-width:320px) and (max-width:1080px){
        width:12rem;
        height:max-height;
        }
`;

const MainContent = styled.div`
  display: flex;
  justify-content: center; /* Center the form horizontally */
  align-items: center; /* Center the form vertically */
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 5rem;
  width: calc(100% - ${({ showRecentSearches, sidebarWidth }) => (showRecentSearches ? sidebarWidth + 'px' : '0')});
  overflow: hidden; /* Prevent full page scroll */
    @media screen and (min-width:320px) and (max-width:1080px){
     @media screen and (min-width:320px) and (max-width:1080px){
 display: flex;
 flex-direction:column;
justify-content:center;
align-items:center;

  height: 100vh; /* Make the container full height */

}
`;

const MessagesContainer = styled.div`
  flex: 1;
  overflow-y: auto; /* Allow scrolling within the messages container */
  padding: 20px;
  margin-bottom: 80px; /* Ensure there is space for the form */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

const Message = styled.div`
  background-color: ${props =>
    props.type === 'user' ? '#caf0f8' : props.type === 'response' ? '#0077b6' : props.type === 'error' ? '#ff0000' : 'transparent'};
  color: ${props =>
    props.type === 'user' ? 'black' : props.type === 'response' ? 'white' : props.type === 'error' ? 'white' : 'black'};
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  max-width: 80%;
  word-wrap: break-word;
`;

const Button = styled.button`
  background-color: #00b4d8;
  color: white;
  height: 50px;
  border-radius: 6px;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  margin: 10px;

  &:hover {
    background-color: #0090ad;
  }
`;

const InsightCard = styled.div`
  background-color: #caf0f8;
  color: black;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
//   max-width: 80%;
`;

const InsightText = styled.p`
  font-weight: bold;
`;

const InsightInfo = styled.p`
  margin-left: 10px;
  font-style: italic;
`;

const InputContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
 
  max-width: 800px; /* Set a maximum width */
  margin: 20px 0;
    @media screen and (min-width:320px) and (max-width:1080px){
 display: flex;
 flex-direction:column;
justify-content:center;
align-items:center;
width:80%;
}
`;

const QuestionInput = styled.input`
  width: 90vw; /* The input should take up 100% of the available width */
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 1rem;
  height:4rem;
  font-weight:600;
      @media screen and (min-width:320px) and (max-width:1080px){
 display: flex;
 flex-direction:column;
justify-content:center;
align-items:center;
width:80%;
`;
