import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {cardShadow,hoverEffect,themeColor} from '../utils';
import styled from 'styled-components';
import Mypod from './Mypod';
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import { useAuthToken } from "../TokenContext";
import { Checkbox } from '@mui/material';
import { Breadcrumbs } from '@mui/material';
import { Link } from 'react-router-dom';

const Createtasktalent = () => {
    const location = useLocation();
const { podname, podid } = location.state;

const { isLoggedIn, user } = useSelector(state => state.auth);
const [isBlockerChecked, setIsBlockerChecked] = useState(false);

  const [taskDescription, setTaskDescription] = useState('');
  const [jiraItem, setJiraItem] = useState('');
  const[podtaskname,setPodtaskname]=useState('')
  const [taskDueDate, setTaskDueDate] = useState('');

  const [categories, setCategories] = useState('');
 
  const [formError, setFormError] = useState('');
 
  const token = useAuthToken();



  

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!taskDescription || !taskDueDate  || !categories ) {
      setFormError('Please fill in all required fields.');
      alert('Please fill in all required fields.');
      return;
    }
    const headers = {
      'Authorization' : token, // Replace with your actual token
      'Content-Type': 'application/json', // You can add more headers as needed
    };
    try {
        //'https://fl1lf3m25m.execute-api.us-east-1.amazonaws.com/dev/talentCreatePodTask'
        
      const response = await axios.post(process.env.REACT_APP_TALENT_TALENTCREATEPODTASK, {
        project_key:podname,
        pod_task_name:podtaskname,
        description:taskDescription,
        due_date: taskDueDate,
        candidate_id:user.data[0].c_id,
        Candidate_Name:user.data[0].firstName,
        issuetype: "story",
        summary:'',
        category:categories,
        pod_id:podid,
        jira_item:jiraItem,
        blockercheck: isBlockerChecked ? 1 : 0 // Set value based on checkbox
      }, {headers:headers});
      
      console.log('Form submitted successfully');
    
      // Clear form fields
      setTaskDescription('');
      setTaskDueDate('');
      
      setPodtaskname('');
      setCategories('');
      setFormError('');
      alert('Posted Successfully')
    //   window.location.reload();
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };
const id=2;
  
  return (
    <Div style={{marginTop:'12rem'}}>
      <Breadcrumbs aria-label="breadcrumb" style={{float:"left"}} >
  <Link to="/Pods" style={{ color: '#03045e', fontSize: "18px", fontFamily: 'Poppins,Medium', fontWeight: "600" }}>My Pods</Link>
  <span style={{ color: 'grey', fontSize: "18px", fontFamily: 'Poppins,Medium', fontWeight: "600" }}>&nbsp;&gt;&nbsp;</span>
  <span style={{ color: 'grey', fontSize: "18px", fontFamily: 'Poppins,Medium', fontWeight: "600" }}>Post Task</span>
</Breadcrumbs>
<br/>
<br/>

<br/>
      <h2>Task Form</h2>
      {formError && <p className="error">{formError}</p>}
      <CardContent>

      <form onSubmit={handleSubmit}>
      <CardContent1>
      <Postfield>
        <Label  htmlFor="taskDueDate">POD Task Name</Label>
        <p style={{fontWeight:'600'}}>:</p>
        <InputField
          type="text"
          id="podtaskname"
          name="podtaskname"
          placeholder='podtaskname'
      
          value={podtaskname}
          onChange={(e) => setPodtaskname(e.target.value)}
          required
        />
</Postfield>
        <Postfield>
        <Label  
        htmlFor="taskDescription">POD Task Description </Label>
        <p style={{fontWeight:'600'}}>:</p>
        <InputField
          type="text"
          id="taskDescription"
          name="taskDescription"
          placeholder='taskDescription'
          value={taskDescription}
          onChange={(e) => setTaskDescription(e.target.value)}
          required
        />
</Postfield>
<Postfield>
        <Label  htmlFor="taskDueDate">POD Task Due Date</Label>
        <p style={{fontWeight:'600'}}>:</p>
        <InputField
          type="date"
          id="taskDueDate"
          name="taskDueDate"
          placeholder='taskDueDate'
      
          value={taskDueDate}
          onChange={(e) => setTaskDueDate(e.target.value)}
          required
        />
</Postfield>

        <Postfield>
      
        <Label   htmlFor="categories">POD Categories  </Label>


        <p style={{fontWeight:'600'}}>:</p>


        <SelectField
          id="categories"
          name="categories"
          placeholder='categories'
          value={categories}
          onChange={(e) => setCategories(e.target.value)}
          required
        >
          <option  value="">categories</option>
        
            <option  value='Design'>Design</option>
            <option  value='Coding'>Coding</option>
            <option  value='Testing'>Testing</option>
            <option  value='Documentation'>Documentation</option>
         
        </SelectField>
       
</Postfield>
<Postfield>
        <Label  
        htmlFor="jiraItem">Jira Item </Label>
        <p style={{fontWeight:'600'}}>:</p>
        <InputField
          type="text"
          id="jiraItem"
          name="jiraItem"
          placeholder='Jira Item'
          value={jiraItem}
          onChange={(e) => setJiraItem(e.target.value)}
          
        />
</Postfield>
<Postfield>
    <span style={{color:"black",fontWeight:"600"}} htmlFor="blockerCheck">&#x2611; Check this box if it is a blocker</span>
    {/* <p style={{ fontWeight: '600' }}>:</p> */}
    <Checkbox
        type="checkbox"
        id="blockerCheck"
        checked={isBlockerChecked}
        onChange={(e) => setIsBlockerChecked(e.target.checked)}
    />
</Postfield>
        <Button type="submit">Submit</Button>
        </CardContent1>
      </form>
      </CardContent>
    </Div>
  );
};

export default Createtasktalent
const Div =styled.div`

// display:flex;
display:inline;
jutify-content:center;
flex-direction:column;
margin:auto;
margin-top:6rem;
align-items:center;

// margin-left:6.5rem;
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
  overflow:hidden;
justify-content:center;
align-items:center;
margin:auto;
width:24rem;
  }
`;
const CardContent=styled.div`
margin:0.4rem;
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;


border-radius:15px;
font-family:Poppins,Medium;
width:40rem;
height:max-height;
padding: 1rem;
background-color: #F2F4F5;

box-shadow:${cardShadow};
transition:0.45 ease-in-out;
&:hover {
box-shadow:${hoverEffect};
}

@media screen and (min-width:320px) and (max-width:1080px){
  width:20rem;
  margin:auto;
 
  
 }
`;

const Postfield=styled.div`
font-family:Poppins,Medium;
display:flex;
justify-content:center;
align-items:center;
margin:auto;
padding:10px

@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
  flex-direction:column;
`;
const CardContent1=styled.div`
margin:0.4rem;
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;

@media screen and (min-width:320px) and (max-width:1080px){
  width:20rem;
  margin:auto;
 
  
 }


`;
const InputField=styled.input`

color:grey;
border: 1px solid #cccccc;
border-radius: 5px;
width:20rem;
height:3.1rem;
margin-left:1rem;
padding-left:20px;
font-weight:600;
font-family:Poppins,Medium;
@media screen and (min-width:320px) and (max-width:1080px){
  width:15rem;
 
  
 }

`;


const SelectField=styled.select`

border: 1px solid #cccccc;
border-radius: 5px;
width:20rem;
margin-left:1rem;
height:3.1rem;
font-weight:600;
color:grey;
font-family:Poppins,Medium;
@media screen and (min-width:320px) and (max-width:1080px){
  width:15rem;
 
  
 }

`;
const Textarea = styled.textarea`
display:flex;
color:grey;
justify-content:center;
align-items:center;
margin:auto;
border: 1px solid #cccccc;
border-radius: 5px;
width:20rem;
height:3.1rem;
margin-left:1rem;
padding:10px;
padding-left:20px;
font-weight:600;
font-family:Poppins,Medium;

  @media screen and (min-width:320px) and (max-width:1080px){
    width: 15rem;
  }


`;
const Button=styled.button`
margin-top:1rem;
border:none;
border-radius: 10px;
background-color:#1DBF73;
box-shadow: 1px 3px 6px #00000029;
width:20rem;
height:3rem;
font-size:18px;
font-weight:600;
color:white;
margin-top:4rem;
@media screen and (min-width:320px) and (max-width:1080px){
  width:15rem;
 
  
 }
`;
const Label =styled.label`
font-family:Poppins,Medium;
font-weight: 600;
font-size:16px;
color :grey;
width:14rem;
`;

