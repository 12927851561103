import React, { useEffect,useState } from 'react';
import { useParams, useHistory, Redirect } from 'react-router-dom';
import axios from 'axios';
import { useDispatch, useSelector } from "react-redux";
import { logout } from "./components/action/auth";
import styled from 'styled-components';
import './components/css/addcandidate.css';
import { useIntl } from 'react-intl';

import moment from 'moment';
import {
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Paper,
  } from '@material-ui/core';
const ProfilePage = () => {
    const { firstName, c_id } = useParams();
    const history = useHistory();
    const [profileData, setProfileData] = useState(null);
    const [loading, setLoading] = useState(true);
    const intl = useIntl();
  

    // const { isLoggedIn } = useSelector(profileData => profileData.auth);
    const dispatch = useDispatch();
   
    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const response = await axios.post(process.env.REACT_APP_TALENT_GETVISIBILITY, {
                    id: c_id
                });
    
                if (response.data && response.data.data && response.data.data.length > 0) {
                    const profile = response.data.data[0];
                    const { visibility, ...userData } = profile;
    
                    // console.log("Fetched profile data:", profile);
                    // console.log("Visibility:", visibility);
                   
                    if (visibility === 'public') {
                        // console.log("Setting profile data for public visibility");
                        setProfileData(userData);
                    } else if (visibility === 'link') {
                        // console.log("Handling link visibility");
                        if ( window.location.search.includes('token')) {
                            const token = new URLSearchParams(window.location.search).get('token');
                            if (token === btoa(c_id)) {
                                // console.log("Token matches, setting profile data");
                                setProfileData(userData);
                            } else {
                                // console.warn("Token does not match, logging out");
                                dispatch(logout());
                                history.push('/login');
                            }
                        } else {
                            console.warn("Not logged in or missing token for link visibility, logging out");
                            dispatch(logout());
                            history.push('/login');
                        }
                    } else {
                        console.warn("Visibility is restricted or unknown, logging out");
                        dispatch(logout());
                        history.push('/login');
                    }
                } else {
                    console.warn("No profile data found, logging out");
                    dispatch(logout());
                  
                }
            } catch (error) {
                // console.error('Error fetching profile data:', error);
                dispatch(logout());
             
            } finally {
                setLoading(false);
            }
        };
    
        fetchProfileData();
    }, [c_id, , dispatch, history]);
    
    // Ensure other useEffects and conditional checks are correctly placed and structured
    
    // Ensure other useEffects and conditional checks are correctly placed and structured
    
  
    
    useEffect(() => {
       
            fetchAdditionalData();
        
    }, []);
    
    const fetchAdditionalData = async () => {
        try {
            const response = await axios.post(process.env.REACT_APP_TALENT_GETCANDPROF, {
                id: c_id
            });
    
            if (response.data && response.data.data) {
                const { education, certification, exp, podExperience } = response.data.data;
    
                setProfileData((prevProfileData) => ({
                    ...prevProfileData,
                    education,
                    certification,
                    experience: exp,
                    podExperience,
                }));
            }
        } catch (error) {
            console.error('Error fetching additional profile data:', error);
        }
    };
    
   
    
  
    
    if (!profileData) {
        return <div>No profile found or you do not have access.</div>;
    }
    
    // Render your component with profileData
    
    return (
       
   


<div className="container" style={{ "margin-top": "140px" }}>
<div className="row" >
<div className="col-lg-7" style={{  margin:'auto',    border: '2px solid #ccc',
        borderRadius: '8px',
        boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.2)',
        padding: '20px', opacity: 1 }}>
<center>

<h2 className="headsmall" style={{color:'#03045e'}}> {profileData.firstName} {profileData.lastName} profile</h2> 
<br></br>

            <div style={{ border: "2px solid #E7E7E7",boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)', borderRadius: "5px", padding: "40px", height: 'max-height', overflow: "auto",display:'flex',flexDirection:"column"}}>
<p style={{ marginLeft: "5px", color :  "#8EAEBC", fontWeight: "bold" , fontSize: "18px", float: "left", maxWidth: "100%", }} >{intl.formatMessage({ id: 'basicInformation' })}</p>

<p style={{ color :  "black", maxWidth: "100%", height: "auto", fontWeight: "bold", fontSize: "16px", float: "left",marginLeft: "5px"  }} >{intl.formatMessage({ id: 'email' })}</p>

<p style={{ color :  "grey", maxWidth: "100%", height: "auto", fontSize: "16px", float: "left",marginLeft: "5px" }}>{profileData.email}</p>
<p style={{  color :  "black", maxWidth: "100%", height: "auto", fontWeight: "bold", fontSize: "16px", float: "left",marginLeft: "5px"  }} >{intl.formatMessage({ id: 'country' })}</p>

<p style={{ color :  "grey", maxWidth: "100%", height: "auto", fontSize: "16px", float: "left",marginLeft: "5px" }}>{profileData.country}  
</p>
{profileData.country ==='usa'?<><p style={{  color :  "black", maxWidth: "100%", height: "auto", fontWeight: "bold", fontSize: "16px", float: "left",marginLeft: "5px"  }} >Work Authorization</p>

<p style={{ color :  "grey", maxWidth: "100%", height: "auto", fontSize: "16px", float: "left",marginLeft: "5px" }}>{profileData.work_authorization}  
</p></>
:(<></>)}


<p style={{color :  "black", maxWidth: "100%", height: "auto", fontWeight: "bold", fontSize: "16px", float: "left",marginLeft: "5px" }} >{intl.formatMessage({ id: 'about' })}</p>

<p style={{ color :  "grey", maxWidth: "100%", height: "auto", fontSize: "16px", float: "left",marginLeft: "5px" }} >{profileData.about}</p>

<br></br>
</div>


<br></br>
<br></br>
<div style={{ border: "2px solid #E7E7E7",boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)', borderRadius: "5px", padding: "40px",height: 'max-height', overflow: "auto",display:'flex',flexDirection:"column"}}>

            <p style={{ display:"flex",justifyContent:"center",alignItems:"center",margin:"auto" ,color :  "#8EAEBC", fontWeight: "bold" , fontSize: "18px", width: "160px",  }} >{intl.formatMessage({ id: 'skillsAndExpertise' })} </p>
            <br></br>

            {/* For Primary Expertise */}
            <p style={{ color :  "black", maxWidth: "100%", height: "auto", fontWeight: "bold", fontSize: "16px", float: "left",marginLeft: "5px" }} >{intl.formatMessage({ id: 'skills' })} </p>

<p style={{ color :  "grey", maxWidth: "100%", height: "auto", fontSize: "16px" }} >{profileData.primaryskill}, {profileData.secondaryskill} </p>

<p style={{ color :  "black", maxWidth: "100%", height: "auto", fontWeight: "bold", fontSize: "16px", float: "left",marginLeft: "5px"  }} >{intl.formatMessage({ id: 'industry' })}</p>
<p style={{ color :  "grey", maxWidth: "100%", height: "auto", fontSize: "16px" }} >{profileData.industry}</p>
<p style={{color :  "black", maxWidth: "100%", height: "auto", fontWeight: "bold", fontSize: "16px", float: "left",marginLeft: "5px"  }} >{intl.formatMessage({ id: 'techStack' })}</p>
<p style={{ color :  "grey", maxWidth: "100%", height: "auto",  fontSize: "16px" }} >{profileData.techStack}</p>
</div>


<br></br>
<div style={{ border: "2px solid #E7E7E7",boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)',borderRadius: "5px", padding: "40px", height: 'max-height', overflow: "auto",display:'flex',flexDirection:"column", justifyContent:"center",alignItems:"center",}}>


<br></br>
            <p style={{  color :  "#8EAEBC", fontWeight: "bold" , fontSize: "18px", width: "229px" }}>{intl.formatMessage({ id: 'educationAndCertifications' })}</p>

        
            <p  style={{ color :  "#8EAEBC" , fontSize: "18px", width: "229px" }} className="label2">{intl.formatMessage({ id: 'education' })} </p>

           
</div>


<br></br>
  {/* Education and Certifications */}
  <div style={{ border: "2px solid #E7E7E7",boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)',borderRadius: "5px", padding: "40px", height: 'max-height', overflow: "auto",display:'flex',flexDirection:"column", justifyContent:"center",alignItems:"center",}}>


<br></br>
            <p style={{  color :  "#8EAEBC", fontWeight: "bold" , fontSize: "18px", width: "229px" }}>{intl.formatMessage({ id: 'educationAndCertifications' })}</p>

        
            <p  style={{ color :  "#8EAEBC" , fontSize: "18px", width: "229px" }} className="label2">{intl.formatMessage({ id: 'education' })} </p>

          {/* Render education and certifications */}
          <p>{/* Education and certifications content here */}</p>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Degree</TableCell>
                  <TableCell>Institution</TableCell>
                  <TableCell>Start Date</TableCell>
                  <TableCell>End Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {profileData.education?.length>0 ?profileData.education.map((edu, index) => (
                  <TableRow key={index}>
                    <TableCell>{edu.degree}</TableCell>
                    <TableCell>{edu.institution}</TableCell>
                    <TableCell>{moment(edu.startDate).format('YYYY-MM-DD')}</TableCell>
                    <TableCell>{moment(edu.endDate).format('YYYY-MM-DD')}</TableCell>
                  </TableRow>
                  )) : (
                    <TableRow>
                      <TableCell colSpan={3}>No education data available</TableCell>
                    </TableRow>
                  )}
                
              </TableBody>
            </Table>
          </TableContainer>
        </div>
    
        <div style={{ border: "2px solid #E7E7E7",boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)',borderRadius: "5px", padding: "40px",height: 'max-height', overflow: "auto",display:'flex',flexDirection:"column", justifyContent:"center",alignItems:"center", }}>

<p  style={{ color :  "#8EAEBC" , fontSize: "18px", width: "229px" }} className="label2">{intl.formatMessage({ id: 'certifications' })}  </p>

          {/* Render education and certifications */}
          <p>{/* Education and certifications content here */}</p>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Certification Name</TableCell>
                  <TableCell>Institution</TableCell>
                  <TableCell>Start Date</TableCell>
                  <TableCell>End Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {profileData.certification?.length>0 ?profileData.certification.map((data, index) => (
                  <TableRow key={index}>
                    <TableCell>{data.degree}</TableCell>
                    <TableCell>{data.institution}</TableCell>
                    <TableCell>{moment(data.startDate).format('YYYY-MM-DD')}</TableCell>
                    <TableCell>{moment(data.endDate).format('YYYY-MM-DD')}</TableCell>
                  </TableRow>
                  )) : (
                    <TableRow>
                      <TableCell colSpan={3}>No certification data available</TableCell>
                    </TableRow>
                  )}
                
              </TableBody>
            </Table>
          </TableContainer>
        </div>
 
      {/* Work Experience */}
      <div style={{ border: "2px solid #E7E7E7",boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)', borderRadius: "5px", padding: "40px", height: 'max-height', overflow: "auto",display:'flex',flexDirection:"column",justifyContent:"center",alignItems:"center", }}>

<br></br>
<p style={{  color :  "#8EAEBC", fontWeight: "bold" , fontSize: "18px", width: "229px" }}>{intl.formatMessage({ id: 'workExperience' })} </p>
          {/* Render work experience */}
          <p>{/* Work experience content here */}</p>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Position</TableCell>
                  <TableCell>Organization</TableCell>
                  <TableCell>Start Date</TableCell>
                  <TableCell>End Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {profileData.experience?.length>0 ?profileData.experience.map((exp, index) => (
                  <TableRow key={index}>
                    <TableCell>{exp.work_position}</TableCell>
                    <TableCell>{exp.work_organization}</TableCell>
                    <TableCell>{moment(exp.workStartdate).format('YYYY-MM-DD')}</TableCell>
                    {exp.present === 0 ? (
                      <TableCell>{moment(exp.workEnddate).format('YYYY-MM-DD')}</TableCell>
                    ) : (
                      <TableCell>Present</TableCell>
                    )}
                  </TableRow>
             )) : (
                <TableRow>
                  <TableCell colSpan={3}>No experience data available</TableCell>
                </TableRow>
              )}
              </TableBody>
            </Table>
          </TableContainer>
    
      


<br></br>
</div>

<br></br>
<div style={{ border: "2px solid #E7E7E7",boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)', borderRadius: "5px", padding: "40px", height: 'max-height', overflow: "auto",display:'flex',flexDirection:"column",justifyContent:"center",alignItems:"center",margin:'auto' }}>

<br></br>
<p style={{color :  "#8EAEBC", fontWeight: "bold" , fontSize: "18px", width: "229px" }}>{intl.formatMessage({ id: 'podExperience' })}</p>
{profileData.podExperience?.length > 0 ? profileData.podExperience.map((data) => {
       return <div style={{ margin : "40px" }}> <p style={{ color :  "black", maxWidth: "100%", height: "auto", fontSize: "16px" }}  >{data.podName}</p>


</div>
}) : ( <>{intl.formatMessage({ id: 'noExperience' })}</>)}




<br></br>
</div>
<br></br>
<div style={{ border: "2px solid #E7E7E7",boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)', borderRadius: "5px", padding: "40px",height: 'max-height', overflow: "auto",display:'flex',flexDirection:"column",justifyContent:"center",alignItems:"center",}}>

<p style={{ color :  "#8EAEBC", fontWeight: "bold" , fontSize: "18px", width: "229px" }}>{intl.formatMessage({ id: 'otherInformation' })}</p>
<p style={{color :  "black", maxWidth: "100%", height: "auto", fontWeight: "bold", fontSize: "16px", float: "left",marginLeft: "5px"  }} >{intl.formatMessage({ id: 'portfolio' })}</p>

<p style={{ color :  "grey", maxWidth: "100%", height: "auto",  fontSize: "16px" }}  >{profileData.portfolio}</p>
<br></br>
<p style={{color :  "black", maxWidth: "100%", height: "auto", fontWeight: "bold", fontSize: "16px", float: "left",marginLeft: "5px" }} >{intl.formatMessage({ id: 'linkedIn' })}</p>

<p style={{ color :  "grey", maxWidth: "100%", height: "auto",  fontSize: "16px" }}  >{profileData.linkedin}</p>
<br></br>
<p style={{ color :  "black", maxWidth: "100%", height: "auto", fontWeight: "bold", fontSize: "16px", float: "left",marginLeft: "5px" }} >{intl.formatMessage({ id: 'preferredWorkHours' })}</p>


<p style={{ color :  "grey", maxWidth: "100%", height: "auto",  fontSize: "16px" }}  >{profileData.workhours}</p>
<br></br>




<br></br>
<p style={{ color :  "black", maxWidth: "100%", height: "auto", fontWeight: "bold", fontSize: "16px", float: "left",marginLeft: "5px"  }} >{intl.formatMessage({ id: 'currency' })}</p>

<p style={{ color :  "grey", maxWidth: "100%", height: "auto", fontSize: "16px" }}  >{profileData.currency}</p>
<br></br>

</div>

</center>
</div>


</div>
</div>
    );
};

export default ProfilePage;

