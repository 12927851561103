import React, { Component, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
  useParams,
} from "react-router-dom";
import axios from "axios";
import NotificationDropdown from "./components/Notificationtalent";
import Notificationmanagericon from "./components/Notificationmanagericon";
import AppBarIcons from "./components/AppBarIcons";
import "./App.css";
import Home from "./components/Home";
import TaskForm from "./components/TaskForm";
import LogIn from "./components/auth/LogIn";
import Leadpodrequests from "./components/Leadpodrequests";
import Register from "./components/auth/Register";
import Tulsi from "./components/auth/Tulsi";
import ForgotPassword from "./components/auth/ForgotPassword";
import ForgotPasswordVerification from "./components/auth/ForgotPasswordVerification";
import ChangePassword from "./components/auth/ChangePassword";
import ChangePasswordConfirm from "./components/auth/ChangePasswordConfirm";
import Welcome from "./components/auth/Welcome";
import Footer from "./components/Footer";
import { Auth } from "aws-amplify";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import CreateProfile from "./components/CreateProfile";
import Createprofiletest from "./components/Createprofiletest";
import ProjectSignupEp from "./components/ProjectSignupEp";
import CandidateHome from "./components/CandidateHome";
import EmployerHome from "./components/EmployerHome";
import CandidateSearch from "./components/CandidateSearch";
import EmployerProjectReq from "./components/EmployerProjectReq";
import Employeepf from "./components/Employeepf";
import SearchProjects from "./components/SearchProjects";
import ClientProjects from "./components/Clientdasboarddata/ClientProjects";
import ClientInvoices from "./components/Clientdasboarddata/ClientInvoices";
import TimeSubmit from "./components/TimeSubmit";

import PodactivitytalentRetro from "./components/PodactivitytalentRetro";
import "bootstrap/dist/css/bootstrap.min.css";
import Updatecandidate from "./components/Updatecandidate";
import MainContent from "./components/MainContent";
import MainLayout from "./components/MainLayout";
import Sidebar from "./components/Sidebar";
import { darkThemeColor } from ".././src/utils";
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Updateprofile from "./components/Updateprofile";
import CandidateHomeContent from "./components/CandidateHomeContent";
import styled from "styled-components";
import Header from "./components/Header";
import Clientprofile from "./components/Clientprofile";
import Costomerprofile from "./components/Costomerprofile";
import Client from "./components/Client";
import PostRequirement from "./components/PostRequirement";
import CreateGigForm from "./components/CreateGigForm";
import PostRequirementPod from "./components/PostRequirementPod";
import PostRequirementdata from "./components/ProjectRequirementdata";
import Edittalentpodactivitybyid from "./components/Edittalentpodactivitybyid";
import PodactivityviewalltalentEdit from "./components/PodactivityviewalltalentEdit";
import Browsetalents from "./components/Browsetalents";
import Browsetalentspartner from "./components/Browsetalentspartner";
import Subscription from "./components/Subscription";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Teammembers from "./components/Teammembers";
import Mypod from "./components/Mypod";
import PodActivity from './components/PodActivity';
import Podprovidermypod from "./components/Podprovidermypod";
import Createpod from "./components/Createpod";
import PartnerCreatepod from "./components/PartnerCreatepod";
import Candidatedetailsbyid from "./components/Candidatedetailsbyid";
import Reviewsandratingcandidate from "./components/Reviewsandratingcandidate";
// import firebase from 'firebase/compat/app';
import Popup from "./components/Popup";
import Popupstakeholdermanager from "./components/Popupstakeholdermanager";
import Master from "./components/Master";
import Adminlogin from "./components/auth/Adminlogin";
import CandidateAdminDashboard from "./components/CandidateAdminDashboard";
import ClientAdminDashboard from "./components/ClientAdminDashboard";
import PartnerAdminDashboard from "./components/PartnerAdminDashboard";
import ProfileSuccessScreen from "./components/ProfileSuccessScreen";
import Candidateapprove from "./components/Candidateapprove";
import Patnerdetailsapprove from "./components/Patnerdetailsapprove";
import Leadpodrequestdetails from "./components/Leadpodrequestdetails";
import Clientadminprofile from "./components/Clientadminprofile";
import PodCreatedSuccessFullyCan from "./components/PodCreatedSuccessFullyCan";
import Pods from "./components/Pods";
import Podstalent from "./components/Podstalent";
import Createtasktalent from "./components/Createtasktalent";
import Viewpodretrotalent from "./components/Viewpodretrotalent";
import PartnerPods from "./components/PartnerPods";
import ClientPods from "./components/ClientPods";
import EditPostRequirementCand from "./components/EditPostRequirementCand";
import PostRequirementCand from "./components/PostRequirementCand";
import ViewPostedProReq from "./components/ViewPostedProReq";
import ManagePod from "./components/ManagePod";
import PartnerManagePod from "./components/PartnerManagePod";
import ClientManagePod from "./components/ClientManagePod";
import FindPodsDetails from "./components/FindPodsDetails";
import FindPodDetailsClient from "./components/FindPodDetailsClient";
import FindPods from "./components/FindPods";
import FindPodsClient from "./components/FindPodsClient";
import Recommendedproject from "./components/Recomendedproject";
import Poddetaillscandidate from "./components/Poddetaillscandidate";
import Clientcreatepod from "./components/Clientcreatepod";
import Podcandidatereview from "./components/Podcandidatereview";
import ReviewsandratingPodcandidate from "./components/ReviewsandratingPodcandidate";
import ReviewsandratingClient from "./components/ReviewsandratingClient";
import Providerprofile from "./components/podprovider/Providerprofile";
import Providerdashboard from "./components/podprovider/Providerdashboard";
import Statusupdate from "./components/Statusupdate";
import AddPodactivityLead from "./components/AddPodactivityLead";
import Podactivitytalent from "./components/Podactivitytalent";
import PodActivityLead from "./components/PodActivityLead";
import PartnerINviteTalent from "./components/podprovider/PartnerINviteTalent";
import Updatedailystatus from "./components/Updatedailystatus";
import { DisabledByDefault } from "@mui/icons-material";
import Podactivitydashboardviewall from "./components/Podactivitydashboardviewall";
import Podactivityviewalltalent from "./components/Podactivityviewalltalent";
import Clientcreatepodinvite from "./components/Clientcreatepodinvite";
import BarGraph from "./components/BarGraph";
import BarGraphstories from "./components/BarGraphstories";
import SprintVelocityChart from "./components/SprintVelocityChart ";
import PRcountgraph from "./components/PRcountgraph";
import TalentViewTask from "./components/TalentViewTask";
import ViewPodTasksManager from "./components/ViewPodTasksManager";
import ViewPodTasksManagerbyid from "./components/ViewPodTasksManagerbyid";
import Clientinvite from "./components/Clientinvite";
import Detailsviewmetrics from "./components/Detailsviewmetrics";
import Defectsdetailviewchart from "./components/Defectsdetailviewchart";
import PodMetricsmanager from "./components/PodMetricsmanager";
import Githublinespiecharttalent from "./components/Githublinespiecharttalent";
import DetailviewGithublinesofcode from "./components/DetailviewGithublinesofcode";
import PRCountTalent from "./components/PRCountTalent";
import Githublinesofcodebydaywise from "./components/Githublinesofcodebydaywise";
import DetailPRcountmanager from "./components/DetailPRcountmanager";
import CloudCost from "./components/CloudCost";
import Podretromanager from "./components/Podretromanager";
import PodMetricsTalentDetailview from "./components/PodMetricsTalentDetailview";
import InvoicesClient from "./components/InvoicesClient";
import CicdPiechartmanagerdetailvivew from "./components/CicdPiechartmanagerdetailvivew";
import CicdPopup from "./components/CicdPopup";
import TimeDetailView from "./components/TimeDetailView";
import PartnerSubscription from "./components/PartnerSubscription";
import PartnerInvoices from "./components/podprovider/PartnerInvoices";
import PartnerInvite from "./components/podprovider/PartnerInvite";
import PartnerSettings from "./components/podprovider/PartnerSettings";
import ProfileVisibility from "./ProfileSettings";
import ProfilePage from "./ProfilePage";


import PopupDetailviewmetrics from "./components/PopupDetailviewmetrics";
import PopupDefectsdetailviewchart from "./components/PopupDefectsdetailviewchart";
import Stakeholder from "./components/Stakeholder";
import Partnerguest from './components/Partnerguest';
import Sonarprojectdetailsmetrics from "./components/Sonarprojectdetailsmetrics";
import ProjectPieChart from "./components/ProjectPieChart";
import Setting from "./components/Setting";
import EditTalentSettingsForm from './components/EditTalentSettingsForm';
import PodActivityStakeholder from "./components/PodActivityStakeholder";
import StakeholderDashboard from "./components/StakeholderDashboard";
import PodActivitystakeholderviewall from "./components/PodActivitystakeholderviewall";
import Stakeholderinvite from "./components/Stakeholderinvite";
import PodAssist from "./components/PodAssist";
import OpenAi from "./components/OpenAi";
import PodInsights from "./components/PodInsights";
import PodAutomation from "./components/PodAutomation";
import Boardtask from "./components/Boardtask";
import KanbanBoard from "./components/KanbanBoard";
import KanbanboardTalent from "./components/KanbanboardTalent";
import { FaBars, FaTimes } from "react-icons/fa"; // Import icons for menu and close
import Portfolio from "./components/Portfolio";
import ActivityTracker from "./components/ActivityTracker";
import { logout } from "./components/action/auth";
import PropTypes from "prop-types";
import FindPodsPartnerGuest from "./components/FindPodsPartnerGuest";
import FindTalentPartnerGuest from "./components/FindTalentPartnerGuest";
import Sidebarnew from './components/Sidebarnew';
import Dashboardnew from './components/Dashboardnew';
import Dashboardnewclient from "./components/Dashboardnewclient";
import { CssBaseline } from '@mui/material';
import ClientLayout from './components/ClientLayout';
import { SidebarProvider } from './components/SidebarContext'; // Adjust the import path accordingly
 import SubscriptionNew from "./components/SubscriptionNew";
import StakeholderLayout from "./components/StakeholderLayout";
import ResumeUploadPage from "./components/ResumeUploadPage";
import { useAuthToken } from "./TokenContext";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPEKEY);

library.add(faEdit);

const App = ({ changeLocale, currentLocale }) => {
  const [redirectToLogin, setRedirectToLogin] = useState(false);
  const dispatch = useDispatch();
  const token = useAuthToken();
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    const handleResize = () => {
      // Always expanded when the screen width is between 320px and 1080px
      const isMediaWidth =
        window.innerWidth >= 320 && window.innerWidth <= 1080;
      setExpanded(isMediaWidth);
    };

    // Add resize event listener
    window.addEventListener("resize", handleResize);

    // Initial check when the component mounts
    handleResize();

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const { isLoggedIn, user } = useSelector((state) => state.auth);
  const isUserLoggedIn = isLoggedIn === true;

  const [state, setState] = useState({
    isAuthenticated: false,
    isAuthenticating: true,
    user: null,
  });

  const [isrender, setisrender] = useState(true);

  const history = useHistory();

  const setAuthStatus = (authenticated) => {
    setState({ isAuthenticated: authenticated });
  };

  const setUser = (user) => {
    setState({ user: user });
  };

  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });

  useEffect(() => {
    const onInactive = () => {
      // Log out the user
      dispatch(logout());

      // Set redirectToLogin to true to trigger navigation
      setRedirectToLogin(true);
    };

    const activityTracker = new ActivityTracker(60 * 60 * 1000, onInactive); // 1 hour timeout

    return () => {
      window.removeEventListener("mousemove", activityTracker.resetTimer);
      window.removeEventListener("keydown", activityTracker.resetTimer);
    };
  }, [dispatch]);

  useEffect(async () => {
    try {
      const session = await Auth.currentSession();
      if (session) {
        setAuthStatus(true);
        const user = await Auth.currentAuthenticatedUser();
        setUser(user);
      }
    } catch (error) {

    }

    setState({ isAuthenticating: false });
  }, []);

  const authProps = {
    isAuthenticated: isLoggedIn,
    user: state.user,
    setAuthStatus: setAuthStatus,
    setUser: setUser,
  };

  let path =
    window.location.pathname == "/" ? "Home" : window.location.pathname;
    const [sidebarOpen, setSidebarOpen] = useState(true);

    const handleSidebarToggle = () => {
      setSidebarOpen(!sidebarOpen);
    };

    const path3 = window.location.pathname; // Get current path
   
    const [collapsed, setCollapsed] = useState(true); // Initial state
    
  const [access, setAccess] = useState(null); // Access status
  const [loading, setLoading] = useState(true); // Loading state for the request

  const [subscriptionAccess, setSubscriptionAccess] = useState(null);

  const[Subscription,setSubscription]=useState({})
  const[subscrictionfetch,setSubscriptionfetch]=useState(null);
  const [Loading, setloading] = useState(true);
 


  // useEffect(() => {

 
  
  //   if (user && user.data && user.data.length > 0) {

  //   const body = {
  //     client_code: user?.data[0]?.type === 'Candidate' 
  //       ? user?.data[0]?.talent_code 
  //       : user?.data[0]?.client_code
  //   };
    
  //   const headers = {
  //     'Authorization' : token, // Replace with your actual token
  //     'Content-Type': 'application/json', // You can add more headers as needed
  //   };
  // // Check if the client code contains "PARTNER"
  // if (user.data[0]?.type !== 'Candidate') {
  //   if (user.data[0].client_code.includes("PARTNER")) {
  //     // Second API call
  //     axios.post(process.env.REACT_APP_PARTNER_FTECHSUBSCCRIPTIONPARTNER, body, { headers: headers })
  //       .then((res) => {
  //         // Process the response as needed for the second API
  //         setSubscription(res?.data?.data);
  //         setSubscriptionfetch(res?.data?.data[0]?.priceID);
  //         setloading(false);
  //       })
  //       .catch((err) => {
  //         console.log("Error in the second API call:", err);
  //         setloading(false);
  //       });
  //   } else {
  //     // First API call
  //     axios.post(process.env.REACT_APP_FETCHSUBSCRIPTIONDATA, body, { headers: headers })
  //       .then((res) => {
  //         // Process the response as needed for the first API
  //         setSubscription(res?.data?.data);
  //         setSubscriptionfetch(res?.data?.data[0]?.priceID || null);
  //         setloading(false);
  //       })
  //       .catch((err) => {
  //         console.log("Error in the first API call:", err);
  //         setloading(false);
  //       });
  //   }
  // }}
  // }, [token]);

  // useEffect(() => {
  //   if (user && user.data && user.data.length > 0) {
     
  //     const body = {
  //       client_code: user.data[0].talent_code,
  //     };

  //     const headers = {
  //       Authorization: token, // Replace with your actual token
  //       'Content-Type': 'application/json', // You can add more headers as needed
  //     };

  //     axios
  //       .post(process.env.REACT_APP_FETCHSUBSCRIPTIONDATA, body, { headers })
  //       .then((res) => {
  //         console.log('response',res)
  //         setSubscription(res.data.data);
  //         setSubscriptionfetch(res.data.data[0]?.priceID );
  //       })
  //       .catch((err) => {
  //         console.log('catch', err);
  //       })
  //       .finally(() => {
  //         setLoading(false); // Set loading to false once the API call is completed.
  //       });
  //   }
  // }, [token, user]);
  
  useEffect(() => {
    const fetchSubscriptionAccess = async () => {
      if (user && user.data && user.data.length > 0) {
        const body = {
          client_code: user?.data[0]?.type === 'Candidate' 
            ? user?.data[0]?.talent_code 
            : user?.data[0]?.client_code,
        };
    
        const headers = {
          'Authorization': token,
          'Content-Type': 'application/json',
        };
    
        try {
          let response;
          
          // Check if the user is a client and a partner
          if (user?.data[0]?.type !== 'Candidate' && user.data[0]?.client_code.includes("PARTNER")) {
            // Partner client: Use the partner-specific API
            response = await axios.post(process.env.REACT_APP_PARTNER_FTECHSUBSCCRIPTIONPARTNER, body, { headers });
          } else {
            // Otherwise (both Candidate and non-partner Client), use the general subscription data API
            response = await axios.post(process.env.REACT_APP_FETCHSUBSCRIPTIONDATA, body, { headers });
          }
    
          // Log the response for debugging purposes
          console.log("Response from subscription API:", response);
    
          // Extract priceID from the response
          const priceID = response?.data?.data[0]?.priceID || null;
          console.log('Extracted priceID:', priceID);
    
          const payload = { priceId: priceID };
          console.log('Payload for subscriptionPageaccess:', payload);
    
          if (payload.priceId === null) {
            console.warn('Warning: priceId is null. Ensure the API returned the correct data.');
          }
    
          const accessResponse = await fetch(process.env.REACT_APP_SUBSCRIPTIONPAGEACCESS, {
            method: 'POST',
            headers: {
              'Authorization': token,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
          });
    
          const data = await accessResponse.json();
          setSubscriptionAccess(data?.body);
          
        } catch (error) {
          console.error('Error fetching subscription access:', error);
        }
      }
    };
    
  
    fetchSubscriptionAccess();
  }, [token]);
  
  const hasAccess = (featureName) => {
    // If the subscription data is still loading, return false and wait for it to finish
    if (!subscriptionAccess) {
     // console.log("Data is still loading...");
      return false;
    }
  
    const feature = subscriptionAccess?.find(f => f.feature_name === featureName);
    // console.log(`Checking access for feature: ${featureName}`);
    // console.log("Feature found:", feature);
    
    // If the feature is found, check if access is TRUE
    if (feature) {
      //console.log("Access value for feature:", feature.access);
      return feature.access === "TRUE";
    }
  
    // If the feature is not found, return false
    return false;
  };
  
  
  
   

  
  
  return (

  
    
      <Container>
        
       {!state.isAuthenticating && (
        <Elements stripe={stripePromise}>
          <Router>
            
        
          <Route
  exact
  path="/candidate-dashboard"
  render={(props) => {
    if (!isUserLoggedIn || user.data[0].type !== "Candidate") {
      return <Redirect to={{ pathname: "/login" }} />;
    }

    if (!subscriptionAccess) {
      console.log("subscriptionAccess is still null, preventing access check.");
      return <div>Loading subscription data...</div>; // Another loading UI or redirect
    }

    if (!hasAccess("POD_Metrics_(Dashboard/Details)")) {
      alert("Your subscription does not support this Page.");
      return <Redirect to="/" />;
    }

    return <MainLayout collapsed={collapsed} />;
  }}
/>

             <Header onMenuClick={handleSidebarToggle} />
         
            
            
            {redirectToLogin && <Redirect to={{ pathname: "/login" }} />}
            {user ? (
  // path !== "Home" &&
  path !== "/login" &&
  path !== "/Clientcreatepodinvite" &&
  path.slice(0, 8) !== "/Setting" &&
  path.slice(0, 23) !== "/EditTalentSettingsForm" &&
  path.slice(0, 16) !== "/ClientManagePod" &&
  path.slice(0, 13) !== "/Clientinvite" &&
  path !== "/Leadpodrequests" &&
  path.slice(0, 17) !== "/costomer-profile" &&
  path.slice(0, 20) !== "/stakeholder-profile" &&
  path.slice(0, 21) !== "/partnerguest-profile" &&
  // path !== "/FindPods" &&
  path !== "/provider-profile" &&
  path.slice(0, 17) !== "/client-createpod" &&
  path.slice(0, 19) !== "/podcandidatereview" &&
  path.slice(0, 15) !== "/InvoicesClient" &&
  path !== "/ManagePod" &&
  // path !== "/OpenAi" &&
  // path !== "/PodInsights" &&
  path !== "/MainLayout1" &&
  path.slice(0, 16) !== "/FindPodsDetails" &&
  path.slice(0, 22) !== "/FindPodDetailsClient" &&
  // path !== "/FindPodsClient" &&
  path !== "/FindPodsPartnerGuest" &&
  path !== "/FindTalentPartnerGuest" &&
  path.slice(0, 22) !== "/clientadmin-dashboard" &&
  path.slice(0, 20) !== "/clientadmin-profile" &&
  path !== "/FindPodsCan" &&
  path !== "/profilesuccesss" &&
  path.slice(0, 14) !== "/candidatebyid" &&
  path !== "/create-pod" &&
  path !== "/ManagePod" &&
  path !== "/Team" &&
  
  path !== "/PartnerInvoices" &&
  path !== "/PartnerSettings" &&
  path !== "/ProfilePage" &&
  path !== "/PartnerInvite" &&
 
  path !== "/project-requirment" &&
  path !== "/candidatehome" &&
  path !== "/client-profile" &&
  path.slice(0,14) !== "/browse-talent" &&
  path !== "/PostRequirementPod" &&
  path !== "/PostRequirement" &&
  path !== "/CreateGigForm" &&
  path !== "/PostRequirementdata" &&
  path !== "/CreateProfile" &&
  path !== "/forgotpassword" &&
  path !== "/changepassword" &&
  path !== "/register" &&
  path !== "/forgotpasswordverification" &&
  path !== "/PartnerSubscription"&&
 
  path.slice(0,15) !== "/manage-profile" ?(<>  <Sidebarnew 
    open={true} // or dynamic based on your needs
    collapsed={collapsed}
    setCollapsed={setCollapsed}
  />
</>
)  : (
                <p></p>
              )
            ): (
              <p></p>
            )}
<Route
  exact
  path="/client-dashboard"
  render={(props) => {
    // Check if the user is logged in and is a "Client"
    if (!isUserLoggedIn || user.data[0].type !== "Client") {
      return <Redirect to={{ pathname: "/login" }} />;
    }
    if (!subscriptionAccess) {
      console.log("subscriptionAccess is still null, preventing access check.");
      return <div>Loading subscription data...</div>; // Another loading UI or redirect
    }
    // Check access rights for Client Dashboard
    if (!hasAccess("POD_Metrics_(Dashboard/Details)")) {
      alert("Your subscription does not support accessing the Client Dashboard.");
      return <Redirect to="/" />;
    }

    return <ClientLayout collapsed={collapsed} expanded={expanded} />;
  }}
/>



            <Route
              exact
              path="/"
              render={(props) => (
                <CandidateHomeContent
                  {...props}
                  auth={authProps}
                  currentLocale={currentLocale}
                  changeLocale={changeLocale}
                />
              )}
            />
              <Route path="/profile/:firstName/:c_id" component={ProfilePage} />
            <Route
              exact
              path="/manage-profile"
              render={(props) => {
                return isUserLoggedIn && user.data[0].type == "Candidate" ? (
                  <Updateprofile
                    changeLocale={changeLocale}
                    currentLocale={currentLocale}
                  />
                ) : (
                  <Redirect to={{ pathname: "/login" }} />
                );
              }}
            />



<Route
              exact
              path="/Dashboardnew"
              render={(props) => {
                return isUserLoggedIn && user.data[0].type == "Candidate" ? (
                  <Dashboardnew
                    
                  />
                ) : (
                  <Redirect to={{ pathname: "/login" }} />
                );
              }}
            />
<Route
              exact
              path="/Dashboardnewclient"
              render={(props) => {
                return isUserLoggedIn && user.data[0].type == "Client" || user.data[0].type == "stakeholder" ? (
                  <Dashboardnewclient
                    
                  />
                ) : (
                  <Redirect to={{ pathname: "/login" }} />
                );
              }}
            />


<Route
              exact
              path="/Createprofiletest"
              render={(props) => {
                return isUserLoggedIn  ? (
                  <Createprofiletest />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
 <Route
  exact
  path="/CreateProfile"
  render={(props) => {
    // Check if data is still loading
    if (loading) {
      console.log("Data is still loading, preventing render...");
      return <div>Loading...</div>;  // Optional loading UI
    }

    // Prevent access check if subscriptionAccess is null
    if (!subscriptionAccess) {
      console.log("subscriptionAccess is still null, preventing access check.");
      return <div>Loading subscription data...</div>; // Another loading UI or redirect
    }

    const access = hasAccess("Create_Profile_(Manager/Talent)");
    console.log("Access result for CreateProfile:", access);

    if (!access) {
      alert("Your subscription does not support creating profiles.");
      return <Redirect to="/" />;
    }

    return isUserLoggedIn ? (
      <CreateProfile />
    ) : (
      <Redirect
        to={{
          pathname: "/login",
        }}
      />
    );
  }}
/>



            <Route
              exact
              path="/TalentViewTask"
              render={(props) => {
                return isUserLoggedIn && user.data[0].type == "Candidate" ? (
                  <TalentViewTask />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
            <Route
              exact
              path="/ViewPodTasksManager"
              render={(props) => {
                return isUserLoggedIn && user.data[0].type == "Client" ? (
                  <ViewPodTasksManager />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
            <Route
              exact
              path="/ViewPodTasksManagerbyid"
              render={(props) => {
                return isUserLoggedIn && user.data[0].type == "Client" ? (
                  <ViewPodTasksManagerbyid  collapsed={collapsed} expanded={expanded}/>
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />



<Route
              exact
              path="/ResumeUploadPage"
              render={(props) => {
                return isUserLoggedIn && user.data[0].type == "Candidate" ? (
                  <ResumeUploadPage />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />

            <Route
              exact
              path="/PartnerInvoices"
              render={(props) => {
                return isUserLoggedIn && user.data[0].type == "Provider" ? (
                  <PartnerInvoices />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
            <Route
              exact
              path="/PartnerSettings"
              render={(props) => {
                return isUserLoggedIn && user.data[0].type == "Provider" ? (
                  <PartnerSettings />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
            <Route
              exact
              path="/PartnerINviteTalent"
              render={(props) => {
                return isUserLoggedIn && user.data[0].type == "Provider" ? (
                  <PartnerINviteTalent />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
            <Route
              exact
              path="/PartnerInvite"
              render={(props) => {
                return isUserLoggedIn && user.data[0].type == "Provider" ? (
                  <PartnerInvite />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
            <Route
              exact
              path="/PartnerSubscription"
              render={(props) => {
                return isUserLoggedIn ? (
                  <SubscriptionNew />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
            {/* <Route exact path="/manage-profile" render={(props) =>{

return  isUserLoggedIn && user.data[0].type == "Candidate" ? (
<Updateprofile                    
/>):(

<Redirect to={{
pathname: '/login',
}}
/>                                
)
}

} /> */}
            <Route
              exact
              path="/ViewPostedProReq"
              render={(props) => {
                return isUserLoggedIn && user.data[0].type == "Candidate" ? (
                  <ViewPostedProReq />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
            {/* <Route exact path="/TechStackDevloper" render={(props) => <TechStackDevloper {...props} auth={authProps} />} /> */}
            {/* <Route exact path="/Confirm" render={(props) => <Confirm {...props} auth={authProps} />} /> */}
            {/* <Route exact path="/PostedProReq" render={(props) => <PostedProReq {...props} auth={authProps} />} /> */}
            {/* <Route exact path="/EditPostRequirementCand/:id" render={(props) => <EditPostRequirementCand {...props} auth={authProps} />} /> */}
            {/* <Route exact path="/FindPodsCan" render={(props) => <FindPodsCan {...props} auth={authProps} />} />
<Route exact path="/FindPodsCan" render={(props) => <FindPodsCan {...props} auth={authProps} />} /> */}
            <Route
              exact
              path="/PodCreatedSuccessFullyCan"
              render={(props) => (
                <PodCreatedSuccessFullyCan {...props} auth={authProps} />
              )}
            />
            <Route
              exact
              path="/Pods"
              render={(props) => {
                if (isUserLoggedIn) {
                  const userType = user.data[0].type;
                  if (
                    userType === "Candidate" &&
                    user.data[0].isPodLeader === "true"
                  ) {
                    return <Pods />;
                  } else if (userType === "Candidate") {
                    return <Podstalent />;
                  } else if (userType === "Client") {
                    return <ClientPods />;
                  } else if (userType === "Provider") {
                    return <PartnerPods />;
                  }
                }
                return (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
            <Route
              exact
              path="/Notificationmanagericon"
              render={(props) => {
                return isUserLoggedIn && user.data[0].type == "Client" ? (
                  <Notificationmanagericon />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />


            <Route
              exact
              path="/Viewpodretrotalent"
              render={(props) => {
                return isUserLoggedIn && user.data[0].type == "Candidate" ? (
                  <Viewpodretrotalent />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
            <Route
              exact
              path="/Createtasktalent"
              render={(props) => {
                return isUserLoggedIn && user.data[0].type == "Candidate" ? (
                  <Createtasktalent />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
            <Route
              exact
              path="/NotificationDropdown"
              render={(props) => {
                return isUserLoggedIn && user.data[0].type == "Candidate" ? (
                  <NotificationDropdown />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
            {/* <Route exact path="/ReviewsandratingPodcandidate" render={(props) => <ReviewsandratingPodcandidate {...props} auth={authProps} />} /> */}
            <Route
              exact
              path="/ReviewsandratingPodcandidate"
              render={(props) => {
                return isUserLoggedIn && user.data[0].type == "Candidate" ? (
                  <ReviewsandratingPodcandidate />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
            {/* NEW CODE */}
            <Route
              exact
              path="/Leadpodrequests"
              render={(props) => {
                return isUserLoggedIn && user.data[0].type == "Client" ? (
                  <Leadpodrequests />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
            {/* <Route exact path="/ReviewsandratingClient" render={(props) => <ReviewsandratingClient {...props} auth={authProps} />} /> */}
            <Route
              exact
              path="/ReviewsandratingClient"
              render={(props) => {
                return isUserLoggedIn && user.data[0].type == "Client" ? (
                  <ReviewsandratingClient />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
            {/* <Route exact path="/ViewPostedProReq" render={(props) => <ViewPostedProReq {...props} auth={authProps} />} /> */}
            {/* <Route exact path="/Reviewsandratingcandidate" render={(props) => <Reviewsandratingcandidate {...props} auth={authProps} />} /> */}
            <Route
              exact
              path="/Reviewsandratingcandidate"
              render={(props) => {
                return isUserLoggedIn && user.data[0].type == "Candidate" ? (
                  <Reviewsandratingcandidate />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
            <Route
              exact
              path="/podcandidatereview"
              render={(props) => (
                <Podcandidatereview {...props} auth={authProps} />
              )}
            />
            <Route
              exact
              path="/provider-profile"
              render={(props) => {
                return isUserLoggedIn && user.data[0].type == "Provider" ? (
                  <Providerprofile />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
            <Route
              exact
              path="/provider-dashboard"
              render={(props) => {
                return isUserLoggedIn && user.data[0].type == "Provider" ? (
                  <Providerdashboard />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
            {/* <Route exact path="/ManagePod" render={(props) => <ManagePod {...props} auth={authProps} />} /> */}
            {/* <Route exact path="/FindPodsDetails/:id" render={(props) => <FindPodsDetails {...props} auth={authProps} />} /> */}
            ​
            <Route
              exact
              path="/FindPodsDetails"
              render={(props) => {
                return isUserLoggedIn && user.data[0].type == "Client" ? (
                  <FindPodsDetails />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
            {/* <Route exact path="/FindPodDetailsClient/:id" render={(props) => <FindPodDetailsClient {...props} auth={authProps} />} /> */}
            <Route
              exact
              path="/FindPodDetailsClient/:id"
              render={(props) => {
                return isUserLoggedIn ? (
                  <FindPodDetailsClient {...props} auth={authProps} />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
            {/* <Route exact path="/FindPods" render={(props) => <FindPods {...props} auth={authProps} />} /> */}
            ​
          
<Route
  exact
  path="/FindPods"
  render={(props) => {
    if (!subscriptionAccess) {
      console.log("subscriptionAccess is still null, preventing access check.");
      return <div>Loading subscription data...</div>; // Another loading UI or redirect
    }
    // Check if the user has access to Find PODs
    if (!hasAccess("Find_PODs_(As Client or Talent)")) {
      alert("Your subscription does not support finding PODs.");
      return <Redirect to="/" />;
    }

    // Check if the user is logged in and of the correct type
    return isUserLoggedIn && user.data[0].type === "Candidate" ? (
      <FindPods />
    ) : (
      <Redirect
        to={{
          pathname: "/login",
        }}
      />
    );
  }}
/>
            <Route
              exact
              path="/recommendedproject"
              render={(props) => (
                <Recommendedproject {...props} auth={authProps} />
              )}
            />
            <Route
              exact
              path="/PartnerCreatepod"
              render={(props) => (
                <PartnerCreatepod {...props} auth={authProps} />
              )}
            />
            {/* <Route exact path="/FindPodsClient" render={(props) => <FindPodsClient {...props} auth={authProps} />} /> */}
            <Route
  exact
  path="/FindPodsClient"
  render={(props) => {
    if (!subscriptionAccess) {
      console.log("subscriptionAccess is still null, preventing access check.");
      return <div>Loading subscription data...</div>; // Another loading UI or redirect
    }
    // Check if the user has access to Find PODs
    if (!hasAccess("Find_PODs_(As Client or Talent)")) {
      alert("Your subscription does not support finding PODs.");
      return <Redirect to="/" />;
    }

    // Check if the user is logged in and of the correct type
    return isUserLoggedIn && user.data[0].type === "Client" ? (
      <FindPodsClient {...props} auth={authProps} />
    ) : (
      <Redirect
        to={{
          pathname: "/login",
        }}
      />
    );
  }}
/>
             <Route
              exact
              path="/FindPodsPartnerGuest"
              render={(props) => {
                return isUserLoggedIn && user.data[0].type == "PartnerGuest" ? (
                  <FindPodsPartnerGuest {...props} auth={authProps} />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />

<Route
              exact
              path="/FindTalentPartnerGuest"
              render={(props) => {
                return isUserLoggedIn && user.data[0].type == "PartnerGuest" ? (
                  <FindTalentPartnerGuest {...props} auth={authProps} />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />


            {/* <Route exact path="/EditPostRequirementCand/:id" render={(props) => <EditPostRequirementCand {...props} auth={authProps} />} /> */}
            <Route
              exact
              path="/EditPostRequirementCand/:id"
              render={(props) => {
                return isUserLoggedIn && user.data[0].type == "Candidate" ? (
                  <EditPostRequirementCand />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
            ​
            <Route
  exact
  path="/PodactivitytalentRetro"
  render={(props) => {
    // Check if the user is logged in and is a Candidate
    if (!isUserLoggedIn) {
      return <Redirect to={{ pathname: "/login" }} />;
    }

    if (user.data[0].type !== "Candidate") {
      return <Redirect to={{ pathname: "/login" }} />;
    }
    if (!subscriptionAccess) {
      console.log("subscriptionAccess is still null, preventing access check.");
      return <div>Loading subscription data...</div>; // Another loading UI or redirect
    }
    // Check access rights for Candidate to POD Retro
    if (!hasAccess("POD_Retro")) {
      alert("Your subscription does not support accessing POD Retro.");
      return <Redirect to="/" />;
    }

    return <PodactivitytalentRetro />;
  }}
/>

            ​
            <Route
  exact
  path="/Podactivitytalent"
  render={(props) => {
    // Check if the user is logged in, is a Candidate, and has access to POD Activity
    if (!isUserLoggedIn) {
      return <Redirect to={{ pathname: "/login" }} />;
    }

    if (user.data[0].type !== "Candidate") {
      return <Redirect to={{ pathname: "/login" }} />;
    }
    if (!subscriptionAccess) {
      console.log("subscriptionAccess is still null, preventing access check.");
      return <div>Loading subscription data...</div>; // Another loading UI or redirect
    }

    if (!hasAccess("POD_Activity_(Manager/Talent)")) {
      alert("Your subscription does not support accessing POD activity.");
      return <Redirect to="/" />;
    }

    return <Podactivitytalent />;
  }}
/>

            ​
            <Route
              exact
              path="/Podactivitydashboardviewall"
              render={(props) => {
                return isUserLoggedIn && user.data[0].type == "Candidate" ? (
                  <Podactivitydashboardviewall />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
            ​
            <Route
              exact
              path="/Podactivityviewalltalent"
              render={(props) => {
                return isUserLoggedIn && user.data[0].type == "Candidate" ? (
                  <Podactivityviewalltalent />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
            ​
            <Route
              exact
              path="/PostRequirementCand"
              render={(props) => {
                return isUserLoggedIn && user.data[0].type == "Candidate" ? (
                  user.data[0].isPodLeader == "true" ? (
                    <PostRequirementCand />
                  ) : (
                    (<>
                      {window.alert(
                        "Your are not pod leader to post a requirement"
                      )}
                    </>)(<>{(window.location.href = "/Pods")}</>)
                  )
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
            <Route
              exact
              path="/create-pod"
              render={(props) => {
                return isUserLoggedIn && user.data[0].type == "Candidate" ? (
                  user.data[0].isPodLeader == "true" ? (
                    <Createpod />
                  ) : (
                    (<>
                      {window.alert("Your are not pod leader to create pod")}
                    </>)(<>{(window.location.href = "/candidate-dashboard")}</>)
                  )
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
            <Route
              exact
              path="/Updatedailystatus"
              render={(props) => {
                return isUserLoggedIn && user.data[0].type == "Candidate" ? (
                  user.data[0].isPodLeader == "true" ? (
                    <Updatedailystatus />
                  ) : (
                    <></>
                  )
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
            <Route
              exact
              path="/PodActivityLead"
              render={(props) => {
                return isUserLoggedIn && user.data[0].type == "Candidate" ? (
                  user.data[0].isPodLeader == "true" ? (
                    <PodActivityLead />
                  ) : (
                    <></>
                  )
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
            ​
            <Route
              exact
              path="/PodactivityviewalltalentEdit/:id"
              render={(props) => {
                return isUserLoggedIn && user.data[0].type == "Candidate" ? (
                  <PodactivityviewalltalentEdit />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
            <Route
              exact
              path="/Edittalentpodactivitybyid"
              render={(props) => {
                return isUserLoggedIn && user.data[0].type == "Client" ? (
                  <Edittalentpodactivitybyid />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
            <Route
              exact
              path="/AddPodactivityLead/:pod_id"
              render={(props) => {
                return isUserLoggedIn && user.data[0].type == "Candidate" ? (
                  user.data[0].isPodLeader == "true" ? (
                    <AddPodactivityLead />
                  ) : (
                    <>{(window.location.href = "/Podactivitytalent/:pod_id")}</>
                  )
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
            <Route
              exact
              path="/PostRequirementPod"
              render={(props) => {
                return isUserLoggedIn && user.data[0].type == "Client" ? (
                  <PostRequirementPod {...props} auth={authProps} />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
            <Route
              exact
              path="/PostRequirementdata"
              render={(props) => {
                return isUserLoggedIn && user.data[0].type == "Client" ? (
                  <PostRequirementdata {...props} auth={authProps} />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
            <Route
              exact
              path="/project-requirment"
              render={(props) => {
                return isUserLoggedIn && user.data[0].type == "Client" ? (
                  <PostRequirement {...props} auth={authProps} />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
            <Route
              exact
              path="/CreateGigForm"
              render={(props) => {
                return isUserLoggedIn && user.data[0].type == "Client" ? (
                  <CreateGigForm {...props} auth={authProps} />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
            <Route
              exact
              path="/Team"
              render={(props) => {
                return isUserLoggedIn && user.data[0].type == "Client" ? (
                  <Teammembers {...props} auth={authProps} />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
            <Route
              exact
              path="/login"
              render={(props) => {
                return isUserLoggedIn ? (
                  <Redirect
                    to={{
                      pathname: "/",
                    }}
                  />
                ) : (
                  <LogIn />
                );
              }}
            />
            <Route
              exact
              path="/admin-login"
              render={(props) => <Adminlogin {...props} auth={authProps} />}
            />
            <Route
              exact
              path="/browse-talent"
              render={(props) => {
                return isUserLoggedIn && user.data[0].type == "Client" ? (
                  <Browsetalents {...props} auth={authProps} />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
            <Route
              exact
              path="/browse-talent-partner"
              render={(props) => {
                return isUserLoggedIn && user.data[0].type == "Provider" ? (
                  <Browsetalentspartner {...props} auth={authProps} />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
            <Route
              exact
              path="/register"
              render={(props) => <Register {...props} auth={authProps} />}
            />
            <Route
              exact
              path="/tulsi"
              render={(props) => <Tulsi {...props} auth={authProps} />}
            />
            <Route
              exact
              path="/subscriptions"
              render={(props) => {
                return isUserLoggedIn ? (
                  <SubscriptionNew />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
            {/* <Route exact path="/subscriptions" render={(props) => {
              return  isUserLoggedIn && user.data[0].type == "ClientAdmin" ? (

                user.data[0].isApproved == "Approved" ? (  <Subscription {...props} auth={authProps} />   ) : (
                  <ProfileSuccessScreen />
                )):(

                <Redirect to={{
                  pathname: '/login',
                  }}
                  />  
              )
                
              }}  /> */}
            <Route
              exact
              path="/forgotpassword"
              render={(props) => <ForgotPassword {...props} auth={authProps} />}
            />
            <Route
              exact
              path="/forgotpasswordverification"
              render={(props) => (
                <ForgotPasswordVerification {...props} auth={authProps} />
              )}
            />
            <Route
              exact
              path="/changepassword"
              render={(props) => {
                return isUserLoggedIn ? (
                  <ChangePassword />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
            <Route
              exact
              path="/changepasswordconfirmation"
              render={(props) => {
                return isUserLoggedIn ? (
                  <ChangePasswordConfirm />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
            <Route
              exact
              path="/welcome"
              render={(props) => <Welcome {...props} auth={authProps} />}
            />
  <Route
  exact
  path="/client-profile"
  render={(props) => {
    // Check if subscriptionAccess is still null or data is loading
    if (!subscriptionAccess) {
      console.log("subscriptionAccess is still null, preventing access check.");
      return <div>Loading subscription data...</div>; // Or show a loading spinner/UI
    }

    // Check if the user has access to create profiles
    const accessGranted = hasAccess("Create_Profile_(Manager/Talent)");
    console.log("Access granted:", accessGranted);

    if (!accessGranted) {
      alert("Your subscription does not support creating profiles.");
      return <Redirect to="/" />;
    }

    // Check if the user is logged in
    return isUserLoggedIn ? (
      <Clientprofile />
    ) : (
      <Redirect
        to={{
          pathname: "/login",
        }}
      />
    );
  }}
/>


            
            <Route
              exact
              path="/partnerguest-profile"
              render={(props) => {
                return isUserLoggedIn && user.data[0].type === "PartnerGuest" ? (
                  <Partnerguest
                    changeLocale={changeLocale}
                    currentLocale={currentLocale}
                  />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
            
            <Route
              exact
              path="/stakeholder-profile"
              render={(props) => {
                return isUserLoggedIn && user.data[0].type === "stakeholder" ? (
                  <Stakeholder
                    changeLocale={changeLocale}
                    currentLocale={currentLocale}
                  />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
            <Route
              exact
              path="/costomer-profile"
              render={(props) => {
                return isUserLoggedIn && user.data[0].type == "ClientAdmin" ? (
                  <Costomerprofile />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
            <Route
              exact
              path="/Sonarprojectdetailsmetrics"
              render={(props) => {
                return isUserLoggedIn &&
                  user.data[0].type == "Client" &&
                  "stakeholder" ? (
                  <Sonarprojectdetailsmetrics />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
          <Route
  exact
  path="/PodMetricsTalentDetailview"
  render={(props) => {
    // Check if the user is logged in and is a "Candidate"
    if (!isUserLoggedIn || user.data[0].type !== "Candidate") {
      return <Redirect to={{ pathname: "/login" }} />;
    }
    if (!subscriptionAccess) {
      console.log("subscriptionAccess is still null, preventing access check.");
      return <div>Loading subscription data...</div>; // Another loading UI or redirect
    }
    // Check access rights for POD Metrics Talent Detail View
    if (!hasAccess("POD_Metrics_(Dashboard/Details)")) {
      alert("Your subscription does not support accessing POD Metrics.");
      return <Redirect to="/" />;
    }

    return <PodMetricsTalentDetailview />;
  }}
/>
            <Route
              exact
              path="/PopupDefectsdetailviewchart"
              render={(props) => {
                return isUserLoggedIn &&
                  user.data[0].type == "Client" &&
                  "stakeholder" ? (
                  <PopupDefectsdetailviewchart />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
            <Route
              exact
              path="/PopupDetailviewmetrics"
              render={(props) => {
                return isUserLoggedIn &&
                  user.data[0].type == "Client" &&
                  "stakeholder" ? (
                  <PopupDetailviewmetrics />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
            <Route
              exact
              path="/CicdPopup"
              render={(props) => {
                return isUserLoggedIn &&
                  user.data[0].type == "Client" &&
                  "stakeholder" ? (
                  <CicdPopup />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
            <Route
              exact
              path="/TimeDetailView"
              render={(props) => {
                return isUserLoggedIn && user.data[0].type == "Client" ? (
                  <TimeDetailView />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
            <Route
              exact
              path="/CloudCost"
              render={(props) => {
                return isUserLoggedIn && user.data[0].type == "Client" ? (
                  <CloudCost />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
            <Route
              exact
              path="/ProjectPieChart"
              render={(props) => {
                return isUserLoggedIn &&
                  user.data[0].type == "Client" &&
                  "stakeholder" ? (
                  <ProjectPieChart />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
            <Route
              exact
              path="/CicdPiechartmanagerdetailvivew"
              render={(props) => {
                return isUserLoggedIn &&
                  user.data[0].type == "Client" &&
                  "stakeholder" ? (
                  <CicdPiechartmanagerdetailvivew />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
           <Route
  exact
  path="/OpenAi"
  render={(props) => {
    if (!isLoggedIn) {
      return <Redirect to={{ pathname: "/login" }} />;
    }
    if (!subscriptionAccess) {
      console.log("subscriptionAccess is still null, preventing access check.");
      return <div>Loading subscription data...</div>; // Another loading UI or redirect
    }
    // Check access for both Client and Candidate
    if (
      (user.data[0].type === "Client" || user.data[0].type === "Candidate") &&
      
      hasAccess("POD_Assist")
    ) {
      return <OpenAi collapsed={collapsed} expanded={expanded} />;
    } else {
      alert("Your subscription does not support POD Assist.");
      return <Redirect to="/" />;
    }
  }}
/>



<Route
  exact
  path="/PodInsights"
  render={(props) => {
    if (!isLoggedIn) {
      return <Redirect to={{ pathname: "/login" }} />;
    }
    if (!subscriptionAccess) {
      console.log("subscriptionAccess is still null, preventing access check.");
      return <div>Loading subscription data...</div>; // Another loading UI or redirect
    }
    // Check access for Client only
    if (user.data[0].type === "Client" && hasAccess("POD_Insights")) {
      return <PodInsights />;
    } else {
      alert("Your subscription does not support POD Insights.");
      return <Redirect to="/" />;
    }
  }}
/>

<Route
              exact
              path="/PodAutomation"
              render={(props) => {
                return isLoggedIn && user.data[0].type === "Client" ? (
                  <PodAutomation />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />


<Route
  exact
  path="/PodMetricsmanager"
  render={(props) => {
    // Check if the user is logged in and is either a "Client" or "stakeholder"
    if (!isUserLoggedIn || !(user.data[0].type === "Client" || user.data[0].type === "stakeholder")) {
      return <Redirect to={{ pathname: "/login" }} />;
    }
    if (!subscriptionAccess) {
      console.log("subscriptionAccess is still null, preventing access check.");
      return <div>Loading subscription data...</div>; // Another loading UI or redirect
    }
    // Check access rights for POD Metrics (Manager/Stakeholder)
    if (!hasAccess("POD_Metrics_(Dashboard/Details)")) {
      alert("Your subscription does not support accessing POD Metrics.");
      return <Redirect to="/" />;
    }

    return <PodMetricsmanager />;
  }}
/>
          <Route
  exact
  path="/Podretromanager"
  render={(props) => {
    // Combined condition for login and user type
    if (!isUserLoggedIn || user.data[0].type !== "Client") {
      return <Redirect to={{ pathname: "/login" }} />;
    }
    if (!subscriptionAccess) {
      console.log("subscriptionAccess is still null, preventing access check.");
      return <div>Loading subscription data...</div>; // Another loading UI or redirect
    }
    // Check access rights for Client to POD Retro
    if (!hasAccess("POD_Retro")) {
      alert("Your subscription does not support accessing POD Retro.");
      return <Redirect to="/" />;
    }

    return <Podretromanager />;
  }}
/>
            <Route
              exact
              path="/DetailPRcountmanager"
              render={(props) => {
                return isUserLoggedIn &&
                  user.data[0].type == "Client" &&
                  "stakeholder" ? (
                  <DetailPRcountmanager />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
            <Route
              exact
              path="/Githublinesofcodebydaywise"
              render={(props) => {
                return isUserLoggedIn &&
                  user.data[0].type == "Client" &&
                  "stakeholder" ? (
                  <Githublinesofcodebydaywise />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
            <Route
              exact
              path="/Portfolio"
              render={(props) => {
                return isLoggedIn &&
                  (user.data[0].type === "Candidate" ||
                    user.data[0].type === "Provider") ? (
                  <Portfolio />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
         <Route
  exact
  path="/KanbanboardTalent"
  render={(props) => {
    // Combined condition for login and user type
    if (!isUserLoggedIn || user.data[0].type !== "Candidate") {
      return <Redirect to={{ pathname: "/login" }} />;
    }
    // if (!subscriptionAccess) {
    //   console.log("subscriptionAccess is still null, preventing access check.");
    //   return <div>Loading subscription data...</div>; 
    // }
 
    // if (!hasAccess("POD_Board")) {
    //   alert("Your subscription does not support accessing the Kanban Board.");
    //   return <Redirect to="/" />;
    // }

    return <KanbanboardTalent />;
  }}
/>
            <Route
              exact
              path="/PRCountTalent"
              render={(props) => {
                return isUserLoggedIn && user.data[0].type == "Candidate" ? (
                  <PRCountTalent />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
             <Route
              exact
              path="/ProfileVisibility"
              render={(props) => {
                return isUserLoggedIn ? (
                  <ProfileVisibility />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
 

            <Route
              exact
              path="/InvoicesClient"
              render={(props) => {
                return isUserLoggedIn && user.data[0].type == "ClientAdmin" ? (
                  <InvoicesClient />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
            <Route
              exact
              path="/DetailviewGithublinesofcode"
              render={(props) => {
                return isUserLoggedIn &&
                  user.data[0].type == "Client" &&
                  "stakeholder" ? (
                  <DetailviewGithublinesofcode />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
            <Route
              exact
              path="/Githublinespiecharttalent"
              render={(props) => {
                return isUserLoggedIn && user.data[0].type == "Candidate" ? (
                  <Githublinespiecharttalent />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
            <Route
              exact
              path="/Defectsdetailviewchart"
              render={(props) => {
                return isUserLoggedIn &&
                  user.data[0].type == "Client" &&
                  "Candidate" &&
                  "stakeholder" ? (
                  <Defectsdetailviewchart />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
            <Route
              exact
              path="/Detailsviewmetrics"
              render={(props) => {
                return isUserLoggedIn &&
                  user.data[0].type == "Client" &&
                  "stakeholder" ? (
                  <Detailsviewmetrics />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
         <Route
  exact
  path="/KanbanBoard"
  render={(props) => {
    // Combined condition for login and user type
    if (!isUserLoggedIn || user.data[0].type !== "Client") {
      return <Redirect to={{ pathname: "/login" }} />;
    }
    if (!subscriptionAccess) {
      console.log("subscriptionAccess is still null, preventing access check.");
      return <div>Loading subscription data...</div>; // Another loading UI or redirect
    }
    // Check access rights for Client to Kanban Board
    if (!hasAccess("POD_Board")) {
      alert("Your subscription does not support accessing the Kanban Board.");
      return <Redirect to="/" />;
    }

    return <KanbanBoard />;
  }}
/>
            <Route
              exact
              path="/Stakeholderinvite"
              render={(props) => {
                return isUserLoggedIn && user.data[0].type == "ClientAdmin" ? (
                  <Stakeholderinvite />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
            <Route
              exact
              path="/PodActivitystakeholderviewall"
              render={(props) => {
                return isUserLoggedIn && user.data[0].type === "stakeholder" ? (
                  <PodActivitystakeholderviewall />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
            <Route
              exact
              path="/PodActivityStakeholder"
              render={(props) => {
                return isUserLoggedIn && user.data[0].type === "stakeholder" ? (
                  <PodActivityStakeholder />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
            <Route
              exact
              path="/Setting"
              render={(props) => {
                return isUserLoggedIn && user.data[0].type == "Client" ? (
                  <Setting />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />

<Route
              exact
              path="/EditTalentSettingsForm"
              render={(props) => {
                return isUserLoggedIn && user.data[0].type == "Candidate" ? (
                  <EditTalentSettingsForm />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />



            <Route
              exact
              path="/Clientinvite"
              render={(props) => {
                return isUserLoggedIn && user.data[0].type == "ClientAdmin" ? (
                  <Clientinvite />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
            <Route
              exact
              path="/TaskForm"
              render={(props) => {
                return isUserLoggedIn && user.data[0].type == "Client" ? (
                  <TaskForm />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
           
           
        
           
           
            <Route
              exact
              path="/StakeholderDashboard"
              render={(props) => {
                const shouldRenderDashboard =
                  isUserLoggedIn &&
                  user.data[0].type === "stakeholder" &&
                  user.data[0].isApproved === "Approved";
                const componentToRender = shouldRenderDashboard ? (
                  <StakeholderLayout
                  collapsed={collapsed}
                  />
                ) : (
                  <ProfileSuccessScreen />
                );

                return shouldRenderDashboard ? (
                  componentToRender
                ) : (
                  <Redirect to={{ pathname: "/login" }} />
                );
              }}
            />
            <Route
              exact
              path="/mypod"
              render={(props) => {
                return isUserLoggedIn && user.data[0].type == "Client" ? (
                  <Mypod {...props} auth={authProps} />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />



<Route
  exact
  path="/PodActivity"
  render={(props) => {
    // Check if the user is logged in, is a Client, and has access to POD Activity
    if (!isUserLoggedIn) {
      return <Redirect to={{ pathname: "/login" }} />;
    }

    if (user.data[0].type !== "Client") {
      return <Redirect to={{ pathname: "/login" }} />;
    }
    if (!subscriptionAccess) {
      console.log("subscriptionAccess is still null, preventing access check.");
      return <div>Loading subscription data...</div>; // Another loading UI or redirect
    }
    if (!hasAccess("POD_Activity_(Manager/Talent)")) {
      alert("Your subscription does not support accessing POD activity.");
      return <Redirect to="/" />;
    }

    return (
      <PodActivity
        {...props}
        auth={authProps}
        collapsed={collapsed}
        expanded={expanded}
      />
    );
  }}
/>




            <Route
              exact
              path="/Podproviderpodactivity"
              render={(props) => {
                return isUserLoggedIn && user.data[0].type == "Provider" ? (
                  <Podprovidermypod {...props} auth={authProps} />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
            <Route
              exact
              path="/employerhome"
              render={(props) => <EmployerHome {...props} auth={authProps} />}
            />
            {/* <Route exact path="/candidatebyid/:id" render={(props) => <Candidatedetailsbyid {...props} auth={authProps} />} /> */}
            <Route
              exact
              path="/Candidatedetailsbyid"
              render={(props) => {
                return isUserLoggedIn && user.data[0].type == "Client" ? (
                  <Candidatedetailsbyid />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
            
            <Route
              exact
              path="/poddetail"
              render={(props) => {
                return isUserLoggedIn && user.data[0].type == "Candidate" ? (
                  <Poddetaillscandidate />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
            {/* <Route exact path="/client-createpod" render={(props) => <Clientcreatepod {...props} auth={authProps} />} />
             */}
         <Route
  exact
  path="/client-createpod"
  render={(props) => {
    if (!subscriptionAccess) {
      console.log("subscriptionAccess is still null, preventing access check.");
      return <div>Loading subscription data...</div>; // Another loading UI or redirect
    }
    // Check if the user has access to POD Management
    if (!hasAccess("POD_Mgmt_(Create/Edit/Delete)")) {
      alert("Your subscription does not support creating/editing/deleting PODs.");
      return <Redirect to="/" />;
    }

    // Check if the user is logged in and of the correct type
    return isUserLoggedIn && user.data[0].type === "Client" ? (
      <Clientcreatepod />
    ) : (
      <Redirect
        to={{
          pathname: "/login",
        }}
      />
    );
  }}
/>
            <Route
              exact
              path="/candidatesearch"
              render={(props) => {
                return isUserLoggedIn ? (
                  <CandidateSearch />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
            <Route
              exact
              path="/employeeprojectreq"
              render={(props) => {
                return isUserLoggedIn ? (
                  <EmployerProjectReq />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
            <Route
              exact
              path="/employeepf"
              render={(props) => {
                return isUserLoggedIn ? (
                  <Employeepf />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
            <Route
              exact
              path="/searchprojects"
              render={(props) => {
                return isUserLoggedIn ? (
                  <SearchProjects />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
            
 


            <Route
              exact
              path="/candidateadmin-dashboard"
              render={(props) => {
                return isUserLoggedIn &&
                  user.data[0].type == "CandidateAdmin" ? (
                  <CandidateAdminDashboard />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/admin-login",
                    }}
                  />
                );
              }}
            />
            <Route
              exact
              path="/Master"
              render={(props) => {
                return isUserLoggedIn && user.data[0].type == "Client" ? (
                  <Master />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
            <Route
              exact
              path="/Popupstakeholdermanager"
              render={(props) => {
                return isUserLoggedIn && user.data[0].type == "Client" ? (
                  <Popupstakeholdermanager />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
            <Route
              exact
              path="/TimeSubmit"
              render={(props) => {
                return isUserLoggedIn && user.data[0].type == "Candidate" ? (
                  <TimeSubmit />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
            <Route
              exact
              path="/Popup"
              render={(props) => {
                return isUserLoggedIn && user.data[0].type == "Client" ? (
                  <Popup />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
            <Route
              exact
              path="/clientadmin-dashboard"
              render={(props) => {
                return isUserLoggedIn && user.data[0].type == "ClientAdmin" ? (
                  <ClientAdminDashboard />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
            <Route
              exact
              path="/PartnerAdminDashboard"
              render={(props) => {
                return isUserLoggedIn &&
                  user.data[0].type == "ProviderAdmin" ? (
                  <PartnerAdminDashboard />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/admin-login",
                    }}
                  />
                );
              }}
            />
            <Route
              exact
              path="/candidate-profile/:id"
              render={(props) => {
                return isUserLoggedIn &&
                  user.data[0].type == "CandidateAdmin" ? (
                  <Candidateapprove />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/admin-login",
                    }}
                  />
                );
              }}
            />
            <Route
              exact
              path="/Patnerdetailsapprove/:id"
              render={(props) => {
                return isUserLoggedIn &&
                  user.data[0].type == "ProviderAdmin" ? (
                  <Patnerdetailsapprove />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/admin-login",
                    }}
                  />
                );
              }}
            />
            <Route
              exact
              path="/Leadpodrequestdetails"
              render={(props) => {
                return isUserLoggedIn && user.data[0].type == "Client" ? (
                  <Leadpodrequestdetails />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
            <Route
              exact
              path="/Clientcreatepodinvite"
              render={(props) => {
                return isUserLoggedIn && user.data[0].type == "Client" ? (
                  <Clientcreatepodinvite />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
            <Route
              exact
              path="/BarGraph"
              render={(props) => {
                return isUserLoggedIn &&
                  user.data[0].type == "Client" &&
                  "stakeholder" ? (
                  <BarGraph />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
            <Route
              exact
              path="/PRcountgraph"
              render={(props) => {
                return isUserLoggedIn &&
                  user.data[0].type == "Client" &&
                  "stakeholder" ? (
                  <PRcountgraph />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
            <Route
              exact
              path="/BarGraphstories"
              render={(props) => {
                return isUserLoggedIn &&
                  user.data[0].type == "Client" &&
                  "stakeholder" ? (
                  <BarGraphstories />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
            <Route
              exact
              path="/SprintVelocityChart"
              render={(props) => {
                return isUserLoggedIn && user.data[0].type == "Client" ? (
                  <SprintVelocityChart />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
            <Route
              exact
              path="/clientadmin-profile/:id"
              render={(props) => {
                return isUserLoggedIn && user.data[0].type == "ClientAdmin" ? (
                  <Clientadminprofile />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/admin-login",
                    }}
                  />
                );
              }}
            />
            {/* navigating to manage pods of partner,client and talents  */}
            <Route
              exact
              path="/ManagePod"
              render={(props) => {
                if (isUserLoggedIn) {
                  const userType = user.data[0].type;
                  if (userType === "Provider") {
                    return <PartnerManagePod />;
                  } else if (userType === "Candidate") {
                    return <ManagePod />;
                  }
                }
                return (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
         <Route
  exact
  path="/ClientManagePod"
  render={(props) => {
    if (!subscriptionAccess) {
      console.log("subscriptionAccess is still null, preventing access check.");
      return <div>Loading subscription data...</div>; // Another loading UI or redirect
    }
    // Check if the user has access to POD Management
    if (!hasAccess("POD_Mgmt_(Create/Edit/Delete)")) {
      alert("Your subscription does not support creating/editing/deleting PODs.");
      return <Redirect to="/" />;
    }

    // Check if the user is logged in and of the correct type
    return isUserLoggedIn && user.data[0].type === "Client" ? (
      <ClientManagePod />
    ) : (
      <Redirect
        to={{
          pathname: "/login",
        }}
      />
    );
  }}
/>
            <Route
              exact
              path="/profilesuccesss"
              render={(props) => {
                return isUserLoggedIn ? (
                  <ProfileSuccessScreen />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }}
            />
               
          </Router>
        </Elements>
        )}
      </Container>
    
  );
};

App.propTypes = {
  changeLocale: PropTypes.func.isRequired,
  currentLocale: PropTypes.string.isRequired,
};

export default App;

const Container = styled.div`
   display: flex;
   height: 100vh;
  width:100vw;
   flex-direction: row;
  //border-radius:2rem;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: inline;
    flex-direction: column;
    
  }
`;

const MenuIcon = styled.div`
  width: 2rem;
  height: 2rem;
  border-radius: 2px;
  cursor: pointer;
  background-color: #caf0f8;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  color: black;
  margin-top: 5rem;
  margin-left: 0.5rem;
  box-shadow: 0px 3px 6px #00000029;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: none;
  }
`;
