import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuthToken } from "./TokenContext";
import { useSelector } from "react-redux";

const ProfileVisibility = () => {
    const [visibility, setVisibility] = useState('private');
    const [profileLink, setProfileLink] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const token = useAuthToken();
    const { user } = useSelector(state => state.auth);

    useEffect(() => {
        fetchInitialVisibility();
    }, [token, user.data]);

    const fetchInitialVisibility = async () => {
        setLoading(true);
        setError(null);

        try {
            const response = await axios.post(
                process.env.REACT_APP_TALENT_GETUPDATEDLINKANDVISIBILITY,
                {
                    id: user.data[0].c_id
                },
                {
                    headers: {
                        Authorization: token,
                        'Content-Type': 'application/json',
                    }
                }
            );

            if (response && response.data && response.data.data && response.data.data[0]) {
                setVisibility(response.data.data[0].visibility);
                setProfileLink(response.data.data[0].profilelink);
            } else {
                throw new Error('Invalid response format');
            }
        } catch (error) {
            console.error('Error fetching profile visibility:', error);
            setError('Failed to fetch profile visibility.');
        } finally {
            setLoading(false);
        }
    };

    const handleVisibilityChange = async (event) => {
        const selectedVisibility = event.target.value;
        setVisibility(selectedVisibility);

        let profilelink = '';
        if (selectedVisibility === 'link') {
            profilelink = `${window.location.origin}/profile/${user.data[0].firstName}/${user.data[0].c_id}?token=${generateEncryptedToken(user.data[0].c_id)}`;
        } else if (selectedVisibility === 'public') {
            profilelink = `${window.location.origin}/profile/${user.data[0].firstName}/${user.data[0].c_id}`;
        }

        const headers = {
            Authorization: token,
            'Content-Type': 'application/json',
        };

        setLoading(true);
        setError(null);

        try {
            await axios.put(
                process.env.REACT_APP_TALENT_GETUPDATEVISIBILITY,
                {
                    id: user.data[0].c_id,
                    visibility: selectedVisibility,
                    profilelink,
                },
                { headers }
            );

            setProfileLink(profilelink);
            alert('Profile visibility updated successfully.');
        } catch (error) {
            console.error('Error updating profile visibility:', error);
            setError('Failed to update profile visibility.');
        } finally {
            setLoading(false);
        }
    };

    const generateEncryptedToken = (userId) => {
        // Implement your encryption logic here
        return btoa(userId); // Example: Base64 encode the userId
    };

    return (
        <div>
            <label title="Restricted to Logged in Users">
                <input
                    style={{ padding: "2px" }}
                    type="radio"
                    value="private"
                    checked={visibility === 'private'}
                    onChange={handleVisibilityChange}
                />
                Restricted
            </label>
            <label style={{ padding: "10px" }} title="Visible to those with Link">
                <input
                    type="radio"
                    value="link"
                    checked={visibility === 'link'}
                    onChange={handleVisibilityChange}
                />
                Link
            </label>
            <label>
                <input
                    style={{ padding: "2px" }}
                    type="radio"
                    value="public"
                    checked={visibility === 'public'}
                    onChange={handleVisibilityChange}
                />
                Public
            </label>
            {loading && <p>Loading...</p>}
            {error && <p style={{ color: 'red' }}>{error}</p>}
            {visibility !== 'private' && profileLink && !loading && !error && (
                <div>
                    <p>Profile Link: <a href={profileLink} target="_blank" rel="noopener noreferrer">{profileLink}</a></p>
                </div>
            )}
        </div>
    );
};

export default ProfileVisibility;
