import React, { useState, useEffect, useRef } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import axios from "axios";
import Select from "react-select";
import { useAuthToken } from "../TokenContext";
import { useIntl } from "react-intl";
import PersonIcon from "@mui/icons-material/Person";
import moment from "moment";
import { Drawer, TextField, Button, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import "./kanband.css";
import { FaBug, FaJira } from 'react-icons/fa';  // Import from react-icons/fa


const RetentionHeatmap = ({ tasks, authorizationDetails, user }) => {
  const [selectedTask, setSelectedTask] = useState(null);
  const [newComment, setNewComment] = useState("");
  const token = useAuthToken();

  const JiraAttachment = ({ mediaItem, issueKey }) => {
    const [attachment, setAttachment] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
      const fetchAttachment = async () => {
        try {
          const authHeader = `Basic ${btoa(`${authorizationDetails.Jira_admin_login_email_address}:${authorizationDetails.Jira_admin_API_Token}`)}`;
          const response = await axios.get(
            `${authorizationDetails.Jira_URL}/3/issue/${issueKey}/attachments/${mediaItem.attrs.id}`,
            {
              headers: {
                'Authorization': authHeader,
                'Content-Type': 'application/json'
              }
            }
          );
          setAttachment(response.data);
        } catch (error) {
          console.error('Error fetching attachment:', error);
          setError(true);
          setErrorMessage(error.message || 'An unknown error occurred.');
        } finally {
          setLoading(false);
        }
      };

      if (mediaItem?.attrs?.id && issueKey) {
        fetchAttachment();
      }
    }, [mediaItem?.attrs?.id, issueKey]);

    if (loading) {
      return (
        <div style={{ 
          padding: '20px',
          textAlign: 'center',
          backgroundColor: '#f8f9fa',
          borderRadius: '4px'
        }}>
          <CircularProgress size={24} />
        </div>
      );
    }

    if (error) {
      return (
        <div style={{ 
          padding: '20px',
          textAlign: 'center',
          backgroundColor: '#f8f9fa',
          borderRadius: '4px',
          color: '#666'
        }}>
          <p>An error occurred while loading the attachment:</p>
          <p>{errorMessage}</p>
          <p>Please try again later or contact the system administrator if the issue persists.</p>
        </div>
      );
    }

    if (!attachment) {
      return (
        <div style={{ 
          padding: '20px',
          textAlign: 'center',
          backgroundColor: '#f8f9fa',
          borderRadius: '4px',
          color: '#666'
        }}>
          Unable to load attachment
        </div>
      );
    }

    // Handle the case where the attachment is a file type, not a direct image
    if (attachment.mimeType === 'application/octet-stream') {
      return (
        <div style={{ 
          marginTop: '10px', 
          marginBottom: '10px',
          border: '1px solid #ddd',
          borderRadius: '4px',
          padding: '12px',
          backgroundColor: '#f8f9fa'
        }}>
          <div style={{ 
            display: 'flex',
            alignItems: 'center',
            gap: '8px'
          }}>
            <span role="img" aria-label="attachment" style={{ fontSize: '20px' }}>
              📎
            </span>
            <div>
              <div style={{ fontSize: '14px', color: '#333' }}>
                {attachment.filename}
              </div>
              <div style={{ fontSize: '12px', color: '#666' }}>
                {mediaItem.attrs.width} × {mediaItem.attrs.height}
              </div>
            </div>
          </div>

          {/* Download button */}
          <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'flex-end' }}>
            <a
              href={`data:application/octet-stream;base64,${attachment.content}`}
              download={attachment.filename}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                backgroundColor: '#0052CC',
                color: 'white',
                textDecoration: 'none',
                borderRadius: '3px',
                padding: '6px 12px',
                fontSize: '13px',
                display: 'flex',
                alignItems: 'center',
                gap: '6px'
              }}
            >
              <span role="img" aria-label="download">⬇️</span>
              Download
            </a>
          </div>
        </div>
      );
    }

    return (
      <div style={{ 
        marginTop: '10px', 
        marginBottom: '10px',
        border: '1px solid #ddd',
        borderRadius: '4px',
        padding: '12px',
        backgroundColor: '#f8f9fa'
      }}>
        {/* File info */}
        <div style={{ 
          display: 'flex',
          alignItems: 'center',
          gap: '8px'
        }}>
          <span role="img" aria-label="attachment" style={{ fontSize: '20px' }}>
            📎
          </span>
          <div>
            <div style={{ fontSize: '14px', color: '#333' }}>
              {attachment.filename}
            </div>
            <div style={{ fontSize: '12px', color: '#666' }}>
              {mediaItem.attrs.width} × {mediaItem.attrs.height}
            </div>
          </div>
        </div>

        {/* Image preview for images */}
        {attachment.mimeType?.startsWith('image/') && (
          <div style={{ marginTop: '10px' }}>
            <img
              src={`data:${attachment.mimeType};base64,${attachment.content}`}
              alt={attachment.filename}
              style={{
                maxWidth: '100%',
                height: 'auto',
                borderRadius: '4px',
                border: '1px solid #eee'
              }}
            />
          </div>
        )}

        {/* Download button */}
        <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'flex-end' }}>
          <a
            href={`data:${attachment.mimeType};base64,${attachment.content}`}
            download={attachment.filename}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              backgroundColor: '#0052CC',
              color: 'white',
              textDecoration: 'none',
              borderRadius: '3px',
              padding: '6px 12px',
              fontSize: '13px',
              display: 'flex',
              alignItems: 'center',
              gap: '6px'
            }}
          >
            <span role="img" aria-label="download">⬇️</span>
            Download
          </a>
        </div>
      </div>
    );
  };

  const renderCommentContent = (content, issueKey) => {
    if (!content) return null;

    return content.map((item, index) => {
      if (item.type === 'paragraph') {
        return (
          <div key={index} style={{ marginBottom: '8px' }}>
            {item.content && item.content.map((contentItem, cIndex) => {
              if (contentItem.type === 'text') {
                return <span key={cIndex}>{contentItem.text}</span>;
              } else if (contentItem.type === 'mention') {
                return (
                  <span 
                    key={cIndex} 
                    style={{ 
                      color: '#0052CC', 
                      fontWeight: '500',
                      backgroundColor: '#EAE6FF',
                      padding: '0 4px',
                      borderRadius: '3px',
                      margin: '0 2px'
                    }}
                  >
                    {contentItem.attrs.text}
                  </span>
                );
              } else if (contentItem.type === 'hardBreak') {
                return <br key={cIndex} />;
              }
              return null;
            })}
          </div>
        );
      } else if (item.type === 'mediaSingle' && item.content?.[0]?.type === 'media') {
        const { id, alt, width, height, type, content } = item.content[0].attrs;
        return (
          <div key={index} style={{ marginTop: '10px', marginBottom: '10px' }}>
            {type === 'image' ? (
              <img
                src={`data:${type};base64,${content}`}
                alt={alt}
                style={{
                  maxWidth: '100%',
                  height: 'auto',
                  borderRadius: '4px',
                  border: '1px solid #eee'
                }}
              />
            ) : (
              <div style={{ 
                marginTop: '10px', 
                marginBottom: '10px',
                border: '1px solid #ddd',
                borderRadius: '4px',
                padding: '12px',
                backgroundColor: '#f8f9fa'
              }}>
                <div style={{ 
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px'
                }}>
                  <span role="img" aria-label="attachment" style={{ fontSize: '20px' }}>
                    📎
                  </span>
                  <div>
                    <div style={{ fontSize: '14px', color: '#333' }}>
                      {alt}
                    </div>
                    <div style={{ fontSize: '12px', color: '#666' }}>
                      {width} × {height}
                    </div>
                  </div>
                </div>

                {/* Download button */}
                <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'flex-end' }}>
                  <a
                    href={`data:${type};base64,${content}`}
                    download={alt}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      backgroundColor: '#0052CC',
                      color: 'white',
                      textDecoration: 'none',
                      borderRadius: '3px',
                      padding: '6px 12px',
                      fontSize: '13px',
                      display: 'flex',
                      alignItems: 'center',
                      gap: '6px'
                    }}
                  >
                    <span role="img" aria-label="download">⬇️</span>
                    Download
                  </a>
                </div>
              </div>
            )}
            <div style={{ fontSize: '12px', color: '#666', marginTop: '4px' }}>
              {alt} - {width}x{height}
            </div>
          </div>
        );
      }
      return null;
    });
  };


  const handleAddComment = async () => {
    if (!newComment.trim()) return;

    const headers = {
      Authorization: token,
      "Content-Type": "application/json",
    };

    try {
      const commentPayload = {
        jiraUrl: authorizationDetails.Jira_URL,
        issueKey: selectedTask.key,
        commentText: newComment,
        username: authorizationDetails.Jira_admin_login_email_address,
        password: authorizationDetails.Jira_admin_API_Token,
      };

      const response = await axios.post(
        "https://o0quulyyma.execute-api.us-east-1.amazonaws.com/prod/addCommentjirapodboard",
        commentPayload,
        { headers }
      );

      if (response.status === 200) {
        // Add new comment to the UI
        setSelectedTask((prevTask) => ({
          ...prevTask,
          comments: [
            ...(prevTask.comments || []),
            {
              author: user.data[0].emp_name || "current_user",
              body: {
                type: "doc",
                version: 1,
                content: [
                  {
                    type: "paragraph",
                    content: [
                      {
                        type: "text",
                        text: newComment,
                      },
                    ],
                  },
                ],
              },
              created: new Date().toISOString(),
            },
          ],
        }));
        setNewComment("");
      }
    } catch (error) {
      console.error("Error adding comment:", error);
      alert("Failed to add comment. Please try again.");
    }
  };

  const handleDrawerClose = () => {
    setSelectedTask(null);
    setNewComment("");
  };

  return (
    <div>
      {tasks.map((task) => (
        <div key={task.key || task.pod_task_id}>
          <h3>{task.key} - {task.summary}</h3>
          <div>
            {renderCommentContent(task.comments, task.key || task.pod_task_id)}
          </div>
        </div>
      ))}
    </div>
  );
};

const KanbanBoard = () => {
  const [tasks, setTasks] = useState([]);
  const [columns, setColumns] = useState({
    "To Do": { title: "To Do", items: [], type: "open" },
    "In Progress": { title: "In Progress", items: [], type: "open" },
    Done: { title: "Done", items: [], type: "closed" },
  });
  const [selectedPodId, setSelectedPodId] = useState([]);
  const [authorizationDetails, setAuthorizationDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [candidates, setCandidates] = useState([]);
  const [selectedCandidate, setSelectedCandidate] = useState([]);
  const [podNames, setPodNames] = useState([]);
  const token = useAuthToken();
  const intl = useIntl();
  const { user } = useSelector((state) => state.auth);
  const scrollContainerRef = useRef(null);

  const [selectedTask, setSelectedTask] = useState(null);
  const [newComment, setNewComment] = useState("");
  const handleScroll = (direction) => {
    if (scrollContainerRef.current) {
      const scrollAmount = 300; // Adjust this value as needed
      const currentScroll = scrollContainerRef.current.scrollLeft;
      scrollContainerRef.current.scrollTo({
        left:
          currentScroll + (direction === "left" ? -scrollAmount : scrollAmount),
        behavior: "smooth",
      });
    }
  };

  // Parse status strings into arrays
  const parseStatusString = (statusString) => {
    if (!statusString) return [];
    return statusString.split(",").map((status) => status.trim());
  };

  // Initialize columns based on authorization data
  const initializeColumns = (authData) => {
    if (!authData) return columns;

    const openStatuses = parseStatusString(
      authData.jira_open_story_bug_status?.[0]
    );
    const closedStatuses = parseStatusString(
      authData.jira_closed_story_bug_status?.[0]
    );

    const newColumns = {};

    openStatuses.forEach((status) => {
      newColumns[status] = {
        title: status,
        items: [],
        type: "open",
      };
    });

    closedStatuses.forEach((status) => {
      newColumns[status] = {
        title: status,
        items: [],
        type: "closed",
      };
    });

    return newColumns;
  };

  // Group tasks into appropriate columns
  const groupTasksByStatus = (tasks, columns) => {
    const groupedColumns = { ...columns };
    Object.keys(groupedColumns).forEach((key) => {
      groupedColumns[key].items = [];
    });

    tasks.forEach((task) => {
      const status = task.status || "To Do";
      if (groupedColumns[status]) {
        groupedColumns[status].items.push(task);
      }
    });

    return groupedColumns;
  };



  const fetchPodNames = async () => {
    const headers = {
      Authorization: token,
      "Content-Type": "application/json",
    };
    try {
      const response = await axios.post(
        process.env.REACT_APP_CLIENT_FETCHPODSFORMANAGER,
        { clientID: user.data[0].emp_id },
        { headers: headers }
      );

      const data = response.data.data;
      const podNameOptions = data.map((pod) => ({
        label: pod.podName,
        value: pod.id,
      }));

      setPodNames(podNameOptions);
    } catch (error) {
      console.error("Error fetching pod names:", error);
    }
  };

  useEffect(() => {
    fetchPodNames();
  }, []);
  useEffect(() => {
    if (selectedPodId && selectedPodId.length > 0) {  // Check if array has items
      fetchAuthorizationTokensAndList();
    } else {
      // Reset relevant states when no pod is selected
      setCandidates([]);
      setTasks([]);
      setColumns({
        "To Do": { title: "To Do", items: [], type: "open" },
        "In Progress": { title: "In Progress", items: [], type: "open" },
        Done: { title: "Done", items: [], type: "closed" },
      });
    }
  }, [selectedPodId, selectedCandidate]);
  
  const fetchAuthorizationTokensAndList = async () => {
    setLoading(true);
    
    // Validate selectedPodId
    if (!selectedPodId || selectedPodId.length === 0) {
      setLoading(false);
      return;
    }
  
    const headers = {
      Authorization: token,
      "Content-Type": "application/json",
    };
  
    try {
      // Fetch authorization tokens
      const authorizationPayload = {
        Organizational_Code: user.data[0].client_code,
      };
      const authorizationResponse = await axios.post(
        process.env.REACT_APP_CUSTOMERADMIN_FETCHAUTHORIZATIONTOKENS,
        authorizationPayload,
        { headers: headers }
      );
  
      const authorizationData = authorizationResponse.data.body.data[0];
      setAuthorizationDetails(authorizationData);
  
      // Initialize columns based on authorization data
      const initialColumns = initializeColumns(authorizationData);
      setColumns(initialColumns);
  
      // Fetch candidates for all selected pods in a single API call
      const emailFetchingResponse = await axios.post(
        process.env.REACT_APP_CLIENT_FETCHPODCANDIDATES,
        { podID: selectedPodId }, // Sending the entire array of pod IDs [34,78]
        { headers: headers }
      );
  
      const allCandidates = emailFetchingResponse?.data?.data || [];
      setCandidates(allCandidates);
      const candidateNames = selectedCandidate.map(option => option.value);

      // Only proceed with task fetching if we have candidates
      if (allCandidates.length > 0) {
        // Fetch tasks and group them into columns
        const listPayload = {
          pod_Id: selectedPodId,  // Send the array of pod IDs [34,78]
          username: authorizationData.Jira_admin_login_email_address,
          password: authorizationData.Jira_admin_API_Token,
          projectKey: authorizationData.Jira_Kanben_Project_name,
          jira_url: authorizationData.Jira_URL,
          assignees: allCandidates.map(candidate => candidate.email),
          candidateName: candidateNames || [],
        };
  
        const response = await axios.post(
          "https://irl3zjh84e.execute-api.us-east-1.amazonaws.com/prod/testSingleviewtask",
          listPayload,
          { headers: headers }
        );
  
        // Set the tasks array with the combined data including comments
        const combinedTasks = [
          ...(response.data.data.podMembers || []).map((task) => ({
            ...task,
            comments: task.comments || [],
          })),
          ...(response.data.data.jiraIssues || []).map((task) => ({
            ...task,
            comments: task.comments || [],
          })),
        ];
  
        setTasks(combinedTasks);
  
        // Group tasks into columns
        const groupedColumns = groupTasksByStatus(combinedTasks, initialColumns);
        setColumns(groupedColumns);
      }
    } catch (error) {
      console.error("Error in fetchAuthorizationTokensAndList:", error);
      // Consider adding user-friendly error notification here
    } finally {
      setLoading(false);
    }
  };
  // Then update handleTaskClick to use the tasks array
  const handleTaskClick = (task) => {
    // Find the full task data from our tasks array
    const fullTask = tasks.find(
      (t) => (t.key || t.pod_task_id) === (task.key || task.pod_task_id)
    );
    if (fullTask) {
      setSelectedTask(fullTask);
    } else {
      // Fallback to the basic task data if not found
      setSelectedTask({
        ...task,
        comments: [],
      });
    }
  };

  // Add this state for tracking API calls
  const [isUpdating, setIsUpdating] = useState(false);

  const onDragEnd = async (result) => {
    if (!result.destination || isUpdating) return;

    const { source, destination, draggableId } = result;

    // If dropped in same position, do nothing
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    // Find the task being moved
    const task = Object.values(columns).reduce((found, column) => {
      if (found) return found;
      return column.items.find(
        (task) => (task.key || task.pod_task_id).toString() === draggableId
      );
    }, null);

    if (!task) return;

    // Store old columns state for reverting if needed
    const oldColumns = columns;

    // Immediately update the UI
    const newColumns = { ...columns };
    const sourceColumn = columns[source.droppableId];
    const destColumn = columns[destination.droppableId];

    const sourceItems = [...sourceColumn.items];
    const destItems = [...destColumn.items];

    const [removed] = sourceItems.splice(source.index, 1);
    const movedTask = { ...removed, status: destination.droppableId };
    destItems.splice(destination.index, 0, movedTask);

    newColumns[source.droppableId] = {
      ...sourceColumn,
      items: sourceItems,
    };
    newColumns[destination.droppableId] = {
      ...destColumn,
      items: destItems,
    };

    // Update UI immediately
    setColumns(newColumns);
    setIsUpdating(true);

    try {
      const headers = {
        Authorization: token,
        "Content-Type": "application/json",
      };

      if (
        !authorizationDetails?.Jira_admin_API_Token ||
        !authorizationDetails?.Jira_admin_login_email_address ||
        !authorizationDetails?.Jira_URL
      ) {
        throw new Error("Missing authorization details");
      }

      if (task.issue_key) {
        // Pod task update
        const updateResponse = await axios.put(
          process.env.REACT_APP_CLIENT_UPDATEKANBANBOARDPODTASK,
          {
            pod_task_id: task.pod_task_id,
            status: destination.droppableId,
          },
          { headers }
        );

        if (updateResponse.status !== 200) {
          throw new Error("Failed to update pod task status");
        }
      } else {
        // JIRA task update
        const transitionId = await fetchTransitionId(
          destination.droppableId,
          task.key
        );

        if (!transitionId) {
          throw new Error("Invalid transition for this status change");
        }

        const updateResponse = await axios.put(
          process.env.REACT_APP_CLIENT_KANBANBOARDUPDATE,
          {
            Jira_URL: authorizationDetails.Jira_URL,
            Jira_admin_login_email_address:
              authorizationDetails.Jira_admin_login_email_address,
            Jira_admin_API_Token: authorizationDetails.Jira_admin_API_Token,
            key: task.key,
            transitionId: transitionId,
            status: destination.droppableId,
          },
          {
            headers,
            timeout: 10000, // 10 second timeout
          }
        );

        if (updateResponse.status !== 200) {
          throw new Error("Failed to update JIRA task status");
        }
      }
    } catch (error) {
      console.error("Error updating task status:", error);
      // Revert UI to previous state
      setColumns(oldColumns);

      // Show error message to user (you can use your preferred notification system)
      alert("Failed to update task status. Please try again.");
    } finally {
      setIsUpdating(false);
    }
  };

  // Modified fetchTransitionId with better error handling
  const fetchTransitionId = async (status, key) => {
    if (!status || !key) return null;

    const headers = {
      Authorization: token,
      "Content-Type": "application/json",
    };

    try {
      const response = await axios.post(
        process.env.REACT_APP_CLIENT_FETCHSTATUSFORSPECIFICJIRAISSUE,
        {
          Jira_admin_login_email_address:
            authorizationDetails.Jira_admin_login_email_address,
          Jira_admin_API_Token: authorizationDetails.Jira_admin_API_Token,
          key: key,
          Jira_URL: authorizationDetails.Jira_URL,
          status: status,
        },
        {
          headers,
          timeout: 5000, // 5 second timeout
        }
      );

      if (!response.data?.body) {
        throw new Error("Invalid response format");
      }

      const statusData = response.data.body;
      const matchingStatus = statusData.find((s) => s.name === status);

      if (!matchingStatus) {
        console.warn(`No matching transition found for status: ${status}`);
        return null;
      }

      return matchingStatus.id;
    } catch (error) {
      console.error("Error fetching transitionId:", error);
      return null;
    }
  };

  // Add API retry utility function
  const retryApiCall = async (apiCall, maxRetries = 3, delay = 1000) => {
    for (let i = 0; i < maxRetries; i++) {
      try {
        return await apiCall();
      } catch (error) {
        if (i === maxRetries - 1) throw error;
        await new Promise((resolve) => setTimeout(resolve, delay));
      }
    }
  };

  const handleAddComment = async () => {
    if (!newComment.trim()) return;

    const headers = {
      Authorization: token,
      "Content-Type": "application/json",
    };

    try {
      const commentPayload = {
        jiraUrl: authorizationDetails.Jira_URL,
        issueKey: selectedTask.key,
        commentText: newComment,
        username: authorizationDetails.Jira_admin_login_email_address,
        password: authorizationDetails.Jira_admin_API_Token,
      };

      const response = await axios.post(
        "https://o0quulyyma.execute-api.us-east-1.amazonaws.com/prod/addCommentjirapodboard",
        commentPayload,
        { headers }
      );

      if (response.status === 200) {
        // Add new comment to the UI
        setSelectedTask((prevTask) => ({
          ...prevTask,
          comments: [
            ...(prevTask.comments || []),
            {
              author: user.data[0].emp_name || "current_user",
              body: {
                type: "doc",
                version: 1,
                content: [
                  {
                    type: "paragraph",
                    content: [
                      {
                        type: "text",
                        text: newComment,
                      },
                    ],
                  },
                ],
              },
              created: new Date().toISOString(),
            },
          ],
        }));
        setNewComment("");
      }
    } catch (error) {
      console.error("Error adding comment:", error);
      alert("Failed to add comment. Please try again.");
    }
  };

  const handleDrawerClose = () => {
    setSelectedTask(null);
    setNewComment("");
  };
  {
    /* Comment Drawer */
  }



  
  const JiraAttachment = ({ mediaItem }) => {
    // If the media item already has the content, we can use it directly
    const { attrs } = mediaItem;
    
    if (!attrs || !attrs.type) {
      return (
        <div className="p-5 text-center bg-gray-50 rounded text-gray-600">
          Unable to load attachment
        </div>
      );
    }
  
    // For images, display them using the content from API
    if (attrs.type === 'image') {
      return (
        <div className="mt-2 mb-2">
          <img
            src={`data:${attrs.type};base64,${attrs.content}`}
            alt={attrs.alt || 'Attachment'}
            className="max-w-full h-auto rounded border border-gray-200"
          />
          <div className="text-sm text-gray-600 mt-1">
            {attrs.alt || 'Image attachment'}
          </div>
        </div>
      );
    }
  
    // For other file types, show download option
    return (
      <div className="mt-2 mb-2 p-3 bg-gray-50 rounded border border-gray-200">
        <div className="flex items-center gap-2">
          <span role="img" aria-label="attachment" className="text-xl">📎</span>
          <div>
            <div className="text-sm text-gray-800">{attrs.alt || 'File attachment'}</div>
            <div className="text-xs text-gray-600">
              {attrs.width && attrs.height ? `${attrs.width} × ${attrs.height}` : ''}
            </div>
          </div>
        </div>
        
        <div className="mt-2 flex justify-end">
          <a
            href={`data:${attrs.type};base64,${attrs.content}`}
            download={attrs.alt || 'download'}
            className="bg-blue-600 text-white px-3 py-2 rounded text-sm flex items-center gap-2 hover:bg-blue-700 transition-colors"
          >
            <span role="img" aria-label="download">⬇️</span>
            Download
          </a>
        </div>
      </div>
    );
  };
  
  const renderCommentContent = (content) => {
    if (!content) return null;
  
    return content.map((item, index) => {
      if (item.type === 'paragraph') {
        return (
          <div key={index} style={{ marginBottom: '8px' }}>
            {item.content && item.content.map((contentItem, cIndex) => {
              if (contentItem.type === 'text') {
                return <span key={cIndex}>{contentItem.text}</span>;
              } else if (contentItem.type === 'mention') {
                return (
                  <span 
                    key={cIndex} 
                    style={{ 
                      color: '#0052CC', 
                      fontWeight: '500',
                      backgroundColor: '#EAE6FF',
                      padding: '0 4px',
                      borderRadius: '3px',
                      margin: '0 2px'
                    }}
                  >
                    {contentItem.attrs.text}
                  </span>
                );
              } else if (contentItem.type === 'hardBreak') {
                return <br key={cIndex} />;
              }
              return null;
            })}
          </div>
        );
      } else if (item.type === 'mediaSingle' && item.content?.[0]?.type === 'media') {
        const mediaItem = item.content[0];
        console.log('Media Item:', mediaItem); // Add this to see the data structure
        console.log('Full media item with content:', mediaItem);
  // Check if there's any content/base64/url property
  console.log('Full item:', item);
        return (
          <div key={index} className="mt-2 mb-2 p-3 bg-gray-50 rounded border border-gray-200">
            <div className="flex items-center gap-2">
              <span role="img" aria-label="attachment" className="text-xl">📎</span>
              <div>
                {mediaItem.attrs?.alt && (
                  <div className="text-sm text-gray-800">{mediaItem.attrs.alt}</div>
                )}
                {mediaItem.attrs?.width && mediaItem.attrs?.height && (
                  <div className="text-xs text-gray-600">
                    {mediaItem.attrs.width} × {mediaItem.attrs.height}
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      }
      return null;
    });
  };
 

 



  

  const styles = `
  .download-button:hover {
    background-color: #0747A6 !important;
  }
`;







const [attachments, setAttachments] = useState([]);

useEffect(() => {
    const fetchAttachments = async () => {
        try {
            const authHeader = `Basic ${Buffer.from(`${`contact@brilliglobal.com`}:${`ATATT3xFfGF0QSBxqoJeBiLMGz6CCzxnE46J6IIkEJthHNJKPPgJH3iE7Nev6hXyEsok1ba76a0-p_b8jpXrPbFjD7MkSkEKGwnv6cLv2c-Z52DCl_dFMb8L-gXyf62BxoZm81XKZf9QaJU9Zgix2TtLj8ryQAiHcT0EyKp90ln2yopDzNGKf1k=F2538198
`}`).toString('base64')}`;
            const issueUrl = `${`https://globl.atlassian.net`}/rest/api/3/issue/${`G10-195`}?fields=attachment`;

            const response = await axios.get(issueUrl, {
                headers: {
                    Authorization: authHeader,
                    'Content-Type': 'application/json'
                }
            });

            const attachments = response.data.fields.attachment.map(att => ({
                id: att.id,
                filename: att.filename,
                url: att.content // Direct download URL for the attachment
            }));
            setAttachments(attachments);
        } catch (error) {
            console.error('Error fetching attachments:', error.message);
        }
    };

    fetchAttachments();
}, []);


















  return (
    <div className="kanban-board">
      <h1>{intl.formatMessage({ id: "podBoard" })}</h1>
      
      {/* Pod and Candidate Selection Controls */}
      <div className="board-controls-wrapper">
      <Select
        isMulti // Enable multi-select
        options={podNames}
        value={podNames.filter((option) => selectedPodId.includes(option.value))}
        onChange={(selectedOptions) => setSelectedPodId(selectedOptions.map((opt) => opt.value))}
        placeholder={intl.formatMessage({ id: 'selectPod' })}
        className="select-field"
      />
      
      <Select
  isMulti
  options={candidates.map(candidate => ({
    value: candidate.Candidate_Name,
    label: candidate.Candidate_Name
  }))}
  value={selectedCandidate}
  onChange={(selectedOptions) => setSelectedCandidate(selectedOptions || [])}
  placeholder="Select Candidates"
  className="select-field"
  styles={{
    control: (base) => ({
      ...base,
      marginTop: '10px'
    })
  }}
/>
      </div>
      <div style={{ position: "relative", width: "100%" }}>
        <button
          className="scroll-button scroll-left"
          onClick={() => handleScroll("left")}
        >
          ←
        </button>
        <DragDropContext onDragEnd={onDragEnd}>
          <div className="scrollable-board">
            {Object.entries(columns).map(([columnId, column]) => (
              <Droppable key={columnId} droppableId={columnId}>
                {(provided, snapshot) => (
                  <div
                    className="column"
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    <div className="task-list">
                      <h6
                        style={{
                          color: "grey",
                          fontWeight: "600",
                          borderBottomColor:
                            column.type === "closed" ? "green" : "yellow",
                        }}
                      >
                        {column.title} ({column.items.length})
                      </h6>

                      <div className="tasks-container">
                        {loading ? (
                          <div className="loading-container">
                            <CircularProgress size={24} />
                          </div>
                        ) : (
                          column.items.map((task, index) => (
                            <Draggable
                            key={task.key || task.pod_task_id}
                            draggableId={(task.key || task.pod_task_id).toString()}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className="task"
                                onClick={() => handleTaskClick(task)}
                                style={{
                                  ...provided.draggableProps.style,
                                  cursor: "pointer",
                                }}
                              >
                                <div className="task-content">
                                  <div className="task-header">
                                    <div className="task-type-icon">
                                      {task.key ? (
                                        <FaJira 
                                          size={16} 
                                          className="jira-icon" 
                                          title="JIRA Issue"
                                        />
                                      ) : (
                                        <FaBug 
                                          size={16} 
                                          className="pod-icon" 
                                          title="POD Task"
                                        />
                                      )}
                                    </div>
                                    <div className="task-title">
                                      {task.summary || task.pod_task_name}
                                    </div>
                                  </div>
                                  <div className="task-sprint">
                                    {task.key}
                                  </div>
                                  <div className="task-assignee">
                                    <PersonIcon style={{ color: "green" }} />
                                    {task.Candidate_Name}
                                  </div>
                                  <div className="task-due-date">
                                    Due Date:{" "}
                                    {moment(task.due_date).format("MM-DD-YYYY") || "Not Set"}
                                  </div>
                                </div>
                              </div>
                            )}
                          </Draggable>
                          ))
                        )}
                        {provided.placeholder}
                      </div>
                    </div>
                  </div>
                )}
              </Droppable>
            ))}
          </div>
        </DragDropContext>

        <button
          className="scroll-button scroll-right"
          onClick={() => handleScroll("right")}
        >
          →
        </button>
      </div>

      <Drawer anchor="right" open={!!selectedTask} onClose={handleDrawerClose}>
        <div style={{ width: "400px", padding: "20px" }}>
          {/* Task Header */}
          <div
            className="drawer-header"
            style={{
              borderBottom: "1px solid #eee",
              paddingBottom: "10px",
              marginBottom: "20px",
            }}
          >
            <h5 style={{ margin: 0 }}>
              {selectedTask?.key} - {selectedTask?.summary}
            </h5>
            <div style={{ fontSize: "0.9em", color: "#666", marginTop: "4px" }}>
              Status: {selectedTask?.status}
            </div>
          </div>

          {/* Comments Section */}
          <div
            className="comments-section"
            style={{
              maxHeight: "calc(100vh - 300px)",
              overflowY: "auto",
              padding: "0 4px",
            }}
          >
            {selectedTask?.comments?.map((comment, index) => (
              <div
                key={index}
                className="comment"
                style={{
                  marginBottom: "20px",
                  padding: "12px",
                  backgroundColor: "#f8f9fa",
                  borderRadius: "8px",
                  boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                }}
              >
                {/* Comment Header */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "12px",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      backgroundColor: "#0073e6",
                      color: "white",
                      padding: "4px 8px",
                      borderRadius: "4px",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                  >
                    {comment.author}
                  </span>
                  <small style={{ color: "#666" }}>
                    {moment(comment.created).format("MMM DD, YYYY HH:mm")}
                  </small>
                </div>

                {/* Comment Content */}
                <div
                  style={{
                    whiteSpace: "pre-wrap",
                    wordBreak: "break-word",
                    lineHeight: "1.5",
                  }}
                >
                  {renderCommentContent(comment.body.content)}
                </div>
              </div>
            ))}
          </div>

          {/* Add Comment Section */}
          <div
            style={{
              borderTop: "1px solid #eee",
              paddingTop: "20px",
              marginTop: "20px",
              background: "white",
              position: "sticky",
              bottom: 0,
            }}
          >
            <TextField
              label="Add a comment"
              multiline
              rows={3}
              fullWidth
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
              variant="outlined"
              size="small"
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddComment}
              disabled={!newComment.trim()}
              style={{ marginTop: "10px", float: "right" }}
            >
              Add Comment
            </Button>
          </div>
        </div>

        <style jsx>{`
          .comments-section::-webkit-scrollbar {
            width: 6px;
          }

          .comments-section::-webkit-scrollbar-track {
            background: #f1f1f1;
            border-radius: 3px;
          }

          .comments-section::-webkit-scrollbar-thumb {
            background: #888;
            border-radius: 3px;
          }

          .comments-section::-webkit-scrollbar-thumb:hover {
            background: #555;
          }

          .comment:hover {
            background-color: #f1f5f9 !important;
          }
        `}</style>
      </Drawer>
      {/* <RetentionHeatmap tasks={tasks} authorizationDetails={authorizationDetails} user={user} /> */}
    </div>
  );
};

export default KanbanBoard;
