import FormErrors from "../FormErrors";
import Validate from "../utility/FormValidation";
import { Auth } from "aws-amplify";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory, Link } from "react-router-dom";
import { login, logout } from "../action/auth";
import axios from "axios";
import styled from 'styled-components';
import talentlabel from '../assets/talentlabel.png';
import clientlabel from "../assets/clientlabel.png";
import candidatelabel from "../assets/candidatelabel.png";
import pod from "../assets/customer.png";
import partner from '../assets/partner.png'
import customer from '../assets/customer.png'

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [User, setUser] = useState({
    username: "",
    password: "",
    table: "",
    errors: {
      cognito: null,
      blankfield: false,
    },
  });

  const { isLoggedIn, user } = useSelector((state) => state.auth);

  const [loading, setLoading] = useState(false);
  const { message } = useSelector((state) => state.message);

  const clearErrorState = () => {
    setUser((prevState) => ({
      ...prevState,
      errors: {
        cognito: null,
        blankfield: false,
      },
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);

    if (User.table === "") {
      alert("Please select a user type!");
      setLoading(false);
      return;
    } else if (User.username === "") {
      alert("Please enter an email address!");
      setLoading(false);
      return;
    } else if (!User.username.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
      alert("Please enter a valid email address!");
      setLoading(false);
      return;
    } else if (User.password === "") {
      alert("Please enter a password!");
      setLoading(false);
      return;
    }

    try {
      const userResponse = await Auth.signIn(User.username, User.password);
//console.log('table',User.table === "candidate")
      if (User.table === "candidate") {
        dispatch(login(User.username, User.table)).then((data) => {
          if (data[0].profile === 0) {
            window.location.href = "/CreateProfile";
          } else {
            window.location.href = "/candidate-dashboard";
          }
        
      
          setLoading(false);
        }).catch((err) => {
          setLoading(false);
          let error = { message: "User not found as talent." };
          setUser((prevState) => ({
            ...prevState,
            errors: {
              cognito: error,
            },
          }));
        });
      } else if (User.table === "podprovider") {
        dispatch(login(User.username, User.table)).then((data) => {
          if (data[0].profile === "0") {
            window.location.href = "/provider-profile";
          } else {
            window.location.href = "/provider-profile";
          }
          setLoading(false);
        }).catch((err) => {
          setLoading(false);
          let error = { message: "User not found as Partner." };
          setUser((prevState) => ({
            ...prevState,
            errors: {
              cognito: error,
            },
          }));
        });
      } else if (User.table === "customer") {
        dispatch(login(User.username, User.table)).then((data) => {
          if (data[0].profile === "0") {
            window.location.href = "/costomer-profile";
          } else {
            window.location.href = "/subscriptions";
          }
          setLoading(false);
        }).catch((err) => {
          setLoading(false);
          let error = { message: "User not found as Client-Admin." };
          setUser((prevState) => ({
            ...prevState,
            errors: {
              cognito: error,
            },
          }));
        });
      } else if (User.table === "stakeholder") {
        dispatch(login(User.username, User.table)).then((data) => {
          if (data[0].profile === "0") {
            window.location.href = "/stakeholder-profile";
          } else {
           window.location.href = "/stakeholder-profile";
          }
          setLoading(false);
        }).catch((err) => {
          setLoading(false);
          let error = { message: "User not found as Stake Holder." };
          setUser((prevState) => ({
            ...prevState,
            errors: {
              cognito: error,
            },
          }));
        });
      
      } else if (User.table === "partnerguest") {
        dispatch(login(User.username, User.table)).then((data) => {
          if (data[0].profile === "0") {
            window.location.href = "/partnerguest-profile";
          } else {
           window.location.href = "/partnerguest-profile";
          }
          setLoading(false);
        }).catch((err) => {
          setLoading(false);
          let error = { message: "User not found as partnerguest." };
          setUser((prevState) => ({
            ...prevState,
            errors: {
              cognito: error,
            },
          }));
        });
      
      }else {
        dispatch(login(User.username, User.table)).then((data) => {
          if (data[0].profile === "0") {
            window.location.href = "/client-profile";
          } else {
            window.location.href = "/client-profile";
          }
          setLoading(false);
        }).catch((err) => {
          setLoading(false);
          let error = { message: "User not found as manager." };
          setUser((prevState) => ({
            ...prevState,
            errors: {
              cognito: error,
            },
          }));
        });
      }
    } catch (error) {
      setLoading(false);
      let err = null;
      !error.message ? (err = { message: error }) : (err = error);
      setUser((prevState) => ({
        ...prevState,
        errors: {
          cognito: err,
        },
      }));
    }
  };

  const onInputChange = (e) => {
    clearErrorState();
    setUser((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  function showPassword() {
    var x = document.getElementById("password");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }

  return (
    <div className="container">
      <MAN2>
        <br></br>
        <FormErrors formerrors={User.errors} />
        {loading ? <p>Login... Please wait</p> : <p></p>}
        <form onSubmit={handleSubmit}>
          <h1 className="register-header">Login</h1>
          <br></br>
          <Field1>
            <SelectL
            
              id="table"
              value={User.table}
              onChange={onInputChange}
            >
              <option value="">Select User Type</option>
              <option value="candidate">Talent</option>
              <option value="podprovider">Partner</option>
              <option value="manager">Manager</option>
              <option value="stakeholder">Stake Holder</option>
              <option value="partnerguest">Partner Guest</option>
              
              
              <option value="customer"> Admin</option>
            </SelectL>
          </Field1>
          <div className="field">
            <p className="control">
              <InputL
                className="input2"
                type="text"
                id="username"
                aria-describedby="usernameHelp"
                placeholder="Enter E-mail"
                value={User.username}
                onChange={onInputChange}
              />
            </p>
          </div>
          <div className="field">
            <p className="control">
              <InputL
                className="input2"
                type="password"
                id="password"
                placeholder="Enter Password"
                value={User.password}
                onChange={onInputChange}
              />
            </p>
          </div>
          <br></br>
          <br></br>
          <div className="row">
            <div className="col-sm-2"></div>
            <div className="col-sm-4">
              <div className="field" style={{ marginTop: '-2rem' }}>
                <p className="control" id="showPass">
                  <input
                    type="checkbox"
                    onClick={showPassword}
                    style={{ marginLeft: "-25px" }}
                  />
                  <span
                    style={{
                      fontFamily: "Poppins, Regular",
                      paddingLeft: "5px",
                      fontWeight: "600",
                    }}
                  >
                    Show Password
                  </span>
                </p>
              </div>
            </div>
            <div>
              <div className="field" style={{ marginTop: '-2rem' }}>
                <p className="control" id="showPass">
                  <Link
                    to="/forgotpassword"
                    style={{
                      color: "black",
                      fontFamily: "Poppins, Regular",
                      fontWeight: "600",
                      marginLeft: '2rem'
                    }}
                  >
                    Forgot password?
                  </Link>
                </p>
              </div>
            </div>
            <div className="col-sm-2"></div>
          </div>
          <div className="field">
            <p className="control">
              <Button type="submit">
                Login
              </Button>
            </p>
          </div>
          <div className="field">
            <p
              className="control"
              style={{
                fontFamily: "Poppins, Regular",
                fontWeight: "600",
              }}
            >
              Don't have an account?{" "}
              <Link to="/register">
                <b
                  className="login-label"
                  style={{
                    fontFamily: "Poppins, Regular",
                    fontWeight: "600",
                  }}
                >
                  Register
                </b>
              </Link>
            </p>
          </div>
        </form>
      </MAN2>
      <br></br>
      <br></br>
    </div>
  );
};

export default Login;

const MAN2 = styled.div`
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: 1px 3px 6px #00000029;
  border: 1px solid #D8D8D8;
  border-radius: 16px;
  opacity: 1;
  padding: 30px;
  width: 650px;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    margin: auto;
    width: 20rem;
    display: flex;
    align-items: center;
    justify-content: center;
    over-flow: hidden;
  }
`;

const Field1 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    flex-direction: column;
    margin margin: auto;
    justify-content: center;
    align-items: center;
  }
`;

const InputL = styled.input`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 520px;
  height: 54px;
  font-family: Poppins, Regular;
  font-weight: 600;
  border: 1px solid #B7B7B7;
  border-radius: 10px;
  opacity: 1;
  margin: auto;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    font-size: 12px;
    width: 215px;
    height: 54px;
  }
`;

const SelectL = styled.select`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 520px;
  height: 54px;
  font-family: Poppins, Regular;
  font-weight: 600;
  border: 1px solid #B7B7B7;
  border-radius: 10px;
  opacity: 1;
  margin: auto;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    font-size: 12px;
    width: 215px;
    height: 54px;
  }
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 420px;
  height: 50px;
  margin-Top: 40px;
  font-family: Poppins, Medium;
  font-weight: 600;
  top: 869px;
  left: 757px;
  width: 407px;
  height: 54px;
  border-radius: 10px;
  opacity: 1;
  background-color: #1DBF73;
  color: white;
  border: 0px;
  box-shadow: -15px -15px 25px #FFFFFF;
  border-radius: 10px;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 208px;
    height: 54px;
  }
`;
