import styled from 'styled-components';
import React, { useEffect } from 'react'

import { cardShadow, hoverEffect,  } from '../utils';
import { useState } from 'react';

import { useParams,  Link } from 'react-router-dom';


import {  useSelector } from "react-redux";

import { useLocation } from 'react-router-dom';
import Viewpodretrotalent from './Viewpodretrotalent';
import { useAuthToken } from "../TokenContext";
import { Breadcrumbs } from '@mui/material';
import { useIntl } from 'react-intl';

const PodactivitytalentRetro = () => {

  const [activeTab, setActiveTab] = useState('post'); // Default to 'post' tab
  const intl = useIntl();

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const token = useAuthToken();



const location=useLocation();
const{podid}=location.state
  const { isLoggedIn, user } = useSelector(state => state.auth);
  

  const [poddata, setinputList] = useState(
    {retro: '', sprint: '', pods_id: podid,submitted_by:user.data[0].firstName, submitted_by_id:user.data[0].c_id });
   

  const params = useParams();
  const [sprintData, setSprintData] = useState([]);
  const [selectedSprint, setSelectedSprint] = useState(''); // Store sprint name directly

  const onInputChange = e => {
    // setinputList((prevState) => {
    //   return { ...prevState, [e.target.id]: e.target.value };
    // });
  }
  useEffect(() => { fetchSprintfilter()},[token]);
  const fetchSprintfilter = () => {
    if (!token) {
      return; // Exit early if there is no token
    }
    
    fetch(process.env.REACT_APP_TALENT_SPRINTFILTERTAENT, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({ talent_id: user.data[0].c_id }),
    })
    .then((response) => response.json())
    .then((data) => {
      if (data.statusCode === 200 && data.body && data.body.sprint_filter) {
        setSprintData(data.body.sprint_filter);
      }
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
    });
  };
  
  
  const onInputChangesprint = (e) => {
    const selectedSprintName = e.target.value;
    setSelectedSprint(selectedSprintName); // Store sprint name as string
  };
  

  const handleSubmit = (e) => {
    e.preventDefault();
    const retroFeedback = {
      start_doing: e.target.start_doing.value,
      stop_doing: e.target.stop_doing.value,
      keep_doing: e.target.keep_doing.value,
    };
  
    const formData = {
      pod_feedback:e.target.podRetro.value,
      retro: retroFeedback,
      sprint: selectedSprint,
    status:"Completed",
    pods_id: podid,
    submitted_by:user.data[0].firstName, 
    submitted_by_id:user.data[0].c_id}
  

    const requestOptions = {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization' : token,
      },
      body: JSON.stringify(formData)

    }
    console.log("data99",formData)
    // https://mtfarorwtg.execute-api.us-east-1.amazonaws.com/dev/pod_retros
    fetch(process.env.REACT_APP_TALENT_PODRETROS, requestOptions)
      .then(response => response.json())
      .then((data => {

        if (data.status == 400) {
          alert(data.data)
       
        }
        if (data.status == 200) {

          alert("Posted Successfully.")
         window.location.href = '/Pods'
          setTimeout(() => {
            window.location.reload();
          }, 2000);

        }
        else {
          console.log('error')
        }

      }))

  };




  const Candname = user.data[0].firstName
  const profilePic = user.data[0].profilePicture






  const intervals = [
    { label: 'year', seconds: 31536000 },
    { label: 'month', seconds: 2592000 },
    { label: 'day', seconds: 86400 },
    { label: 'hour', seconds: 3600 },
    { label: 'minute', seconds: 60 },
    { label: 'second', seconds: 1 }
  ];
  function timeSince(date) {
    const seconds = Math.floor((Date.now() - date.getTime()) / 1000);
    const interval = intervals.find(i => i.seconds < seconds);
    const count = Math.floor(seconds / interval.seconds);
    return `${count} ${interval.label}${count !== 1 ? 's' : ''} ago`;


  }




  return (


    <Div >

<Breadcrumbs aria-label="breadcrumb" style={{float:"left"}} >
  <Link to="/Pods" style={{ color: '#03045e', fontSize: "18px", fontFamily: 'Poppins,Medium', fontWeight: "600" }}>My Pods</Link>
  <span style={{ color: 'grey', fontSize: "18px", fontFamily: 'Poppins,Medium', fontWeight: "600" }}>&nbsp;&gt;&nbsp;</span>
  <span style={{ color: 'grey', fontSize: "18px", fontFamily: 'Poppins,Medium', fontWeight: "600" }}>Post Retro</span>
</Breadcrumbs>
<br/>
<br/>

<br/>
<TabsContainer>
        <TabButton
          onClick={() => handleTabClick('post')}
          active={activeTab === 'post'}
        >
          Post Retro 
        </TabButton>
        <TabButton
          onClick={() => handleTabClick('view')}
          active={activeTab === 'view'}
        >
          View Retro
        </TabButton>
      </TabsContainer>

      <TitleText>Post Pod Retro</TitleText>
      
      {activeTab === 'post' ? (<>
      <RecommendProject>



        <CardContent>

          <form  onSubmit={(e) =>handleSubmit(e)}>








<Row1>
  <Label>Pod Retro :</Label>
            <Textarea
              id="podRetro"
              required
maxLength='100'
              onChange={onInputChange}
              placeholder="Pod Retro Feedback" />


</Row1>

<Row1>
           <Label>Sprint :</Label>
           <SelectField
  id="sprint"
  onChange={onInputChangesprint}
  value={selectedSprint.name}

>
  <option value="">Select a Sprint</option>
  {sprintData.map((sprint, index) => (
    <option key={index} value={sprint.sprint_name}>
      {sprint.sprint_name}
    </option>
  ))}
  </SelectField>

</Row1>


<Row1>
  <Label>{intl.formatMessage({ id: 'startDoing' })}  :</Label>
            <Textarea
              id="start_doing"
              required

              onChange={onInputChange}
              placeholder="Start Doing" />


</Row1>
<Row1>
  <Label>{intl.formatMessage({ id: 'stopDoing' })}  :</Label>
            <Textarea
              id="stop_doing"
              required

              onChange={onInputChange}
              placeholder="Stop Doing" />


</Row1>
<Row1>
  <Label>{intl.formatMessage({ id: 'keepDoing' })}  :</Label>
            <Textarea
              id="keep_doing"
              required

              onChange={onInputChange}
              placeholder="Keep Doing" />


</Row1>





            <div style={{ marginLeft: 'auto*' }}>
              {/* <NavLink  to ='/VIewDailyPostedUpdates' > */}
              <Button type='submit'>
                Post
              </Button>

              {/* </NavLink> */}
            </div>


          </form>
        </CardContent>



      </RecommendProject>
      </>):(<Viewpodretrotalent Podid={podid} />
)}
    </Div>
  )
}


const Div = styled.div`

// display:flex;
display:inline;
jutify-content:center;
flex-direction:column;
margin:auto;
margin-top:6rem;
align-items:center;

// margin-left:6.5rem;
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;

  overflow:hidden;
justify-content:center;
align-items:center;
margin:auto;
width:25rem;
  }
`;

const RecommendProject = styled.div`

 display:inline;

jutify-content:center;
flex-direction:row;
@media screen and (min-width:320px) and (max-width:1080px){
  flex-direction:column;
    width:80%;
    height:max-content;
    margin:0.4rem;
    
   }
`;


const TitleText = styled.h4`
font-weight:600;

@media screen and (min-width:320px) and (max-width:1080px){

  margin-top:2rem;
 
}
`;
const CardContent = styled.div`
margin:0.4rem;
margin-top:2rem;
display:flex;
flex-direction:column;
// justify-content:center;
// align-items:center;


border-radius:20px;
font-family:Poppins,Medium;
width:40rem;
height:max-height;
padding: 1rem;
background-color: #F2F4F5;

box-shadow:${cardShadow};
transition:0.45 ease-in-out;
&:hover {
box-shadow:${hoverEffect};
}

@media screen and (min-width:320px) and (max-width:1080px){
  width:20rem;
  margin-top:2rem;
 
  
 }
`;





const InputField = styled.input`
margin-top:1rem;
border: 1px solid #cccccc;
border-radius: 5px;
width:20rem;
height:3.1rem;
padding-left:1.5rem;
font-weight:600;
font-family:Poppins,Medium;
@media screen and (min-width:320px) and (max-width:1080px){
  width:15rem;

  
 }
`;


const SelectField = styled.select`
margin-top:1rem;
border: 1px solid #cccccc;
border-radius: 5px;
width:20rem;
height:3.1rem;
font-weight:600;

color:grey;
font-family:Poppins,Medium;
@media screen and (min-width:320px) and (max-width:1080px){
  width:15rem;

  
 }
`;
const InputFieldCandidates = styled.input`
margin-top:1rem;
border: 1px solid #cccccc;
border-radius: 5px;
width:20rem;
height:3.1rem;
font-weight:600;
color:grey;
margin-left:10px;
padding-left:1.5rem;
font-family:Poppins,Medium;
@media screen and (min-width:320px) and (max-width:1080px){
  width:15rem;

  margin-left:-2px;
 }
`;


const SelectField1 = styled.div`
margin-top:1rem;
border: 1px solid #cccccc;
border-radius: 5px;
width:20rem;
height:3.1rem;
font-weight:600;
color:grey;
font-family:Poppins,Medium;
@media screen and (min-width:320px) and (max-width:1080px){
  width:15rem;

  
 }
`;


const Button = styled.button`
margin-left:auto;
border:none;
border-radius: 10px;
background-color:#1DBF73;
box-shadow: 1px 3px 6px #00000029;
width:8rem;
height:2rem;
font-size:18px;
font-weight:600;
color:white;
margin-top:4rem;
@media screen and (min-width:320px) and (max-width:1080px){

  width:7rem;
}
`;


const Button1 = styled.button`
margin-right:auto;
border:none;
border-radius: 10px;
background-color:#00B4D8
;
box-shadow: 1px 3px 6px #00000029;
width:8rem;
height:max-height;
font-size:16px;
padding:8px;
font-weight:600;
color:white;
margin-top:1rem;
@media screen and (min-width:320px) and (max-width:1080px){

  width:7rem;
}
`;




const SideCard = styled.div`
margin-left:5rem;
display:flex;
justify-content:center;
align-items:center;
flex-direction:column;
border-radius:20px;
font-family:Poppins,Medium;
width:20rem;
height:32rem;

padding: 1rem;
background-color: #F2F4F5;

box-shadow:${cardShadow};
transition:0.45 ease-in-out;
&:hover {
box-shadow:${hoverEffect};
}

@media screen and (min-width:320px) and (max-width:1080px){

margin:auto;
margin-top:3rem;
  width:20rem;
}
`;


const SideCardContent = styled.div`
background-color:#90E0EF;
width:17rem;
display:flex;
flex-direction:column;
padding:3px;


border-radius:20px;
`;


const SideCardContent1 = styled.div`
background-color:#90E0EF;
width:17rem;
display:flex;
margin-top:1rem;
flex-direction:column;
height:10rem;
justify-content:center;
align-items:center;
border-radius:20px;

`;





const Detail = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;

  @media screen and (min-width: 320px) and (max-width: 1080px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.2rem;
    margin-bottom: 1rem;
  }
`;
const InfoContainer = styled.div`
  display: flex;
margin:auto;
`;

const Info = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: 15px;
`;
const Row = styled.div`
  display: flex;

  align-item: center;
  margin-bottom: 0.8rem;
`;
const Row1 = styled.div`
  display: flex;
margin-top:2rem;
  align-item: center;
  margin-bottom: 0.8rem;
  @media screen and (min-width:320px) and (max-width:1080px){
    display: flex;
    flex-direction:column;
  }
`;
const InfoName = styled.h6`
  font-weight: 600;
`;
const InfoUpdate = styled.span`
  font-size: 13px;
  font-weight: 600;
  margin-right: auto;
`;

const Title = styled.h6`
  font-weight: 600;
`;
const ProjectInfo = styled.p`
padding:5px;
margin:3px;
background-color:white;
border-radius:5px  ;
font-size:13px;
color:black;
font-weight:600;
margin-bottom:1rem
`;

const ViewAll = styled.button`
width:5rem;
font-weight:600;
height:2rem;
margin-left:auto;
margin-top:1rem;
border: 2px solid #1DBF73;
border-radius:5px  ;
box-shadow: 1px 3px 6px #00000029;
`;

const View = styled.button`
width:8rem;
font-weight:600;
height:2rem;
color:black;
background-color:white;
border-radius:5px  ;
margin-bottom:1rem;
margin-top:1rem;
border: none;
border-radius:5px  ;
box-shadow: 1px 3px 6px #00000029;
`;

const H1 = styled.h1`
font-size:22px;
font-weight:600;
margin-bottom:1.2rem;
`;

const InputContainer = styled.div`
display : flex;
border-radius:10px;
justify-content: center;
margin-left:auto;
width:25rem;
margin-bottom:1rem;
border:1px solid #4E8FAE;
box-shadow: 3px 6px 9px #00000005;
@media screen and (min-width:320px) and (max-width:1080px){
  margin:auto;
  margin-top:2rem;

    width: 20rem;
  
 
`;

const Icon = styled.div`
height: 3rem;
width: 2rem;
background-color: #F2F3F4;
display:flex;
flex-direction:row
justify-content: center;
align-items: center;
border-top-left-radius:10px;
border-bottom-left-radius:10px;
svg{
    color:#555555;
}
`;

const Input = styled.input`
width:25rem;


tex-decoration:none;
border:1px solid #cccccc;
border-radius:5px;

padding:1rem;
font-size:14px;
font-family:Poppins,Medium;
font-Weight:600;
  outline: none;
  @media screen and (min-width:320px) and (max-width:1080px){
    width: 15rem;
  }


`;

const InfoPod = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-left: 15px;
`;


const Textarea = styled.textarea`
width:25rem;


tex-decoration:none;
border:1px solid #cccccc;
border-radius:5px;

padding:1rem;
font-size:14px;
font-family:Poppins,Medium;
font-Weight:600;
  outline: none;
  @media screen and (min-width:320px) and (max-width:1080px){
    width: 15rem;
  }


`;

const TextDiv = styled.div`
display:flex;
flex-direction:row;




`;

const Buttonadd = styled.button`
tex-decoration:none;
border:none;
display:flex;
// margin-right:auto;
border-radius:5px;
font-size:14px;
font-family:Poppins,Medium;
font-Weight:600;
  outline: none;
width:4rem;
justify-content:center;
align-items:center;
height:2.5rem;
margin-top:0.5rem;
color:white;
background-color:#00B4D8;

`;

const ButtonRemove = styled.button`
text-decoration:none;
border:none;
border-radius:5px;
font-size:14px;
font-family:Poppins,Medium;
font-Weight:600;
  outline: none;
width:4.5rem;
height:2.5rem;
margin-top:0.5rem;
margin-left:10px;
color:white;
background-color:#00B4D8;

`;

const Label =styled.label`
margin-top:1rem;

font-family:Poppins,Medium;
font-weight: bold;
font-size:16px;
color :black;
width:10rem;
`;

const TabsContainer = styled.div`
  display: flex;
  margin-bottom: 1rem;
`;

const TabButton = styled.button`
  background: ${(props) => (props.active ? 'lightblue' : 'white')};
  border: 1px solid #ccc;
  padding: 10px;
  cursor: pointer;
  outline: none;
`;
export default PodactivitytalentRetro



