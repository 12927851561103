import React, { useEffect, useState, useRef } from "react";
import { Chart } from "chart.js";
import "chartjs-plugin-annotation";
import "./BarGraph.css";
import { useSelector } from "react-redux";
import { useAuthToken } from "../TokenContext";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

const TicketsDetailviewmetricsTalent = ({ initialPodIds }) => {
  const [talentData, setTalentData] = useState([]);
  const [totalTickets, setTotalTickets] = useState({ open_tickets: 0, closed_tickets: 0 });
  const [openTickets, setOpenTickets] = useState([]);
  const [closedTickets, setClosedTickets] = useState([]);
  const chartRef = useRef(null);
  const [selectedTalent, setSelectedTalent] = useState(""); // State for selected talent
  const [selectedTicket, setSelectedTicket] = useState(null); // State for selected ticket
  const [isPopupVisible, setIsPopupVisible] = useState(false); // State for popup visibility
  const { user } = useSelector((state) => state.auth);
  const token = useAuthToken();
  const intl = useIntl();
  const [isDataAvailable, setIsDataAvailable] = useState(false);

  useEffect(() => {
    if (!token) {
      return; // Exit early if there is no token
    }


    const fetchData = async () => {
      try {
        const payload = {
          talent_id: user.data[0].c_id,
          pod_id: initialPodIds || [],
        };
    
        const apiUrl = process.env.REACT_APP_TALENT_JIRATICKETSMETRICSTALENT;
    
        const response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(payload),
        });
    
        if (response.ok) {
          const data = await response.json();
          if (Object.keys(data).length === 0 && data.constructor === Object) {
            console.log("No data available");
            // Handle no data available, e.g., display a message to the user
            return;
          }
          const { talent_data, total_tickets, open_tickets, closed_tickets } = data;
          setTalentData(talent_data);
          setTotalTickets(total_tickets);
          setOpenTickets(open_tickets);
          setClosedTickets(closed_tickets);
        } else {
          console.error("Error fetching talent metrics:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching talent metrics:", error);
      }
    };
    

    fetchData();
  }, [initialPodIds, token, user.data]);

  const generatePieData = () => {
    if (!totalTickets || Object.keys(totalTickets).length === 0) {
      return null; // Return null to indicate no data available
    } else if (!selectedTalent) {
      return [totalTickets.open_tickets || 0, totalTickets.closed_tickets || 0]; // Show total counts when "All Reporters" is selected
    } else {
      const talent = talentData.find((item) => item.talent_name === selectedTalent);
      if (talent) {
        return [talent.open_tickets, talent.closed_tickets];
      } else {
        return null; // Return null if no data is available for the selected talent
      }
    }
  };



  const updateChart = () => {
    const chartData = generatePieData();

    if (chartData === null) {
      displayNoDataMessage();
      setIsDataAvailable(false);
    } else {
      setIsDataAvailable(true);
      if (chartRef.current) {
        chartRef.current.destroy();
      }
      displayChart("talentChart", "Tickets", chartData);
    }
  };

  useEffect(updateChart, [selectedTalent, talentData, totalTickets, openTickets, closedTickets]);

  const displayChart = (chartId, chartLabel, chartData) => {
    const ctx = document.getElementById(chartId);

    if (!ctx) {
      return;
    }

    chartRef.current = new Chart(ctx, {
      type: "doughnut",
      data: {
        labels: ["Open Tickets", "Closed Tickets"],
        datasets: [
          {
            label: chartLabel,
            data: chartData,
            backgroundColor: ["lightgreen", "red"],
            borderWidth: 1,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          annotation: {},
          legend: {
            labels: {
              generateLabels: () => [],
            },
          },
        },
        layout: {
          padding: {
            top: 10,
            bottom: 10,
            left: 10,
            right: 10,
          },
        },
        tooltips: {
          enabled: true,
          mode: "single",
          callbacks: {
            label: function (tooltipItem, data) {
              const dataIndex = tooltipItem.dataIndex;
              const datasetIndex = tooltipItem.datasetIndex;
              const value = data.datasets[datasetIndex].data[dataIndex];
              return value;
            },
          },
        },
        onClick: (event, elements) => {
          if (elements.length > 0) {
            const segmentIndex = elements[0].index;
            const ticketData = segmentIndex === 0 ? openTickets : closedTickets;
            setSelectedTicket(ticketData);
            setIsPopupVisible(true);
          }
        },
      },
    });
  };

  const displayNoDataMessage = () => {
    const canvas = document.getElementById("talentChart");
    if (canvas) {
      const ctx = canvas.getContext("2d");
      const width = canvas.width;
      const height = canvas.height;
      ctx.clearRect(0, 0, width, height);
      ctx.font = "16px Arial";
      ctx.textAlign = "center";
      ctx.fillText("No data available", width / 2, height / 2 + 8);
    }
  };

  const closePopup = () => {
    setIsPopupVisible(false);
    setSelectedTicket(null);
  };

  return (
    <div className="chart-container">
     
        <div style={{ display: "flex", flexDirection: "column" }}>
          <h2 className="chart-heading">Tickets</h2>
          <br />
          <select
            value={selectedTalent}
            onChange={(e) => setSelectedTalent(e.target.value)}
            style={{ width: "12rem" }}
          >
            <option value="">{intl.formatMessage({ id: "allReporters" })}</option>
            <option value="">{intl.formatMessage({ id: "allReporters" })}</option>
            {talentData &&
    talentData.length > 0 &&
    talentData.map((item) => (
              <option key={item.talent_name} value={item.talent_name}>
                {item.talent_name}
              </option>
            ))}
          </select>
         
          <div className="chart-wrapper"style={{width:"170px",height:"170px",margin:"auto",textAlign:"center",marginTop:"5px"}}>

            { talentData && talentData.length > 0 && isDataAvailable ? (
              <canvas key="talentChart" id="talentChart"></canvas>
            ) : (
              <p style={{ fontSize: "16px", textAlign: "center" }}>No data available</p>
            )}
          </div>
          
        </div>
     
      {isPopupVisible && (
        <div className="popup">
          <div className="popup-content">
            <h2>Ticket Details</h2>
            <button onClick={closePopup}>Close</button>
            <ul>
              {selectedTicket?.map(ticket => (
                <li key={ticket.issue_id}>
                  <p>Issue ID: {ticket.issue_id}</p>
                  <p>Issue Key: {ticket.issue_key}</p>
                  <p>Created Date: {ticket.created_date}</p>
                  <p>Talent Name: {ticket.talent_name}</p>
                  <p>Ticket Summary: {ticket.ticket_summary}</p>
                  <p>Ticket Description: {ticket.ticket_description}</p>
                  <p>Ticket Status: {ticket.ticket_status}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
  


};

TicketsDetailviewmetricsTalent.propTypes = {
  initialPodIds: PropTypes.array.isRequired,
};

export default TicketsDetailviewmetricsTalent;
