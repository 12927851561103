import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Paper,
  MenuItem,
  Select,
  Pagination,
  IconButton,
  Checkbox,
  ListItemText,
  OutlinedInput,
} from "@mui/material";
import axios from "axios";

import 'jspdf-autotable';
import { FiDownload } from "react-icons/fi";
import ViewTimeSubmit from "./ViewTimeSubmit";
import moment from "moment";
import { NavHashLink } from "react-router-hash-link";
import styled from "styled-components";
import { DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";
import { useSelector } from "react-redux";

import { useAuthToken } from "../TokenContext";
import { useIntl } from "react-intl";
import { GrFormEdit, GrFilter } from "react-icons/gr";
import { jsPDF } from "jspdf";
import { FiArrowUp, FiArrowDown } from "react-icons/fi"; 
function PodActivity({collapsed}) {
  const [selectedPodId, setSelectedPodId] = useState([]);
  const [editableData, setEditableData] = useState([]);
  const [podDetails, setPodDetails] = useState([]);
  const [podActivities, setPodActivities] = useState([]);
  const [selectedPodDetails, setSelectedPodDetails] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const token = useAuthToken();
  const [filteredActivities, setFilteredActivities] = useState([]);
  const [selectedTeamMember, setSelectedTeamMember] = useState("");
  const [selectedIds, setSelectedIds] = useState([]);
  const { isLoggedIn, user } = useSelector((state) => state.auth);
  const [showDateRangePicker, setShowDateRangePicker] = useState(false);
  const [activeTab, setActiveTab] = useState("post"); // Default to 'post' tab
  const [isOpen, setIsOpen] = useState(false);
  const [selectedPodIds, setSelectedPodIds] = useState([]);
  const [dateRange, setDateRange] = useState([
    {
      startDate: moment().month(2).date(10).toDate(), // March is represented by index 2
      endDate: moment().month(2).date(11).toDate(), // March is represented by index 2
      key: "selection",
    },
  ]);


  const [sortedBy, setSortedBy] = useState("createdAt");
  const [sortOrder, setSortOrder] = useState("desc");
  const [statusFilter, setStatusFilter] = useState("all");

  const intl = useIntl();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_CLIENT_GETCLIENTPOD,
          {
            method: "POST",
            headers: {
              Authorization: token,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              clientID: user.data[0].emp_id,
            }),
          }
        );

        const data = await response.json();
        setPodDetails(data.data.podDetails);
        const activities = data.data.activities;
        // Sort activities by createdAt date in descending order (most recent first)
        const sortedActivities = activities.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setPodActivities(sortedActivities);
        setFilteredActivities(sortedActivities); // Initialize filtered activities with sorted activities
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [token]);
  const handlePodSelect = (event) => {
    const selectedValues = event.target.value;
    setSelectedPodId(selectedValues);
  
    // Filter activities based on selected pods
    let filteredResult;
    if (selectedValues.length === 0) {
      // If no pods selected, show all activities
      filteredResult = podActivities;
    } else {
      // Filter activities for selected pods
      filteredResult = podActivities.filter((activity) => 
        selectedValues.some(podId => 
          podId.toString() === activity.pod_id.toString()
        )
      );
    }
    const lastSelectedPod = podDetails.find(
      (pod) => pod.id === selectedValues[selectedValues.length - 1]
    );
    setSelectedPodDetails(lastSelectedPod || null);
  
    setFilteredActivities(filteredResult);
    setCurrentPage(1); // Reset to first page
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const handleDateRangeChange = (ranges) => {
    setDateRange([ranges.selection]);
    // Filter activities based on selected date range and team member
    const activities = podActivities.filter((activity) => {
      const isInDateRange = isActivityInRange(activity, ranges.selection);
      const matchesMember =
        !selectedTeamMember || activity.firstName === selectedTeamMember;
      return isInDateRange && matchesMember;
    });
    setFilteredActivities(activities);
  };

  const handleTeamMemberSelect = (event) => {
    const selectedMember = event.target.value;
    setSelectedTeamMember(selectedMember);
  
    // If the selected member is an empty string, reset the filtered activities to all activities
    if (selectedMember === "") {
      setFilteredActivities(podActivities);
    } else {
      // Otherwise, filter the activities based on the selected team member
      const activities = podActivities.filter((activity) => {
        return activity.firstName === selectedMember;
      });
      setFilteredActivities(activities);
    }
  
    setCurrentPage(1); // Reset current page when selecting a new team member
  
  };
  

  const isActivityInRange = (activity, range) => {
    const activityDate = new Date(activity.createdAt);
    const activityYear = activityDate.getFullYear();
    const rangeStartDateYear = range.startDate.getFullYear();
    const rangeEndDateYear = range.endDate.getFullYear();

    return (
      activityDate >= range.startDate &&
      activityDate <= range.endDate &&
      activityYear >= rangeStartDateYear &&
      activityYear <= rangeEndDateYear
    );
  };

  // Pagination
  const rowsPerPage = 10;
  const totalPages = Math.ceil(filteredActivities.length / rowsPerPage);
  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const paginatedActivities = filteredActivities.slice(startIndex, endIndex);

  const handleCheckboxChange = (event, id) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedIds([...selectedIds, id]);
    } else {
      setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id));
    }
  };
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleSubmit = () => {
    if (selectedIds.length === 0) {
      alert("Please select at least one data item.");
      return; // Prevent further execution of the function
    }

    const ids = selectedIds.map((id) => id); // Copy selectedIds array

    const payload = {
      poddata: [{ id: ids, manager_id: user.data[0].emp_id }],
      manager_id: user.data[0].emp_id,
    };

    fetch(process.env.REACT_APP_CLIENT_PODACTIVITYPOSTLEAD, {
      method: "POST",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the response from the server
        alert("Data Approved successfully.");
        // Optionally, you can perform further actions after successful submission.
        window.location.reload();
      })
      .catch((error) => {
        // Handle any errors that occur during the API call
        console.error("Error:", error);
      });
  };

  const handleDownload = async (format) => {
    if (format === "csv") {
      // Convert filteredActivities to downloadable format, for example, CSV
      let csvContent =
        "data:text/csv;charset=utf-8," +
        "First Name, Categories, Jira Issue, Today's Focus, Accomplishments, Blocker, Posted Date, Video URL, Status\n";

      filteredActivities.forEach((activity) => {
        // Format the "Today's Focus" field
        const todayFocus = activity.today_focus
          ? activity.today_focus.replace(/,/g, ";")
          : ""; // Check for null value before replacing commas

        // Construct each row of the CSV
        const row = `${activity.firstName},"${activity.categories}","${
          activity.jira_issue
        }","${todayFocus}","${activity.accomplishments}","${
          activity.blockers
        }","${moment(activity.createdAt).format("MM-DD-YYYY") || ""}","${
          activity.loom_url || ""
        }","${activity.posted_to_client == 1 ? "Approved" : "Pending"}"\n`;

        csvContent += row;
      });

      // Create a temporary link element and trigger the download
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "pod_activities.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }else if (format === "pdf") {
      if (filteredActivities.length > 0) {
        const doc = new jsPDF('landscape'); // Set page layout to landscape
  
        // Define table headers
        const headers = [
          "First Name",
          "Categories",
          "Jira Issue",
          "Today's Focus",
          "Accomplishments",
          "Blocker",
          "Posted Date",
          "Video URL",
          "Status",
        ];
  
        // Define table rows
        const rows = filteredActivities.map((activity) => [
          activity.firstName,
          activity.categories,
          activity.jira_issue,
          activity.today_focus || "",
          activity.accomplishments || "", // Ensure there are no unexpected characters
          activity.blockers || "",
          moment(activity.createdAt).format("MM-DD-YYYY") || "",
          activity.loom_url || "",
          activity.posted_to_client == 1 ? "Approved" : "Pending",
        ]);
  
        // Use autoTable plugin to generate the table in the PDF
        doc.autoTable({
          head: [headers],
          body: rows,
          styles: {
            fontSize: 8, // Reduce font size for better fit
            cellPadding: 3,
            overflow: 'linebreak', // Ensures text wraps within the cell
          },
          headStyles: {
            fillColor: [0, 180, 216], // Change header background color if needed
            textColor: [255, 255, 255],
          },
          columnStyles: {
            0: { cellWidth: 30 }, // First Name
            1: { cellWidth: 30 }, // Categories
            2: { cellWidth: 35 }, // Jira Issue
            3: { cellWidth: 'auto' }, // Today's Focus (auto width)
            4: { cellWidth: 'auto' }, // Accomplishments (auto width)
            5: { cellWidth: 'auto' }, // Blocker (auto width)
            6: { cellWidth: 30 }, // Posted Date
            7: { cellWidth: 50 }, // Video URL
            8: { cellWidth: 20 }, // Status
          },
          margin: { top: 10 }, // Adjust top margin as needed
        });
  
        // Save the PDF
        doc.save("pod_activities.pdf");
      } else {
        console.error("No activities to generate PDF.");
      }
    } else if (format === "docx") {
      if (filteredActivities.length > 0) {
        let docContent = "<html><body><table border='1'>";
        docContent +=
          "<tr><th>First Name</th><th>Categories</th><th>Jira Issue</th><th>Today's Focus</th><th>Accomplishments</th><th>Blocker</th><th>Posted Date</th><th>Video URL</th><th>Status</th></tr>";

        filteredActivities.forEach((activity) => {
          const todayFocus = activity.today_focus ? activity.today_focus : "";
          const postedStatus =
            activity.posted_to_client == 1 ? "Approved" : "Pending";
          docContent += `<tr><td>${activity.firstName}</td><td>${
            activity.categories
          }</td><td>${activity.jira_issue}</td><td>${todayFocus}</td><td>${
            activity.accomplishments
          }</td><td>${activity.blockers}</td><td>${
            moment(activity.createdAt).format("MM-DD-YYYY") || ""
          }</td><td>${
            activity.loom_url || ""
          }</td><td>${postedStatus}</td></tr>`;
        });

        docContent += "</table></body></html>";

        const blob = new Blob([docContent], { type: "application/msword" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "pod_activities.doc");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.error("No activities to generate Word document.");
      }
    }
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelection = (format) => {
    handleDownload(format);
    setIsOpen(false); // Close the dropdown after selection
  };


  const handleViewAndTranscribe = async (loomUrl, id) => {
    try {
      // Split the URL at '?' to extract the filename
      const [filename] = loomUrl.split('?')[0].split('/').slice(-1);
  
      // Construct the CloudFront URL
      const cloudFrontUrl = process.env.REACT_APP_CLOUDFRONTs3;
  
      // Combine the CloudFront URL with the filename
      const fullUrl = cloudFrontUrl + filename;
  
      // Make a request to get the signed URL for transcription
      const response = await axios.post(
       process.env.REACT_APP_TALENT_CLOUDFRONTAPI,
        { "url": fullUrl }
      );
  
      // Parse the response body as JSON
      const responseBody = JSON.parse(response.data.body);
  
      // Extract the signed URL from the parsed response body
      const signedUrl = responseBody.signedUrl;
  
      // Ensure that the signed URL is found in the transcription response
      if (!signedUrl) {
        console.error('Signed URL not found in transcription response:', responseBody);
        alert('Error transcribing video. Signed URL not found.');
        return;
      }
  
      // Update the corresponding item in editableData with the signed URL
      const updatedData = editableData.map((item) =>
        item.id === id ? { ...item, loom_url: signedUrl } : item
      );
      setEditableData(updatedData);
  
      // Open the video for viewing in a new tab using the full signed URL
      window.open(signedUrl, '_blank');
  
      // console.log('Transcription Data:', signedUrl);
      // alert('Video transcription completed successfully.');
    } catch (error) {
      console.error('Error transcribing video:', error);
      alert('Error transcribing video. Please try again later.');
    }
  };
  
  const handleSort = (sortBy, order) => {

    if (filteredActivities?.length === 0) {
      console.warn("No activities to sort.");
      return;
    }
    setSortedBy(sortBy);
    setSortOrder(order);

    const sortedActivities = filteredActivities.slice().sort((a, b) => {
      if (order === "asc") {
        return new Date(a[sortBy]) - new Date(b[sortBy]);
      } else {
        return new Date(b[sortBy]) - new Date(a[sortBy]);
      }
    });

    setFilteredActivities(sortedActivities);
  };

  const handleStatusFilterChange = (event) => {
    if (podActivities?.length === 0) {
      console.warn("No activities to sort.");
      return;
    }
    const status = event.target.value;
    setStatusFilter(status);

    let filteredActivities = [...podActivities];

    if (status === "pending") {
      filteredActivities = podActivities.filter((activity) => activity.posted_to_client === 0);
    } else if (status === "approved") {
      filteredActivities = podActivities.filter((activity) => activity.posted_to_client === 1);
    }

    setFilteredActivities(filteredActivities);
  };
  return (
    <Div   collapsed={collapsed}>
      <TitleText>{intl.formatMessage({ id: "podActivity" })}</TitleText>

      <Select
        multiple
        value={selectedPodId}
        onChange={handlePodSelect}
        input={<OutlinedInput />}
        renderValue={(selected) => {
          if (selected.length === 0) {
            return "All Pods";
          }
          return podDetails
            .filter((pod) => selected.includes(pod.id))
            .map((pod) => pod.podName)
            .join(", ");
        }}
        displayEmpty
        style={{ minWidth: 200, marginBottom: "1rem" }}
      >
        {podDetails.map((pod) => (
          <MenuItem key={pod.id} value={pod.id}>
            <Checkbox checked={selectedPodId.indexOf(pod.id) > -1} />
            <ListItemText primary={pod.podName} />
          </MenuItem>
        ))}
      </Select>
      {selectedPodDetails && (
        <TabsContainer>
          <TabButton
            onClick={() => handleTabClick("post")}
            active={activeTab === "post"}
          >
            {intl.formatMessage({ id: "viewPodActivities" })}
          </TabButton>
          <TabButton
            onClick={() => handleTabClick("view")}
            active={activeTab === "view"}
          >
            {intl.formatMessage({ id: "viewTimeEntries" })}
          </TabButton>
        </TabsContainer>
      )}
      {activeTab === "post" ? (
        <>
          {/* {selectedPodDetails && ( */}
          
          {/* )} */}

          <div style={{ display: "flex", marginLeft: "auto", width: "15rem" }}>
            <div style={{ position: "relative" }}>
              <FiDownload
                cursor="pointer"
                style={{ width: "25px", height: "25px" }}
                title="Download Activities"
                onClick={toggleDropdown}
              />
              {isOpen && (
                <div
                  style={{
                    position: "absolute",
                    top: "100%",
                    left: "50%",
                    transform: "translateX(-50%)",
                    zIndex: "1",
                    backgroundColor: "#fff",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    marginTop: "5px",
                  }}
                >
                  <select
                    style={{
                      width: "15rem",
                      border: "none",
                      textDecoration: "none",
                    }}
                    onChange={(e) => handleSelection(e.target.value)}
                  >
                    <option value="">Download as...</option>
                    <option value="csv">CSV</option>
                    <option value="pdf">PDF</option>
                    <option value="docx">DOCX</option>
                  </select>
                </div>
              )}
            </div>
          </div>

          <div
            style={{ display: "flex", marginRight: "auto" }}
            onClick={() => setShowDateRangePicker(!showDateRangePicker)}
          >
            <GrFilter
              title="Date Range Filter"
              cursor="pointer"
              style={{ width: "25px", height: "25px" }}
            />
          </div>

          {showDateRangePicker && (
            <DateRangePicker
              onChange={handleDateRangeChange}
              ranges={dateRange}
              moveRangeOnFirstSelection={false}
              months={2}
              direction="horizontal"
            />
          )}
          <TableContainer component={Paper}>
            <Table
              component={Paper}
              sx={{
                width: "100%",
                fontFamily: "Poppins,Medium",
                borderRadius: "10px",
              }}
              aria-label="simple table"
            >
              <TableHead
                sx={{
                  backgroundColor: "#00B4D8",
                  "& td, & th": {
                    fontFamily: "Poppins,Medium",
                    fontSize: "15px",
                    color: "white",
                    fontWeight: "600",
                  },
                }}
              >
                <TableRow>
                  <TableCell align="right">
                    {intl.formatMessage({ id: "select" })}
                  </TableCell>
                  <TableCell>
                    
                    <Select
           value={selectedTeamMember}
           onChange={handleTeamMemberSelect}
           displayEmpty
           inputProps={{ "aria-label": "Select Team Member" }}
           sx={{ marginTop: "1rem" ,color:'white'}}
         >
           <MenuItem value=""  >
          {intl.formatMessage({ id: "PodTeamMember" })}
           </MenuItem>
           {[...new Set(podActivities.map((activity) => activity.firstName))].map(
             (member, index) => (
               <MenuItem key={index} value={member}>
                 {member}
               </MenuItem>
             )
           )}
         </Select>
                  </TableCell>
                  {/* <TableCell>Categories</TableCell> */}
                  <TableCell align="right" sx={{ fontWeight: "bold" }}>
                    {intl.formatMessage({ id: "jiraIssue" })}
                  </TableCell>
                  <TableCell align="right" sx={{ fontWeight: "bold" }}>
                    {intl.formatMessage({ id: "todaysFocus" })}
                  </TableCell>
                  <TableCell align="right" sx={{ fontWeight: "bold" }}>
                    {intl.formatMessage({ id: "accomplishments" })}
                  </TableCell>
                  <TableCell align="right" sx={{ fontWeight: "bold" }}>
                    {intl.formatMessage({ id: "blocker" })}
                  </TableCell>
                  <TableCell align="right" sx={{ fontWeight: "bold" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              {intl.formatMessage({ id: "postedDate" })}
              {/* Sorting arrow icons */}
              {sortedBy === "createdAt" && sortOrder === "desc" && (
                <FiArrowDown onClick={() => handleSort("createdAt", "asc")} />
              )}
              {sortedBy === "createdAt" && sortOrder === "asc" && (
                <FiArrowUp onClick={() => handleSort("createdAt", "desc")} />
              )}
            </div>
          </TableCell>
                  <TableCell align="right" sx={{ fontWeight: "bold" }}>
                    {intl.formatMessage({ id: "videoUrl" })}
                  </TableCell>
                 
          <TableCell align="right" sx={{ fontWeight: "bold" }}>
          
            {/* Dropdown filter for status */}
            <Select
              value={statusFilter}
              onChange={handleStatusFilterChange}
              style={{ marginLeft: "10px", color: "white" }}
              IconComponent={GrFilter}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value="all">  {intl.formatMessage({ id: "status" })}</MenuItem>
              <MenuItem value="pending">Pending</MenuItem>
              <MenuItem value="approved">Approved</MenuItem>
            </Select>
          </TableCell>
                  <TableCell align="right" sx={{ fontWeight: "bold" }}>
                    {intl.formatMessage({ id: "edit" })}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedActivities.map((activity) => (
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                        fontFamily: "Poppins,Medium",
                        fontSize: "15px",
                      },
                      "&:nth-child(even)": { backgroundColor: "#90E0EF" },
                      "&:nth-child(odd)": {
                        backgroundColor: "#caf0f8",
                      },
                      "& td, & th": {
                        fontFamily: "Poppins,Medium",
                        fontSize: "15px",
                        color: "black",
                        fontWeight: "600",
                      },
                    }}
                    key={activity.id}
                  >
                    {activity.posted_to_client == 1 ? (
                      <TableCell>
                        {" "}
                        <input
                          type="checkbox"
                          disabled
                          checked={selectedIds.includes(activity.id)}
                          onChange={(event) =>
                            handleCheckboxChange(event, activity.id)
                          }
                        />
                      </TableCell>
                    ) : (
                      <TableCell>
                        {" "}
                        <input
                          type="checkbox"
                          checked={selectedIds.includes(activity.id)}
                          onChange={(event) =>
                            handleCheckboxChange(event, activity.id)
                          }
                        />
                      </TableCell>
                    )}
                    <TableCell>{activity.firstName}</TableCell>
                    {/* <TableCell>{activity.categories}</TableCell> */}
                    <TableCell>{activity.jira_issue}</TableCell>
                    <TableCell>{activity.today_focus}</TableCell>
                    <TableCell>{activity.accomplishments}</TableCell>
                    <TableCell>{activity.blockers}</TableCell>
                    <TableCell>
                      {moment(activity.createdAt).format("MM-DD-YYYY")}
                    </TableCell>
                    <TableCell>
                      {activity.loom_url ? (
                       <Button onClick={() => handleViewAndTranscribe(activity.loom_url, activity.id)}>
                       {intl.formatMessage({ id: "viewVideo" })}
                       </Button>
                      ) : (
                        <p>{intl.formatMessage({ id: "noVideoAvailable" })}</p>
                      )}
                    </TableCell>
                    {activity.posted_to_client == 1 ? (
                      <TableCell align="right">
                        {intl.formatMessage({ id: "approved" })}
                      </TableCell>
                    ) : (
                      <TableCell align="right">
                        {intl.formatMessage({ id: "pending" })}
                      </TableCell>
                    )}
                    {activity.posted_to_client == 1 ? (
                      <TableCell align="right">
                        <ButtonEdit disabled>
                          <GrFormEdit style={{ color: "#00B4D8" }} />{" "}
                        </ButtonEdit>{" "}
                      </TableCell>
                    ) : (
                      <TableCell align="right">
                        {" "}
                        <NavHashLink
                          to={{
                            pathname: "/Edittalentpodactivitybyid",
                            state: {
                              Id: activity.id,
                            },
                          }}
                          smooth
                        >
                          {" "}
                          <ButtonEdit>
                            <GrFormEdit style={{ color: "#00B4D8" }} />{" "}
                          </ButtonEdit>{" "}
                        </NavHashLink>{" "}
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Button1 onClick={handleSubmit}>
              {intl.formatMessage({ id: "approve" })}
            </Button1>
          </TableContainer>

          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            variant="outlined"
            shape="rounded"
            color="primary"
          />
        </>
      ) : (
        <ViewTimeSubmit Podid={selectedPodDetails.id} />
      )}
    </Div>
  );
}

export default PodActivity;

const Div = styled.div`
  display: flex;
  jutify-content: center;
  align-items: center;
 
  width: 80%;
  flex-direction: column;
  margin: auto;
  margin-top: 6rem;
    margin-left: ${(props) => (props['data-collapsed'] === 'true' ? '60px' : '240px')};
height:max-height;
padding-bottom:2rem;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 80vw;
    margin-left:4rem;
  }
`;
const TitleText = styled.h4`
  font-weight: 600;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    margin-top: 2rem;
  }
`;
const ButtonEdit = styled.button`
  text-decoration: none;
  border: none;
  margin-left: auto;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-top: 2rem;
  }
`;
const Button1 = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: 1rem;
  border: none;
  border-radius: 10px;
  background-color: #1dbf73;
  box-shadow: 1px 3px 6px #00000029;
  width: 10rem;
  height: 2rem;
  font-size: 15px;
  font-weight: 600;
  color: white;
`;
const TabsContainer = styled.div`
  display: flex;
  margin-bottom: 1rem;
`;

const TabButton = styled.button`
  background: ${(props) => (props.active ? "lightblue" : "white")};
  border: 1px solid #ccc;
  padding: 10px;
  cursor: pointer;
  outline: none;
`;
