import { AiOutlineClose } from 'react-icons/ai';
import styled, { keyframes } from 'styled-components';
import React, { useEffect,useState } from 'react'
import { cardShadow, hoverEffect, themeColor } from '../utils';

import { FaSpinner } from 'react-icons/fa';

import Select from "react-select";


import {  useSelector} from "react-redux";
import { useLocation ,useParams} from 'react-router-dom';
import AWS from 'aws-sdk';

import Podactivityviewalltalent from './Podactivityviewalltalent'
import Modal from 'react-modal';
import { useAuthToken } from "../TokenContext";
import makeAnimated from 'react-select/animated';
import { useIntl } from 'react-intl';
import { Breadcrumbs } from '@mui/material';
import { Link } from 'react-router-dom';

const animatedComponents = makeAnimated();

const Podactivitytalent = () => {
  const intl = useIntl();


  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalCategory, setModalCategory] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [uploadError, setUploadError] = useState(null);
  const [userStories, setUserStories] = useState([]);
  const [selectedUserStories, setSelectedUserStories] = useState([]);
  const [blockerCheck, setBlockerCheck] = useState(false); // State for checkbox
  const [blockerEnabled, setBlockerEnabled] = useState(false); // State to enable/disable text field

  const token = useAuthToken();

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

 

  const handleModalCategoryChange = (event) => {
    setModalCategory(event.target.value);
  };

  const bucketName=process.env.REACT_APP_VIDEOURLBUCKETNAME;
  AWS.config.update({
   
    region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESSKEYID,
  secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY
  });
  
  const s3 = new AWS.S3();

  const uploadFileToS3 = async (file) => {
    const params = {
      Bucket: process.env.REACT_APP_VIDEOURLBUCKETNAME,
      Key: file.name, // Use the file name as the key
      Body: file,
      ContentType: "video/mp4", // Add the ContentType property
    };
  
    try {
      await s3.upload(params).promise();
      console.log('File uploaded successfully.');
      return file.name;
    } catch (error) {
      console.error('Error uploading file to S3:', error);
      throw error;
    }
  };
  
  
  
  

  
  const [activeTab, setActiveTab] = useState('post'); // Default to 'post' tab

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const { isLoggedIn, user } = useSelector(state => state.auth);
 
  const location = useLocation();
  const { podid } = location.state
const {clientid}=location.state
  const [poddata, setinputList] = useState(
    { talent_id: user.data[0].c_id, pod_candidates: user.data[0].firstName, categories: '', today_focus : '', blockers: '', podID: podid,loom_url:'',accomplishments :'' ,jira_issue:''});


  const params = useParams();


  const handleCheckboxChange = (e) => {
    setBlockerCheck(e.target.checked);
    setBlockerEnabled(e.target.checked);
  };
  const onInputChange = e => {
    setinputList((prevState) => {
      return { ...prevState, [e.target.id]: e.target.value };
    });
  }
  useEffect(() => {
    fetch(process.env.REACT_APP_CLIENT_JIRAISSUESLISTTALENTS, {
      method: 'POST',
      headers: {
        'Authorization' : token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        pod_id: podid || null,
        c_id: user.data[0].c_id,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.statusCode === 200 && data.body) {
          const parsedBody = JSON.parse(data.body);
          if (Array.isArray(parsedBody.jiraIssues)) {
            setUserStories(parsedBody.jiraIssues);
          }
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, [podid, user.data,token]);
  
  const handleSubmit = (e) => {
    e.preventDefault();
    const abc = {
        ...poddata,
        jira_issue: selectedUserStories.map((story) => story.value),
        blockercheck: blockerCheck ? 1 : 0,
      };
    

    const requestOptions = {
      method: "POST",
      headers: {
        'Authorization' : token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(abc)

    }
    // https://tz6mi7arah.execute-api.us-east-1.amazonaws.com/dev/pod_activity_talent
    fetch(
      process.env.REACT_APP_TALENT_PODACTIVITYTALENT, requestOptions)
      .then(response => response.json())
      .then((data => {

        if (data.status == 400) {
          alert(data.data)

        }
        if (data.status == 200) {

          alert("Posted Successfully.")
          window.location.reload();
          setTimeout(() => {
            window.location.reload();
          }, 2000);

        }
        else {
          console.log('error')
        }

      }))

  };
  const [sourceLanguage, setSourceLanguage] = useState('en');
   
 
  const languageOptions = [
    { label: 'Afrikaans', value: 'af' },
    { label: 'Albanian', value: 'sq' },
    { label: 'Amharic', value: 'am' },
    { label: 'Arabic', value: 'ar' },
    { label: 'Armenian', value: 'hy' },
    { label: 'Azerbaijani', value: 'az' },
    { label: 'Bengali', value: 'bn' },
    { label: 'Bosnian', value: 'bs' },
    { label: 'Bulgarian', value: 'bg' },
    { label: 'Catalan', value: 'ca' },
    { label: 'Chinese (Simplified)', value: 'zh' },
    { label: 'Chinese (Traditional)', value: 'zh-TW' },
    { label: 'Croatian', value: 'hr' },
    { label: 'Czech', value: 'cs' },
    { label: 'Danish', value: 'da' },
    { label: 'Dari', value: 'fa-AF' },
    { label: 'Dutch', value: 'nl' },
    { label: 'English', value: 'en' },
    { label: 'Estonian', value: 'et' },
    { label: 'Farsi (Persian)', value: 'fa' },
    { label: 'Filipino, Tagalog', value: 'tl' },
    { label: 'Finnish', value: 'fi' },
    { label: 'French', value: 'fr' },
    { label: 'French (Canada)', value: 'fr-CA' },
    { label: 'Georgian', value: 'ka' },
    { label: 'German', value: 'de' },
    { label: 'Greek', value: 'el' },
    { label: 'Gujarati', value: 'gu' },
    { label: 'Haitian Creole', value: 'ht' },
    { label: 'Hausa', value: 'ha' },
    { label: 'Hebrew', value: 'he' },
    { label: 'Hindi', value: 'hi' },
    { label: 'Hungarian', value: 'hu' },
    { label: 'Icelandic', value: 'is' },
    { label: 'Indonesian', value: 'id' },
    { label: 'Irish', value: 'ga' },
    { label: 'Italian', value: 'it' },
    { label: 'Japanese', value: 'ja' },
    { label: 'Kannada', value: 'kn' },
    { label: 'Kazakh', value: 'kk' },
    { label: 'Korean', value: 'ko' },
    { label: 'Latvian', value: 'lv' },
    { label: 'Lithuanian', value: 'lt' },
    { label: 'Macedonian', value: 'mk' },
    { label: 'Malay', value: 'ms' },
    { label: 'Malayalam', value: 'ml' },
    { label: 'Maltese', value: 'mt' },
    { label: 'Marathi', value: 'mr' },
    { label: 'Mongolian', value: 'mn' },
    { label: 'Norwegian (Bokmål)', value: 'no' },
    { label: 'Pashto', value: 'ps' },
    { label: 'Polish', value: 'pl' },
    { label: 'Portuguese (Brazil)', value: 'pt' },
    { label: 'Portuguese (Portugal)', value: 'pt-PT' },
    { label: 'Punjabi', value: 'pa' },
    { label: 'Romanian', value: 'ro' },
    { label: 'Russian', value: 'ru' },
    { label: 'Serbian', value: 'sr' },
    { label: 'Sinhala', value: 'si' },
    { label: 'Slovak', value: 'sk' },
    { label: 'Slovenian', value: 'sl' },
    { label: 'Somali', value: 'so' },
    { label: 'Spanish', value: 'es' },
    { label: 'Spanish (Mexico)', value: 'es-MX' },
    { label: 'Swahili', value: 'sw' },
    { label: 'Swedish', value: 'sv' },
    { label: 'Tamil', value: 'ta' },
    { label: 'Telugu', value: 'te' },
    { label: 'Thai', value: 'th' },
    { label: 'Turkish', value: 'tr' },
    { label: 'Ukrainian', value: 'uk' },
    { label: 'Urdu', value: 'ur' },
    { label: 'Uzbek', value: 'uz' },
    { label: 'Vietnamese', value: 'vi' },
    { label: 'Welsh', value: 'cy' },
  ];
  const sendFileNameToAPI = async (fileName,loomUrl) => {
    // Remove spaces from the filename
    const sanitizedFileName = fileName.replace(/\s/g, ''); 
  
    const apiUrl = process.env.REACT_APP_TALENT_TRANSCRIBEPOC;
  
    const payload = {
      file_name: sanitizedFileName,
      created_by: user.data[0].c_id,
      pod_id: podid,
      source_language: sourceLanguage,
              target_language: "en",
              posted_to: clientid,
              loom_url: loomUrl,
            
              categories: modalCategory,
              pod_candidates: user.data[0].firstName,
    };
  
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Authorization' : token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
  
      const data = await response.json();
      return data; // Assuming the response contains information you need for the second API
    } catch (error) {
      console.error('Error sending file name to API:', error);
      alert('Successfully uploaded!');
       closeModal();
          // Optionally, you can navigate to the desired page here
          handleTabClick('view');
      throw error;
    }
  };
  
 
 
  

 
    const [file, setFile] = useState(null);
  
    const handleFileChange = (event) => {
      setFile(event.target.files[0]);
    };
  
 
const handleFileUpload = async () => {
  try {
    if (file) {
      // Start loading
      setIsLoading(true);

      // Step 1: Upload the file to S3
      const fileName = await uploadFileToS3(file);
      // console.log('File uploaded to S3:', fileName);

      // Update loom_url in poddata with the S3 file URL
      
      const loomUrl = `https://${bucketName}.s3.amazonaws.com/${fileName}`;
     
      setinputList((prevData) => ({
        ...prevData,
         loomUrl :`https://${bucketName}.s3.amazonaws.com/${fileName}`
      }));

      // Step 2: Call the first API with the file name
      const firstApiResponse = await sendFileNameToAPI(fileName,loomUrl);
      // console.log('First API Response:', firstApiResponse);

      // Check if the first API call was successful
      if (firstApiResponse.statusCode === 200) {
        // Step 3: Extract information from the first API response
        const sentenceFromBody = firstApiResponse.body;

      // Step 4: Call the second API with the extracted information and loom_url
      // const secondApiResponse = await sendSentenceToAPI(sentenceFromBody, loomUrl);
        
        // console.log('Second API Response:', secondApiResponse);
        if (sendFileNameToAPI.statusCode ===200) {
          // Alert: Successfully uploaded
          alert('Successfully uploaded!');
          closeModal();
          // Optionally, you can navigate to the desired page here
          handleTabClick('view');
        } else {
          // Alert: Error uploading (second API)
           console.log('Error uploading. Please try again.');
           alert('Successfully uploaded!');
           closeModal();
           handleTabClick('view');
        }
      } else if (firstApiResponse.statusCode === 500) {
        // Alert: Server Error
        alert('The requested job name already exists. Use a different job name.');
        closeModal();

        handleTabClick('view');
      } 
      
        // Do something with the second API response if needed
      
    } else {
      console.error('No file selected.');
      setUploadError('No file selected');
    }
  } catch (error) {
    // Handle errors
    console.error('Error:', error);
    setUploadError('Error during file upload');
  } finally {
    // Stop loading, regardless of success or failure
    setIsLoading(false);
  }
};




const handleMultiSelectChange = (selectedOptions) => {
    setSelectedUserStories(selectedOptions);
  };
    

  





 




  return (


    <Div >
      <Breadcrumbs aria-label="breadcrumb" style={{float:"left"}} >
  <Link to="/Pods" style={{ color: '#03045e', fontSize: "18px", fontFamily: 'Poppins,Medium', fontWeight: "600" }}>My Pods</Link>
  <span style={{ color: 'grey', fontSize: "18px", fontFamily: 'Poppins,Medium', fontWeight: "600" }}>&nbsp;&gt;&nbsp;</span>
  <span style={{ color: 'grey', fontSize: "18px", fontFamily: 'Poppins,Medium', fontWeight: "600" }}>Post Activity</span>
</Breadcrumbs>
<br/>
<br/>

<br/>

      <TabsContainer>
        <TabButton
          onClick={() => handleTabClick('post')}
          active={activeTab === 'post'}
        >
          {intl.formatMessage({ id: 'postActivity' })}
        </TabButton>
        <TabButton
          onClick={() => handleTabClick('view')}
          active={activeTab === 'view'}
        >
         {intl.formatMessage({ id: 'viewActivity' })}
        </TabButton>
      </TabsContainer>


      {activeTab === 'post' ? (<>
        <TitleText>{intl.formatMessage({ id: 'postPodActivity' })}</TitleText>

      <RecommendProject>



        <CardContent>
       

<Modal  style={{
              content: {
                width: '50%',
                maxWidth: '800px',
                margin: 'auto',
                marginTop: '5rem',
                backgroundColor:"#f6f6f6"
              },
            }} isOpen={isModalOpen} onRequestClose={closeModal}>
                  <AiOutlineClose style={{display:'flex',marginLeft:"auto",cursor:"pointer"}} onClick={closeModal}/>
  <ModalContent>
  <Row1>
        <Label>{intl.formatMessage({ id: 'languageSpoken' })}:</Label>
        <SelectField value={sourceLanguage} onChange={(e) => setSourceLanguage(e.target.value)}>
          {languageOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </SelectField>
        </Row1>
      
 
    <Row1>
    <Label>  {intl.formatMessage({ id: 'categories' })}:</Label>
    <SelectField
      id="modalCategories"
      name="modalCategories"
      value={modalCategory}
      onChange={handleModalCategoryChange}
    >
      <option value="" defaultValue>
        Categories
      </option>
      <option value="Design">Design</option>
      <option value="Coding">Coding</option>
      <option value="Testing">Testing</option>
      <option value="Resolving Bugs">Resolving Bugs</option>
      <option value="Deployment">Deployment</option>
    </SelectField>
    </Row1>
    <Row1>

    <Label>{intl.formatMessage({ id: 'fileUpload' })}:</Label>
 
      <InputField type="file" onChange={handleFileChange}  />
  
      
      </Row1>
      <p>{intl.formatMessage({ id: 'fileNameNoSpaces' })}.</p>
      
      <div style={{display:"flex",
justifyContent:"center",
alignItems:"center",

margin:"auto"}}>
      {isLoading && <SpinnerIcon size={40} />}
           
            </div>
      <Button2 onClick={handleFileUpload}>{intl.formatMessage({ id: 'upload' })}</Button2>
  
  
  </ModalContent>
</Modal>
          <form onSubmit={handleSubmit} >




          <Row1>
          <Label>{intl.formatMessage({ id: 'userStory' })}:</Label>

          <Select
              components={animatedComponents}
              isMulti
              value={selectedUserStories}
              onChange={handleMultiSelectChange}
              options={userStories.map((story) => ({
                label: `${story.issue_key} - ${story.issue_summary}`,
                value: story.issue_key,
              }))}
              required
              styles={{
                control: (provided) => ({
                  ...provided,
                  width: '20rem',
                  height:'3rem',
                  overflow:'auto'
                }),
              }}
            />


                </Row1>


       

<Row1>
  <Label>{intl.formatMessage({ id: 'todaysFocus' })} :</Label>
            <Textarea
              id="today_focus"
              required
maxLength='250'
              onChange={onInputChange}
              placeholder="Today's Focus . . ." />


</Row1>
<Row1>
  <Label>{intl.formatMessage({ id: 'accomplishments' })} :</Label>
            <Textarea
              id="accomplishments"
              maxLength='250'

              onChange={onInputChange}
              placeholder="Accomplishments . . ." />


</Row1>
<Row1>

<input
              type="checkbox"
              checked={blockerCheck}
              onChange={handleCheckboxChange}
            />
            Indicate if this is causing a blocker
         
          </Row1>
          {blockerCheck && (
<Row1>

           <Label>{intl.formatMessage({ id: 'blocker' })} :</Label>
            <Textarea
              id="blockers"
            
              maxLength='200'
              onChange={onInputChange}
              placeholder="Blocker. . ." />
              

</Row1>)}



 <button style={{textDecoration:"none",border:"none",color:"#00B4D8",background:'none',fontWeight:"600",display:"flex",justifyContent:"center",margin:"auto",alignItems:"center",paddingTop:"1rem"}} onClick={openModal}>{intl.formatMessage({ id: 'clickHereUploadVideoClip' })}</button>


            <div style={{ marginLeft: 'auto*' }}>
              {/* <NavLink  to ='/VIewDailyPostedUpdates' > */}
              <Button type='submit'>
              {intl.formatMessage({ id: 'post' })}
              </Button>

              {/* </NavLink> */}
            </div>

          </form>
          


        </CardContent>



      </RecommendProject>

      </>):(<Podactivityviewalltalent Podid={podid}/>)}
    </Div>
  )
}


const ModalContent = styled.div`
  /* Customize modal content styles here */
`;

const Div = styled.div`

// display:flex;
display:inline;
jutify-content:center;
flex-direction:column;

align-items:center;
margin:auto;
margin-top:10rem;
// margin-left:6.5rem;
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;

  overflow:hidden;
justify-content:center;
align-items:center;
margin:auto;
 }
`;
const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const SpinnerIcon = styled(FaSpinner)`
  animation: ${spin} 1s linear infinite;
`;

const RecommendProject = styled.div`

 display:flex;

jutify-content:center;
flex-direction:row;
@media screen and (min-width:320px) and (max-width:1080px){
  flex-direction:column;
    
    height:max-content;
    margin:0.4rem;
    
   }
`;
const LoadingMessage = styled.div`
  /* Define styles for loading message */
`;

const ErrorMessage = styled.div`
  /* Define styles for error message */
`;

const TitleText = styled.h4`
font-weight:600;

@media screen and (min-width:320px) and (max-width:1080px){

  margin-top:2rem;
 
}
`;

const CardContent = styled.div`
margin:0.4rem;
margin-top:2rem;
display:flex;
flex-direction:column;
// justify-content:center;
// align-items:center;


border-radius:20px;
font-family:Poppins,Medium;
width:40rem;
height:max-height;
padding: 1rem;
background-color: #F2F4F5;

box-shadow:${cardShadow};
transition:0.45 ease-in-out;
&:hover {
box-shadow:${hoverEffect};
}

@media screen and (min-width:320px) and (max-width:1080px){
  width:75vw;
margin-left:2rem
  margin-top:2rem;
 
  
 }
`;





const InputField = styled.input`
margin-top:1rem;
border: 1px solid #cccccc;
background-color:white;
border-radius: 5px;
width:20rem;
height:3.1rem;
padding:0.5rem;
font-weight:600;
font-family:Poppins,Medium;
@media screen and (min-width:320px) and (max-width:1080px){
  width:15rem;

  
 }
`;


const SelectField = styled.select`
margin-top:1rem;
border: 1px solid #cccccc;
border-radius: 5px;
width:20rem;
height:3.1rem;
font-weight:600;

color:grey;
font-family:Poppins,Medium;
@media screen and (min-width:320px) and (max-width:1080px){
  width:10rem;
  over-flow:auto;

  
 }
`;
const InputFieldCandidates = styled.input`
margin-top:1rem;
border: 1px solid #cccccc;
border-radius: 5px;
width:20rem;
height:3.1rem;
font-weight:600;
color:grey;
margin-left:10px;
padding-left:1.5rem;
font-family:Poppins,Medium;
@media screen and (min-width:320px) and (max-width:1080px){
  width:15rem;

  margin-left:-2px;
 }
`;


const SelectField1 = styled.div`
margin-top:1rem;
border: 1px solid #cccccc;
border-radius: 5px;
width:20rem;
height:3.1rem;
font-weight:600;
color:grey;
font-family:Poppins,Medium;
@media screen and (min-width:320px) and (max-width:1080px){
  width:15rem;

  
 }
`;


const Button = styled.button`
margin-left:auto;
border:none;
border-radius: 10px;
background-color:#1DBF73;
box-shadow: 1px 3px 6px #00000029;
width:8rem;
height:2rem;
font-size:18px;
font-weight:600;
color:white;
margin-top:2rem;
@media screen and (min-width:320px) and (max-width:1080px){

  width:7rem;
}
`;

const Button2 = styled.button`
display:flex;
justify-content:center;
align-items:center;
border:none;
margin:auto;
border-radius: 10px;
background-color:#1DBF73;
box-shadow: 1px 3px 6px #00000029;
width:8rem;
height:2rem;
font-size:18px;
font-weight:600;
color:white;
margin-top:4rem;
@media screen and (min-width:320px) and (max-width:1080px){

  width:7rem;
}
`;
const Button1 = styled.button`
margin-right:auto;
border:none;
border-radius: 10px;
background-color:#00B4D8
;
box-shadow: 1px 3px 6px #00000029;
width:8rem;
height:max-height;
font-size:16px;
padding:8px;
font-weight:600;
color:white;
margin-top:1rem;
@media screen and (min-width:320px) and (max-width:1080px){

  width:7rem;
}
`;




const SideCard = styled.div`
margin-left:5rem;
display:flex;
justify-content:center;
align-items:center;
flex-direction:column;
border-radius:20px;
font-family:Poppins,Medium;
width:20rem;
height:32rem;

padding: 1rem;
background-color: #F2F4F5;

box-shadow:${cardShadow};
transition:0.45 ease-in-out;
&:hover {
box-shadow:${hoverEffect};
}

@media screen and (min-width:320px) and (max-width:1080px){

margin:auto;
margin-top:3rem;
  width:20rem;
}
`;


const SideCardContent = styled.div`
background-color:#90E0EF;
width:17rem;
display:flex;
flex-direction:column;
padding:3px;


border-radius:20px;
`;


const SideCardContent1 = styled.div`
background-color:#90E0EF;
width:17rem;
display:flex;
margin-top:1rem;
flex-direction:column;
height:10rem;
justify-content:center;
align-items:center;
border-radius:20px;

`;





const Detail = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;

  @media screen and (min-width: 320px) and (max-width: 1080px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.2rem;
    margin-bottom: 1rem;
  }
`;
const InfoContainer = styled.div`
  display: flex;
margin:auto;
`;

const Info = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: 15px;
`;
const Row = styled.div`
  display: flex;

  align-item: center;
  margin-bottom: 0.8rem;
`;
const Row1 = styled.div`
  display: flex;
margin-top:2rem;
  align-item: center;
  margin-bottom: 0.8rem;
  @media screen and (min-width:320px) and (max-width:1080px){
    display: flex;
    flex-direction:column;
    
  }
`;
const InfoName = styled.h6`
  font-weight: 600;
`;
const InfoUpdate = styled.span`
  font-size: 13px;
  font-weight: 600;
  margin-right: auto;
`;

const Title = styled.h6`
  font-weight: 600;
`;
const ProjectInfo = styled.p`
padding:5px;
margin:3px;
background-color:white;
border-radius:5px  ;
font-size:13px;
color:black;
font-weight:600;
margin-bottom:1rem
`;

const ViewAll = styled.button`
width:5rem;
font-weight:600;
height:2rem;
margin-left:auto;
margin-top:1rem;
border: 2px solid #1DBF73;
border-radius:5px  ;
box-shadow: 1px 3px 6px #00000029;
`;

const View = styled.button`
width:8rem;
font-weight:600;
height:2rem;
color:black;
background-color:white;
border-radius:5px  ;
margin-bottom:1rem;
margin-top:1rem;
border: none;
border-radius:5px  ;
box-shadow: 1px 3px 6px #00000029;
`;

const H1 = styled.h1`
font-size:22px;
font-weight:600;
margin-bottom:1.2rem;
`;

const InputContainer = styled.div`
display : flex;
border-radius:10px;
justify-content: center;
margin-left:auto;
width:25rem;
margin-bottom:1rem;
border:1px solid #4E8FAE;
box-shadow: 3px 6px 9px #00000005;
@media screen and (min-width:320px) and (max-width:1080px){
  margin:auto;
  margin-top:2rem;

    width: 20rem;
  
 
`;

const Icon = styled.div`
height: 3rem;
width: 2rem;
background-color: #F2F3F4;
display:flex;
flex-direction:row
justify-content: center;
align-items: center;
border-top-left-radius:10px;
border-bottom-left-radius:10px;
svg{
    color:#555555;
}
`;

const Input = styled.input`
justify-content: center;
height: 3rem;
width: 25rem;
border:0px solid #4E8FAE;
background-color:#F2F3F4;
font-family:Poppins,Medium;
font-Weight:600;
border-top-right-radius:10px;
border-bottom-right-radius:10px;
&:focus{
    border:none;
    outline:none;
}
@media screen and (min-width:320px) and (max-width:1080px){
  width: 20rem;
}
`;

const InfoPod = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-left: 15px;
`;


const Textarea = styled.textarea`
width:25rem;


tex-decoration:none;
border:1px solid #cccccc;
border-radius:5px;

padding:1rem;
font-size:14px;
font-family:Poppins,Medium;
font-Weight:600;
  outline: none;
  @media screen and (min-width:320px) and (max-width:1080px){
    width: 15rem;
  }


`;

const TextDiv = styled.div`
display:flex;
flex-direction:row;




`;

const Buttonadd = styled.button`
tex-decoration:none;
border:none;
display:flex;
// margin-right:auto;
border-radius:5px;
font-size:14px;
font-family:Poppins,Medium;
font-Weight:600;
  outline: none;
width:4rem;
justify-content:center;
align-items:center;
height:2.5rem;
margin-top:0.5rem;
color:white;
background-color:#00B4D8;

`;

const ButtonRemove = styled.button`
text-decoration:none;
border:none;
border-radius:5px;
font-size:14px;
font-family:Poppins,Medium;
font-Weight:600;
  outline: none;
width:4.5rem;
height:2.5rem;
margin-top:0.5rem;
margin-left:10px;
color:white;
background-color:#00B4D8;

`;

const Label =styled.label`
margin-top:1rem;

font-family:Poppins,Medium;
font-weight: bold;
font-size:16px;
color :black;
width:10rem;
`;
const TabsContainer = styled.div`
  display: flex;
  margin-bottom: 1rem;
`;

const TabButton = styled.button`
  background: ${(props) => (props.active ? 'lightblue' : 'white')};
  border: 1px solid #ccc;
  padding: 10px;
  cursor: pointer;
  outline: none;
`;

export default Podactivitytalent

